import { DateTime } from 'luxon';

import {
  GET_ALL_TABLETS,
  CREATE_TABLET,
  UPDATE_TABLET,
  DELETE_TABLET,
} from '../../state/actionTypes';
import TabletService from './tablets.service';
import Analytics from '../../helpers/Analytics';
import { counterWasRecentlyCalled, counters } from '../../helpers/counters';

const {
  tablets: tabletCounters,
} = counters;

export const getTablets = () => async (dispatch) => {
  if (counterWasRecentlyCalled('tablets.getTablets', 1)) return true;
  tabletCounters.getTablets = DateTime.local();

  const { data } = await TabletService.getAll();

  if (!data) {
    // If there is no data, reset the counter
    tabletCounters.getTablets = null;
    return false
  };
  dispatch({
    type: GET_ALL_TABLETS,
    payload: {
      tablets: data,
    },
  });
  return true;
};

export const createTablet = (tablet) => async (dispatch) => {
  Analytics.track('TabletMode/Create');
  const { data: newTablet } = await TabletService.create(tablet);
  if (!newTablet) return false;
  dispatch({
    type: CREATE_TABLET,
    payload: {
      tablet: newTablet,
    },
  });
  return true;
};

export const updateTablet = (id, tablet) => async (dispatch) => {
  Analytics.track('TabletMode/Update');
  const { data: newTablet } = await TabletService.update(id, tablet);
  if (!newTablet) return false;
  dispatch({
    type: UPDATE_TABLET,
    payload: {
      tablet: newTablet,
    },
  });
  return true;
};

export const deleteTablet = (id) => async (dispatch) => {
  Analytics.track('TabletMode/Delete');
  const { data } = await TabletService.delete(id);
  if (!data) return false;
  dispatch({
    type: DELETE_TABLET,
    payload: {
      tabletId: id,
    },
  });
  return true;
};
