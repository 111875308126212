import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Drawer,
  Select,
  Popover,
} from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { getContactAddressBooks } from 'ontraccr-common/lib/Contacts';
import config from '../../../config';

import {
  fieldOption,
  fieldTypeCanSubmit,
} from './formFieldsHelpers';
import fieldTypes from './formFields.types';

import NuxPopover from '../../../nux/NuxPopover';
import NuxFocusBackground from '../../../nux/NuxFocusBackground';
import {
  FORMS_ADD_DRAWER_TYPE,
  FORMS_ADD_DRAWER_STEP_PREFIX,
  FORMS_ADD_DRAWER_STEP_1,
  FORMS_ADD_DRAWER_STEP_1_TEXT,
  FORMS_ADD_DRAWER_STEP_2,
  FORMS_ADD_DRAWER_STEP_2_TEXT,
  FORMS_ADD_DRAWER_STEP_3,
  FORMS_ADD_DRAWER_STEP_3_TEXT,
} from '../../../nux/nux.constants';

import {
  createNuxEntry,
} from '../../../nux/state/nux.actions';

import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import HoverHelp from '../../../common/HoverHelp';
import FullPhoto from '../../../common/photos/FullPhoto';
import LiveFeedFileUpload from '../../../dashboard/LiveFeed/LiveFeedFileUpload';

import colors from '../../../constants/Colors';
import { getIdMap } from '../../../helpers/helpers';
import { getFileType, downloadFile } from '../../../files/fileHelpers';
import { getAttributeMapWithCustomFields } from '../../formHelpers';
import useToggle from '../../../common/hooks/useToggle';
import { request } from '../../../helpers/requests';

import ATTRIBUTE_CONFIG from './attributeConfig';
import { getEquipmentTypes } from '../../../equipment/state/equipment.actions';

const nuxStyle = {
  position: 'relative',
  zIndex: 1000,
  pointerEvents: 'none',
  backgroundColor: 'white',
};

const NUX_FIELD_TITLE = 'Add a title to your field';

export default function FormFieldAddDrawer({
  sectionId,
  visible,
  onClose,
  onSave,
  onEdit,
  field = {},
  isAdd = false,
  sections = [],
  isBoardCards, // Re-use for board card templates. Hide certain fields
  isExternalForm,
  divisions,
  templateId,
  projectId,
  name,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEquipmentTypes());
  }, []);

  const activeNuxAction = useSelector((state) => state.nux.activeNuxAction);
  const nux = useSelector((state) => state.nux.nux);
  const projects = useSelector((state) => state.projects.projects);
  const users = useSelector((state) => state.users.users);
  const materials = useSelector((state) => state.materials.materials);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const phases = useSelector((state) => state.costcodes.phases);
  const customers = useSelector((state) => state.customers.customers);
  const vendors = useSelector((state) => state.vendors.vendors);
  const customTables = useSelector((state) => state.forms.customTables);
  const scheduleOfValues = useSelector((state) => state.projects.scheduleOfValues);
  const equipment = useSelector((state) => state.equipment.equipment);
  const formTemplates = useSelector((state) => state.forms.templates);
  const labels = useSelector((state) => state.labels);
  const projectTypes = useSelector((state) => state.projects.projectTypes);
  const equipmentTypes = useSelector((state) => state.equipment.equipmentTypes);
  const { connectedToStripe, paidFlags = [], settings = {} } = useSelector((state) => state.settings.company);
  const ccCustomFields = useSelector((state) => state.costcodes.customFieldTemplate);
  const globalAddressBooks = useSelector((state) => state.contacts.globalAddressBooks);
  const userCustomFields = useSelector((state) => state.users.customFieldTemplate);
  const formStatuses = useSelector((state) => state.forms.statuses);
  const customFields = useSelector((state) => state.timecards.customFields);
  const boards = useSelector((state) => state.boards.boards);
  const bucketTemplates = useSelector((state) => state.buckets.bucketTemplates);
  const buckets = useSelector((state) => state.buckets.buckets);

  const form = useRef(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [selectedType, setSelectedType] = useState('yes-no');
  // Store state to allow preview to be interactive
  const [previewProps, setPreviewProps] = useState({});
  const [configProps, setConfigProps] = useState({}); // Store field config
  const [configState, setConfigState] = useState({});
  const [changeOrderMap, setChangeOrderMap] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [currIndex, setCurrIndex] = useState();
  const [positions, setPositions] = useState([]);

  const {
    isToggled: showFileAdd,
    toggle: toggleFileAddModal,
  } = useToggle();

  const contactAddressBooks = useMemo(() => (
    getContactAddressBooks(globalAddressBooks)
  ), [globalAddressBooks]);

  const paidFlagsMap = useMemo(() => {
    const map = {};
    paidFlags.forEach((flag) => { map[flag] = true; });
    return map;
  }, [paidFlags]);

  const parsedFormTemplates = useMemo(() => Object.values(formTemplates), [formTemplates]);
  const {
    settings: {
      defaultCollapsed,
    } = {},
  } = useMemo(() => (
    sections.find((section) => section.id === sectionId) ?? {}
  ), [sections, sectionId]);

  const reset = useCallback(() => {
    setConfigProps({});
    setConfigState({});
    setPreviewProps({});
    setCanSubmit(false);
    setPositions([]);
  }, []);

  const onSubmit = useCallback((shouldClose) => () => {
    if (!selectedType) return;
    const { id: fieldId } = field;
    const payload = {
      shouldClose,
      selectedType,
      id: fieldId,
    };

    const optional = defaultCollapsed || configProps.optional;
    payload.configProps = {
      ...configProps,
      optional,
      presetData: (selectedType === 'table' && configProps.shouldSavePresetTableSelections)
        ? {
          ...previewProps,
          values: previewProps?.selected || [],
        }
        : null,
    };

    onSave(payload);
    reset();

    onEdit();
  }, [
    onSave,
    onEdit,
    selectedType,
    configProps,
    previewProps,
    reset,
    field,
    defaultCollapsed,
  ]);

  const typeNux = useCallback((i = 0) => {
    if (i > NUX_FIELD_TITLE.length) return;
    setConfigProps({
      title: NUX_FIELD_TITLE.substring(0, i),
    });
    setTimeout(() => typeNux(i + 1), [50]);
  }, []);

  const onTypeChange = useCallback((newType) => {
    if (newType !== selectedType) {
      // If type changes, clear out configProps
      const {
        title: oldTitle,
        optional: oldOptional,
      } = configProps;
      setConfigProps({ title: oldTitle, optional: oldOptional });
    }
    setSelectedType(newType);
  }, [selectedType, configProps]);

  useEffect(() => {
    if (activeNuxAction === FORMS_ADD_DRAWER_STEP_3) {
      typeNux();
    } else if (!activeNuxAction) {
      setConfigProps({});
    }
  }, [activeNuxAction, typeNux]);

  useEffect(() => {
    const getPositions = async () => {
      const { data } = await request({
        call: axios.get('/positions'),
        defaultData: [],
        errMsg: 'Could not get roles',
        hideSuccessToast: true,
      });
      setPositions(data ?? []);
    };

    if (!visible) {
      reset();
    } else {
      getPositions();
    }
  }, [visible, reset]);

  useEffect(() => {
    if (
      visible
      && isAdd
      && dispatch
      && !nux.has(FORMS_ADD_DRAWER_TYPE)
    ) {
      dispatch(createNuxEntry(FORMS_ADD_DRAWER_TYPE));
    }
  }, [visible, isAdd, nux, dispatch]);

  useEffect(() => {
    let mostRecentValue; let values;
    const projectChangeOrderMap = {};

    Object.keys(scheduleOfValues).forEach((sovKey) => {
      values = scheduleOfValues[sovKey];

      mostRecentValue = values[values.length - 1];

      if (mostRecentValue.isChangeOrder) {
        if (!projectChangeOrderMap[mostRecentValue.projectId]) {
          projectChangeOrderMap[mostRecentValue.projectId] = [];
        }

        projectChangeOrderMap[mostRecentValue.projectId].push(mostRecentValue);
      }
    });
    setChangeOrderMap(projectChangeOrderMap);
  }, [scheduleOfValues]);

  useEffect(() => {
    const {
      selectedType: fieldType,
      configProps: fieldConfig,
      previewProps: fieldPreviewProps = {},
    } = field;
    if (fieldType && fieldConfig) {
      setSelectedType(fieldType);
      setConfigProps(fieldConfig);
      setPreviewProps(fieldPreviewProps);
    }
  }, [field]);

  useEffect(() => {
    const {
      [selectedType]: canSubmitFunction = () => false,
    } = fieldTypeCanSubmit;
    setCanSubmit(canSubmitFunction({
      configProps,
      sections,
      field,
      sectionId,
    }));
  }, [selectedType, configProps, sections, field, sectionId]);

  const attributeMap = ATTRIBUTE_CONFIG(t);

  const customerList = useMemo(() => Object.values(customers), [customers]);
  const vendorList = useMemo(() => Object.values(vendors), [vendors]);
  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const phaseIdMap = useMemo(() => getIdMap(phases), [phases]);
  const formattedCustomTables = useMemo(() => (
    Object.values(customTables).map((customTable = {}) => {
      const { name: customTableName, id: customTableId } = customTable;
      const formatted = { ...customTable };
      formatted.key = customTableId;
      formatted.title = customTableName;
      return formatted;
    })
  ), [customTables]);

  const {
    configure: configureView,
    preview: previewView,
  } = useMemo(() => {
    if (!selectedType) {
      return {
        configure: () => null,
        preview: () => null,
      };
    }
    const {
      [selectedType]: {
        configure = () => null,
        preview = () => null,
      } = {},
    } = fieldTypes;
    return { configure, preview };
  }, [selectedType]);

  const attributeMapWithCustomFields = useMemo(() => (
    getAttributeMapWithCustomFields({
      attributeMap,
      costcodeCustomFields: ccCustomFields,
      userCustomFields,
      bucketTemplates,
    })
  ), [ccCustomFields, userCustomFields, bucketTemplates]);

  const formattedSubTypes = useCallback((types) => {
    if (!types?.length) return [];

    return [{
      label: 'None',
      value: null,
    }].concat(
      types
        .filter((type) => type.name !== 'None')
        .map((type) => ({
          label: type.name,
          value: type.id,
        })),
    );
  }, []);

  const showNux = activeNuxAction && activeNuxAction.startsWith(FORMS_ADD_DRAWER_STEP_PREFIX);
  const isNuxStep1 = activeNuxAction === FORMS_ADD_DRAWER_STEP_1;
  const isNuxStep2 = activeNuxAction === FORMS_ADD_DRAWER_STEP_2;
  const isNuxStep3 = activeNuxAction === FORMS_ADD_DRAWER_STEP_3;

  const apiFields = new Set(['attachment', 'multiSig']);

  const allFields = useMemo(() => (
    Object.keys(fieldTypes).filter((fieldKey) => (
      (fieldKey !== 'payment' || (connectedToStripe && config.showPaymentField && !isBoardCards))
        && (!isExternalForm || !apiFields.has(fieldKey))
        && (!isBoardCards || fieldKey !== 'staticAttachments')
    ))
  ), [fieldTypes, isBoardCards, isExternalForm]);

  const setFieldTriggerEditable = useCallback((checked) => {
    setConfigProps({
      ...configProps,
      fieldTriggerProps: {
        ...configProps.fieldTriggerProps,
        editable: checked,
      },
    });
  }, [configProps]);

  const selectedFileType = useMemo(() => (
    selectedFile ? getFileType(selectedFile) : null
  ), [selectedFile]);

  const onAddFile = useCallback((file) => {
    setConfigProps((oldConfig) => {
      const oldFiles = oldConfig?.files ?? [];
      return {
        ...oldConfig,
        files: oldFiles.concat([file]),
      };
    });
  }, []);

  const onNextFileClick = useCallback((isRight) => () => {
    const files = configProps?.files ?? [];
    const newIndex = isRight ? currIndex + 1 : currIndex - 1;
    const isInvalid = isRight ? newIndex >= files.length : newIndex < 0;
    if (isInvalid) return;
    const ourFile = files[newIndex] || {};
    setSelectedFile(ourFile);
    setCurrIndex(newIndex);
  }, [configProps]);

  const onUploadEnd = useCallback(() => toggleFileAddModal(), []);
  const onShowFileModal = useCallback(() => toggleFileAddModal(), []);
  const onCloseFile = useCallback(() => {
    setSelectedFile();
    setCurrIndex();
  }, []);

  const onDownloadFile = useCallback(async () => {
    if (!selectedFile) return;
    const downloadOpts = {};
    if (selectedFile instanceof File) {
      downloadOpts.fileObject = selectedFile;
    } else {
      downloadOpts.fileDetails = selectedFile;
    }
    await downloadFile(downloadOpts);
  }, [selectedFile]);

  const isExistingField = !!field.id && !!templateId;

  const isDisabled = useCallback((fieldKey) => {
    const fieldType = fieldTypes[fieldKey];
    if (!fieldType.paidFlag) return false;
    if (paidFlagsMap.IS_DEMO) return false;
    return !paidFlagsMap[fieldType.paidFlag];
  }, [paidFlagsMap, fieldTypes]);

  const bucketTemplatesWithGPSFieldMap = useMemo(() => (
    getIdMap(
      bucketTemplates.filter((template) => template?.customFields?.fields?.some(
        (bucketField) => bucketField?.selectedType === 'gpsLocation',
      )),
    )
  ), [bucketTemplates]);

  const bucketDropdownsWithGPSField = useMemo(() => {
    const dropdowns = [];

    sections?.forEach(({ fields = [] }) => {
      fields?.forEach((sectionField) => {
        const {
          id,
          selectedType: fieldType,
          configProps: { dataType, subDataType, title } = {},
        } = sectionField;
        if (fieldType === 'dropdown' && dataType === 'Buckets' && bucketTemplatesWithGPSFieldMap[subDataType]) {
          dropdowns.push({
            ...sectionField,
            value: id,
            label: title,
          });
        }
      });
    });
    return dropdowns;
  }, [sections, bucketTemplatesWithGPSFieldMap, bucketTemplatesWithGPSFieldMap]);

  return (
    <Drawer
      title="Add a Field"
      visible={visible}
      width="fit-content"
      maskClosable={false}
      onClose={onClose}
      bodyStyle={{ padding: 0, backgroundColor: colors.BREADCRUMB_BACKGROUND }}
    >
      <div className="form-add-container">
        <div className="form-field-header-background">
          <Row className="form-required-field">
            <Col>
              Field Type:
            </Col>
            <Col>
              {isExistingField && (
                <Popover
                  placement="bottomLeft"
                  trigger="hover"
                  content={(
                    <div style={{ width: 200 }}>
                      Field Types cannot be altered for existing forms. You must create a new field
                      to use a different type.
                    </div>
                  )}
                  title="Warning"
                  width={200}
                >
                  <WarningOutlined
                    style={{
                      color: colors.ONTRACCR_DARK_YELLOW,
                      marginLeft: 10,
                    }}
                  />
                </Popover>
              )}
            </Col>
          </Row>
          <NuxPopover
            placement="left"
            visible={isNuxStep1}
            text={FORMS_ADD_DRAWER_STEP_1_TEXT}
            nextAction={FORMS_ADD_DRAWER_STEP_2}
          >
            <Select
              title="Field Type"
              style={{
                width: 350,
                marginTop: 10,
                zIndex: isNuxStep1 ? 1000 : 1,
                pointerEvents: isNuxStep1 ? 'none' : 'auto',
                position: 'relative',
              }}
              listHeight={400}
              placeholder="Select Field Type"
              onSelect={onTypeChange}
              value={selectedType}
              disabled={isExistingField}
              showSearch
              filterOption={(input, option) => {
                const relevantField = fieldTypes[option?.value];
                return (relevantField?.title ?? '').toLowerCase().includes(input.toLowerCase())
              }}
            >
              {allFields.map((fieldKey) => {
                const disabled = isDisabled(fieldKey);
                return (
                  <Select.Option value={fieldKey} key={fieldKey} disabled={disabled}>
                    {fieldOption(fieldTypes[fieldKey], disabled)}
                  </Select.Option>
                );
              })}
            </Select>
          </NuxPopover>
          <NuxPopover
            placement="left"
            visible={isNuxStep2}
            text={FORMS_ADD_DRAWER_STEP_2_TEXT}
            nextAction={FORMS_ADD_DRAWER_STEP_3}
          >
            <Row
              className="form-field-configure-header"
              justify="space-between"
              style={isNuxStep2 || isNuxStep3 ? nuxStyle : {}}
            >
              <Col>
                Configure:
              </Col>
              <Col>
                <HoverHelp placement="topRight" content="Configure the structure of your form field here." />
              </Col>
            </Row>
          </NuxPopover>
          <div
            className="form-field-configure-background"
            style={isNuxStep2 || isNuxStep3 ? nuxStyle : {}}
          >
            {configureView({
              setConfigProps,
              configProps,
              configState,
              setConfigState,
              setCanSubmit,
              sections,
              id: field.id,
              sectionId,
              formattedCustomTables,
              customTables,
              isExternalForm,
              divisions,
              templateId,
              projectId,
              name,
              isBoardCards,
              setFieldTriggerEditable,
              disableOptional: defaultCollapsed,
              t,
              projectTypes: formattedSubTypes(projectTypes),
              equipmentTypes: formattedSubTypes(equipmentTypes),
              attributeMap: attributeMapWithCustomFields,
              onShowFileModal,
              onOpenFile: setSelectedFile,
              users,
              projects,
              costcodes,
              phases,
              customers: customerList,
              vendors: vendorList,
              equipment,
              formTemplates: parsedFormTemplates,
              labels,
              projectIdMap,
              costcodeIdMap,
              contactAddressBooks,
              isExistingField,
              formStatuses,
              customFields,
              boards: Object.values(boards),
              bucketTemplates,
              bucketDropdownsWithGPSField,
              positions,
              buckets,
              fullEquipmentTypes: equipmentTypes,
            })}
          </div>
        </div>
        <NuxPopover
          placement="left"
          visible={isNuxStep3}
          text={FORMS_ADD_DRAWER_STEP_3_TEXT}
        >
          <Row
            className="form-field-preview-header"
            justify="space-between"
            style={isNuxStep3 ? nuxStyle : {}}
          >
            <Col>
              Preview:
            </Col>
            <Col>
              <HoverHelp placement="topRight" content="This is a preview of how this field will function" />
            </Col>
          </Row>
        </NuxPopover>
        <div
          className="form-field-preview-background"
          style={isNuxStep3 ? nuxStyle : {}}
        >
          <div className="form-field-preview">
            {previewView({
              setConfigProps,
              configProps,
              setPreviewProps,
              previewProps,
              users,
              projects,
              materials,
              costcodes,
              phases,
              customers: customerList,
              vendors: vendorList,
              equipment,
              formTemplates: parsedFormTemplates,
              labels,
              formRef: { current: form },
              changeOrderMap,
              customTables,
              projectIdMap,
              costcodeIdMap,
              phaseIdMap,
              divisions,
              sections,
              templateId,
              projectId,
              isExternalForm,
              name,
              t,
              onOpenFile: setSelectedFile,
              setOpenFileIndex: setCurrIndex,
              inAddDrawer: true,
              settings,
              isFormBuilder: true,
            })}
          </div>
        </div>
      </div>
      <div className="drawer-footer">
        <Row justify="end" gutter={10}>
          <OnTraccrButton
            title="Cancel"
            type="cancel"
            style={{ marginRight: 8 }}
            onClick={onClose}
          />
          <OnTraccrButton
            title="Save and Add Another"
            disabled={!canSubmit}
            style={{ marginRight: 8 }}
            onClick={onSubmit(false)}
          />
          <OnTraccrButton
            title="Save"
            disabled={!canSubmit}
            onClick={onSubmit(true)}
          />
        </Row>
      </div>
      <FullPhoto
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...selectedFile}
        file={selectedFile}
        type={selectedFileType}
        onClose={onCloseFile}
        onLeft={onNextFileClick(false)}
        onRight={onNextFileClick(true)}
        showLeft={selectedFile && currIndex > 0}
        showRight={selectedFile
          && configProps?.files?.length
          && currIndex < ((configProps?.files?.length ?? 0) - 1)}
        onDownload={onDownloadFile}
        useApryse
      />
      <LiveFeedFileUpload
        visible={showFileAdd}
        onUploadEnd={onUploadEnd}
        addFile={onAddFile}
        customProps={{
          width: 600,
          zIndex: 1001,
          style: {},
          push: { distance: 600 },
        }}
        placement="right"
      />
      <NuxFocusBackground show={showNux} />
    </Drawer>
  );
}

FormFieldAddDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  field: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    config: PropTypes.shape({}),
  }),
  sectionId: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  isAdd: PropTypes.bool,
  isBoardCards: PropTypes.bool,
  isExternalForm: PropTypes.bool,
  divisions: PropTypes.string,
  projectId: PropTypes.string,
  templateId: PropTypes.string,
  name: PropTypes.string,
};

FormFieldAddDrawer.defaultProps = {
  field: {},
  sectionId: null,
  sections: [],
  isAdd: false,
  isBoardCards: false,
  isExternalForm: false,
  divisions: null,
  projectId: null,
  templateId: null,
  name: null,
};
