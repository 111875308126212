import React from 'react';
import { Col, Row } from 'antd';
import { TaskHelpers } from 'ontraccr-common';
import { TextRow } from '../clock/ManualEntry/manualEntryHelpers';

export default {};

export const sortByStartTime = (a, b) => (
  (TaskHelpers.getStartTime(a)) - (TaskHelpers.getStartTime(b))
);

export const sortByNameAndStartTime = (userMap) => (a, b) => {
  const userA = userMap?.[a?.userId]?.name ?? '';
  const userB = userMap?.[b?.userId]?.name ?? '';
  const userComparison = userA.localeCompare(userB);

  if (userComparison === 0) {
    return sortByStartTime(a, b);
  }

  return userComparison;
};

export const getEnteredViaText = ({
  textFlag = null,
  metadata,
}) => {
  if (!metadata) return null;

  const {
    deviceText,
    submitterText,
  } = metadata;

  const title = textFlag ? `${textFlag}` : '';
  const text = `${submitterText} (${deviceText})`;

  return (
    <Row>
      <Col style={{ fontSize: 12 }}>
        <Row>
          <Col>
            {title ? (
              <TextRow title={title} text={text} />
            ) : (
              <Row>{text}</Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const getEnteredViaTableText = (metadata) => {
  const {
    entered,
    start,
    end,
  } = metadata ?? {};

  if (entered) {
    return getEnteredViaText({ metadata: entered });
  }

  const startText = getEnteredViaText({ metadata: start, textFlag: 'Start' });

  const endText = getEnteredViaText({ metadata: end, textFlag: 'End' });

  return (
    <div>
      {startText}
      {endText}
    </div>
  );
};
