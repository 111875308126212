import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Buckets } from 'ontraccr-common';

import FormsList from './CompletedForms/FormsList';

import { getIdMap } from '../helpers/helpers';
import { getFormInvoicesCostToDate } from '../payables/invoices/state/invoices.actions';

import {
  PAYABLE_FORM_TYPES,
} from '../payables/payables.constants';

export default function FilteredFormList({
  cardId,
  filterId,
  filterIds,
  filterType,
  isNotDisplay,
  formType, // A specific form type to include
  exclude, // List of types to exclude. Don't use type and exclude at the same time
  topOffset = 0,
  visible,
  hideDate = true,
  range,
  onRangeChange,
  option,
  hideWhenEmpty,
}) {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const forms = useSelector((state) => state.forms.forms);
  const formTemplates = useSelector((state) => state.forms.templates);
  const formCostToDate = useSelector((state) => state.invoices.formCostToDate);
  const buckets = useSelector((state) => state.buckets.buckets);

  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const templateTypeSet = useMemo(() => (
    new Set(
      Object.values(formTemplates)
        .filter((template) => template.notDeleted
          && template.active
          && (
            (formType && template.type === formType)
            || (exclude && !exclude.has(template.type))
          ))
        .map((template) => template.id),
    )
  ), [formTemplates, formType, exclude]);

  const relevantBucketIds = useMemo(() => {
    switch (filterType) {
      case 'Customers':
        return Buckets.getLinkedBuckets(buckets, [filterId], ['customers']).allBuckets.map((bucket) => bucket.id)
      case 'Projects':
        return Buckets.getLinkedBuckets(buckets, [filterId], ['projects']).allBuckets.map((bucket) => bucket.id);
      case 'Buckets':
        return [filterId];
      default:
        return [];
    }
  }, [filterId, filterType, filterIds, buckets]);

  const formsList = useMemo(() => {
    if (isNotDisplay) return [];
    let retForms = Object.values(forms)
      .filter((form) => {
        const {
          templateId,
          projects: formProjects = [],
          customers: formCustomers = [],
          cardId: formCardId,
          vendors: formVendors = [],
          cardProjects = [],
          cardCustomers = [],
          buckets: formBuckets = [],
        } = form;

        const relevantBucketSet = new Set(relevantBucketIds);

        // If filter type is Projects:
        if (filterType === 'Projects') {
          return (
            formProjects.includes(filterId)
            || cardProjects.includes(filterId)
            || formBuckets.find((bucket) => relevantBucketSet.has(bucket))
          ) && templateTypeSet.has(templateId);
        }

        // If filter type is Vendors:
        if (filterType === 'Vendors') return formVendors.includes(filterId) && templateTypeSet.has(templateId);

        // If filter type is Customers:
        if (filterType === 'Customers') {
          return formProjects.some((formProjectId) => {
            const {
              [formProjectId]: {
                customerId: projectCustomer,
              } = {},
            } = projectMap;
            return projectCustomer === filterId && templateTypeSet.has(templateId);
          }) || (
            formCustomers.includes(filterId) && templateTypeSet.has(templateId)
          ) || (
            formCardId === cardId && templateTypeSet.has(templateId)
          ) || (
            cardCustomers.includes(filterId) && templateTypeSet.has(templateId)
          ) || formBuckets.find((bucket) => relevantBucketSet.has(bucket));
        }

        const formProjectSet = new Set(formProjects);
        const cardProjectSet = new Set(cardProjects);
        const formBucketSet = new Set(formBuckets);

        if ((filterType === 'ProjectGroups' || filterType === 'Buckets')) {
          const isInFilter = filterIds?.some(
            (relevantId) => (
              formProjectSet.has(relevantId)
              || cardProjectSet.has(relevantId)
              || formBucketSet.has(relevantId)
            ),
          );

          if (filterType === 'Buckets') return isInFilter || formBucketSet.has(filterId);
          return isInFilter;
        }

        // Else filter type is not defined:
        return true;
      });
    if (PAYABLE_FORM_TYPES.has(formType)) {
      retForms = retForms
        .filter(({ type }) => type === formType)
        .map((form) => {
          let costToDate = 0;
          if (formCostToDate[form.id] && formCostToDate[form.id].costToDate) {
            costToDate = formCostToDate[form.id].costToDate;
          }
          return {
            ...form,
            costToDate,
          };
        });
    }
    return retForms;
  }, [
    forms,
    formCostToDate,
    formType,
    filterId,
    filterType,
    cardId,
    isNotDisplay,
    filterIds,
    relevantBucketIds,
  ]);

  const query = useMemo(() => {
    const filter = {
      type: formType,
    };

    switch (filterType) {
      case 'Customers':
        filter.customerId = filterId;
        filter.buckets = relevantBucketIds;
        break;
      case 'Vendors':
        filter.vendorId = filterId;
        break;
      case 'Projects':
        filter.projectId = filterId;
        filter.buckets = relevantBucketIds;
        break;
      case 'Buckets':
        if (filterIds?.length) filter.projects = filterIds;
        if (filterId) filter.buckets = [filterId];
        break;
      case 'ProjectGroups':
        filter.projects = filterIds;
        break;
      default:
    }
    return filter;
  }, [filterId, filterType, formType, filterIds, relevantBucketIds]);

  useEffect(() => {
    if (PAYABLE_FORM_TYPES.has(formType)) {
      const currFormIds = Object.values(forms)
        .filter((form) => form.type === formType)
        .map((form) => form.id);
      if (currFormIds.length > 0) {
        dispatch(getFormInvoicesCostToDate({ formIds: currFormIds }));
      }
    }
  }, [dispatch, forms, visible, formType]);

  if (isNotDisplay) return null;
  return (
    <FormsList
      topOffset={topOffset}
      forms={formsList}
      hideDate={hideDate}
      range={range}
      onRangeChange={onRangeChange}
      query={query}
      visible={visible}
      isPayable={PAYABLE_FORM_TYPES.has(formType)}
      option={option}
      hideWhenEmpty={hideWhenEmpty}
      isPO={formType === 'PO'}
    />
  );
}