import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Col, DatePicker, Row } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import FormBuilder from '../FormBuilder/FormBuilder';
import FormSignature from './FormSignature';
import FormSubmitChildButton from '../FormSubmitChildButton';
import FormApprovalButton, {
  APPROVE_FORM_SUBMISSION, REJECT_FORM_SUBMISSION,
} from '../FormApprovalButton';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';

import Permissions from '../../auth/Permissions';
import { getIdMap } from '../../helpers/helpers';
import useToggle from '../../common/hooks/useToggle';
import SnapshotDrawer from '../../common/customFields/SnapshotDrawer';
import { getRelevantSnapshotData } from '../formHelpers';
import OnTraccrEmpty from '../../common/OnTraccrEmpty';
import { EDIT_TYPES } from '../ResponderHelpers';

export default function FormDetailFields({
  formId,
  formData,
  employeeSignature,
  allowResubmission,
  allowEdit,
  onEditClicked,
  allowSubmitChild,
  onSubmitChildClicked,
  childSubmitMode,
  setChildSubmitMode,
  sliderVisible,
  setSelectedFile,
  setSelectedFileDetails,
  isClosed,
  divisionId,
  approvals = [],
  formState,
}) {
  const formApprovals = useSelector((state) => state.forms.approvals);
  const childForms = useSelector((state) => state.forms.childForms);
  const formTemplates = useSelector((state) => state.forms.assignedFormTemplates);
  const formSnapshotMap = useSelector((state) => state.forms.formSnapshotMap);
  const users = useSelector((state) => state.users.users);

  const [range, setRange] = useState();
  const {
    isToggled,
    toggle,
  } = useToggle();

  const userMap = useMemo(() => getIdMap(users), [users]);
  const formTemplateMap = useMemo(() => getIdMap(formTemplates), [formTemplates]);
  const snapshots = useMemo(() => formSnapshotMap[formId] || [], [formSnapshotMap, formId]);

  const needsApproval = formId in formApprovals
    && (!formApprovals[formId].editable || formApprovals[formId].editableApprovals);

  // Allow on either the form template 'Allow Edit' option or the approval step 'Editable' option
  const canEdit = (needsApproval && !!formApprovals[formId]?.editableApprovals) || (allowEdit && Permissions.has('FORMS_WRITE'));
  const canResubmit = allowResubmission && Permissions.has('FORMS_WRITE');

  const childFormOptions = useMemo(() => (
    childForms.reduce((acc, id) => {
      if (id in formTemplateMap) {
        acc.push({
          value: id,
          label: formTemplateMap[id].name,
        });
      }
      return acc;
    }, [])
  ), [childForms, formTemplateMap]);

  const showSubmitChild = allowSubmitChild && childFormOptions.length;

  const snapshotRange = useMemo(() => {
    if (snapshots.length > 1) {
      return [
        moment.unix(snapshots[snapshots.length - 1].timestamp / 1000).startOf('day'),
        moment.unix(snapshots[0].timestamp / 1000).endOf('day'),
      ];
    }
    return null;
  }, [snapshots]);

  useEffect(() => {
    if (!sliderVisible) {
      setRange();
    } else if (snapshots.length > 1) {
      setRange(snapshotRange);
    }
  }, [snapshotRange, sliderVisible]);

  const disabledDate = useCallback((current) => {
    if (!snapshotRange) return false;
    return current < snapshotRange[0] || current > snapshotRange[1];
  }, [snapshotRange]);

  const relevantFormData = useMemo(() => (
    getRelevantSnapshotData({
      formData,
      range,
      snapshots,
      setSelectedFile,
      setSelectedFileDetails,
    })
  ), [formData, range, snapshots, setSelectedFile, setSelectedFileDetails]);

  const latestRejectionMessage = useMemo(() => {
    const sortedApprovals = [...approvals].sort((a, b) => b.timestamp - a.timestamp);
    const firstApproval = sortedApprovals?.[0];

    if (!firstApproval || firstApproval?.state !== 'rejected') return null;

    const { note, userId } = firstApproval;
    const rejecterName = userMap[userId]?.name ?? 'Unknown';

    let msg = `Form was rejected by ${rejecterName}`;

    if (note) msg += ` with note: ${note}`

    return msg;
  }, [approvals, userMap]);

  const showRejection = latestRejectionMessage && formState !== 'submitted';

  return (
    <>
      <div style={{
        height: '100%',
        width: '100%',
      }}
      >
        <FormBuilder
          formId={formId}
          isDisplay
          initialSections={relevantFormData ?? []}
          style={needsApproval ? {} : {
            bottom: 0,
            overflow: 'hidden',
          }}
          signatureView={<FormSignature employeeSignature={employeeSignature} />}
          shouldHideCustomRenderingFields
          divisions={[divisionId]}
          isDetailView
        >
          {showRejection && (
            <Row
              style={{ padding: '20px 35px 0px 20px', color: 'red' }}
            >
              {latestRejectionMessage}
            </Row>
          )}
          {snapshots.length > 1 && (
            <Row
              justify="space-between"
              style={{
                padding: '20px 35px 10px 20px',
              }}
            >
              <Col>
                <DatePicker.RangePicker
                  allowClear={false}
                  style={{ width: 250 }}
                  format="MMM Do YY"
                  disabledDate={disabledDate}
                  minDate
                  value={range}
                  onChange={setRange}
                  getPopupContainer={(node) => node.parentNode}
                />
              </Col>
              <OnTraccrButton
                title="View Snapshots"
                onClick={toggle}
              />
            </Row>
          )}
          {!relevantFormData && (
            <OnTraccrEmpty />
          )}
        </FormBuilder>
        {!childSubmitMode && !isClosed
          && (needsApproval || canResubmit || showSubmitChild || canEdit)
          ? (
            <div
              className="drawer-footer"
              style={{
                zIndex: 1000,
              }}
            >
              <Row justify="end" gutter={10}>
                {!!canResubmit && (
                  <OnTraccrButton
                    title="Resubmit"
                    onClick={onEditClicked(EDIT_TYPES.RESUBMIT)}
                    type="cancel"
                    style={{
                      marginRight: needsApproval ? 8 : 0,
                      margin: 2,
                    }}
                  />
                )}
                {!!canEdit && (
                  <OnTraccrButton
                    title="Edit"
                    onClick={onEditClicked(EDIT_TYPES.EDIT)}
                    type="cancel"
                    style={{
                      marginRight: needsApproval ? 8 : 0,
                      margin: 2,
                    }}
                  />
                )}
                {!!needsApproval && (
                  <FormApprovalButton
                    formId={formId}
                    type={REJECT_FORM_SUBMISSION}
                  />
                )}
                {!!needsApproval && (
                  <FormApprovalButton
                    formId={formId}
                    type={APPROVE_FORM_SUBMISSION}
                    sections={formData}
                  />
                )}
                {!!showSubmitChild && (
                  <FormSubmitChildButton
                    childForms={childFormOptions}
                    onSubmitChildClicked={onSubmitChildClicked}
                    setChildSubmitMode={setChildSubmitMode}
                  />
                )}
              </Row>
            </div>
          ) : null}
      </div>
      <SnapshotDrawer
        open={sliderVisible && isToggled}
        onClose={toggle}
        snapshots={snapshots}
        setSelectedFile={setSelectedFile}
        setSelectedFileDetails={setSelectedFileDetails}
      />
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
FormDetailFields.propTypes = {
  formData: PropTypes.array.isRequired,
  formId: PropTypes.string.isRequired,
  employeeSignature: PropTypes.object,
  allowEdit: PropTypes.bool.isRequired,
  allowResubmission: PropTypes.bool.isRequired,
  onEditClicked: PropTypes.func.isRequired,
  allowSubmitChild: PropTypes.bool.isRequired,
  onSubmitChildClicked: PropTypes.func.isRequired,
  childSubmitMode: PropTypes.bool.isRequired,
  setChildSubmitMode: PropTypes.func.isRequired,
  sliderVisible: PropTypes.bool.isRequired,
  setSelectedFile: PropTypes.func.isRequired,
  setSelectedFileDetails: PropTypes.func.isRequired,
  isClosed: PropTypes.bool,
  divisionId: PropTypes.string.isRequired,
  approvals: PropTypes.array,
  formState: PropTypes.string.isRequired,
};

FormDetailFields.defaultProps = {
  employeeSignature: undefined,
  isClosed: false,
  approvals: [],
};
