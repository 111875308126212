import React from 'react';
import { DateTime } from 'luxon';
import { Popover, Row } from 'antd';
import { CopyOutlined, EditOutlined, ExclamationOutlined } from '@ant-design/icons';
import { TaskHelpers } from 'ontraccr-common';

import { msToString } from './timecard.helpers';
import sortByString, { capitalizeFirstCharacter, sortByCode } from '../helpers/helpers';
import DateTimeWithZone from '../common/text/DateTimeWithZone';
import Colors from '../constants/Colors';
import BorderlessButton from '../common/buttons/BorderlessButton';
import TimeCardApprovalButton, { APPROVE_TIMECARD_SUBMISSION, REJECT_TIMECARD_SUBMISSION } from './TimeCardApprovalButton';
import { roundTotalRuntime } from '../helpers/time';
import { getEnteredViaTableText, sortByNameAndStartTime } from '../helpers/tasks';

// helpers functions / constants

export const EMPTY_TEXT = '--';

export const ITEMS_PER_SUMMARY_PAGE = 300;

const COLUMNS_TO_IGNORE = new Set(['edit', 'copy', 'approveReject']);

const parseDayBoundary = (boundary) => (
  boundary?.dt
    ? boundary?.dt.toLocaleString(DateTime.TIME_SIMPLE)
    : EMPTY_TEXT
);

export const getFormattedListViewTask = ({
  task,
  costCodeMap,
  phaseMap,
  enableHourBasedTracking,
  unionClassMap,
  unionLocalMap,
  unionMap,
  entryDataMap,
  userMap,
}) => {
  const {
    id,
    costcodeId,
    phaseId,
    hourBased,
    classId,
    userId,
    approvers,
  } = task;

  const costCode = costCodeMap[costcodeId];
  const phase = phaseMap[phaseId]?.[0];

  const hideStartEndTime = hourBased && enableHourBasedTracking;
  const workClass = unionClassMap[classId];
  const localClass = unionLocalMap[workClass?.localId];
  const union = unionMap[localClass?.unionId];
  const name = userMap[userId]?.name;

  const formData = {};
  const { data: customData, divisionId } = entryDataMap[id] ?? {};

  if (customData?.length) {
    customData.forEach(({ fields }) => {
      fields.forEach(({
        fieldId,
        response,
        type,
        title,
      }) => {
        formData[fieldId] = TaskHelpers.formatCustomDataForTimeTable({
          response,
          type,
          title,
        });
      });
    });
  }

  return {
    ...task,
    ...formData,
    approvedBy: approvers
      ?.filter((approver) => !!approver)
      .map((approver) => approver.name)
      .join(','),
    costCode: costCode ? `${costCode?.code} - ${costCode?.name}` : EMPTY_TEXT,
    phase: phase?.name,
    hideStartEndTime,
    duration: task.endTime ? msToString(task.endTime - task.startTime) : EMPTY_TEXT,
    union: union?.name,
    localClass: localClass?.name,
    workingClass: workClass?.name,
    type: capitalizeFirstCharacter(task.type),
    name,
    divisionId,
  };
};

export const getCustomFieldMap = (customFields) => {
  const map = {};
  Object.values(customFields).forEach(({ divisionId, fields: sections }) => {
    sections?.forEach?.(({ fields }) => {
      fields?.forEach?.((subField) => {
        map[subField.id] = {
          title: subField.configProps.title,
          columns: subField.configProps.columns ?? [],
          divisionId,
        };
      });
    });
  });
  return map;
};

const getCustomFieldChildren = (customField, fieldId) => (
  customField?.columns?.map((column) => (
    {
      title: column.name,
      dataIndex: [fieldId, column.key],
      key: column.key,
      width: 150,
      render: (data) => (
        /* eslint-disable react/no-array-index-key */
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 200,
            gap: 4,
          }}
        >
          {data?.map((value, idx) => (
            <div
              key={`${fieldId}-${column.key}-${idx}`}
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
                height: '22px',
              }}
            >
              {value}
            </div>
          ))}
        </div>
      ),
    }
  ))
);

const getTaskTime = (
  key,
  task,
) => {
  if (!task) return 0;
  switch (key) {
    case 'breakStartTime':
      return task.breakStartTime || (task.type === 'Break' ? task.startTime : 0);
    case 'breakEndTime':
      return task.breakEndTime || (task.type === 'Break' ? task.endTime : 0);
    case 'otStartTime':
      return task.otStartTime || (task.type === 'Overtime' ? task.startTime : 0);
    case 'otEndTime':
      return task.otEndTime || (task.type === 'Overtime' ? task.endTime : 0);
    default:
      return task[key];
  }
};

// columns
const startTimeColumn = {
  title: 'Regular Start Time',
  key: 'RegularStartTime',
  dataIndex: 'startTime',
  width: 80,
  sorter: (a, b) => (a?.startTime ?? 0) - (b?.startTime ?? 0),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
  render: (startTime, { type, hideStartEndTime, timezone }) => (
    !hideStartEndTime && startTime && type !== 'Break' && type !== 'Overtime'
      ? <DateTimeWithZone startTime={startTime} timezone={timezone} />
      : EMPTY_TEXT
  ),
};

const endTimeColumn = {
  title: 'Regular End Time',
  key: 'RegularEndTime',
  dataIndex: 'endTime',
  width: 80,
  sorter: (a, b) => (a?.endTime ?? 0) - (b?.endTime ?? 0),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
  render: (endTime, { type, hideStartEndTime, timezone }) => (
    !hideStartEndTime && endTime && type !== 'Break' && type !== 'Overtime'
      ? <DateTimeWithZone startTime={endTime} timezone={timezone} />
      : EMPTY_TEXT
  ),
};

const breakStartTimeColumn = {
  title: 'Break Start Time',
  key: 'BreakStartTime',
  dataIndex: 'breakStartTime',
  width: 80,
  sorter: (a, b) => (getTaskTime('breakStartTime', a) ?? 0) - (getTaskTime('breakStartTime', b) ?? 0),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
  render: (_, {
    startTime,
    breakStartTime,
    type,
    hideStartEndTime,
    timezone,
  }) => {
    const relevantTime = getTaskTime('breakStartTime', { startTime, breakStartTime, type });
    return !hideStartEndTime && relevantTime
      ? <DateTimeWithZone startTime={relevantTime} timezone={timezone} />
      : EMPTY_TEXT;
  },
};

const breakEndTimeColumn = {
  title: 'Break End Time',
  key: 'BreakEndTime',
  dataIndex: 'breakEndTime',
  width: 80,
  sorter: (a, b) => (getTaskTime('breakEndTime', a) ?? 0) - (getTaskTime('breakEndTime', b) ?? 0),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
  render: (_, {
    endTime,
    breakEndTime,
    type,
    hideStartEndTime,
    timezone,
  }) => {
    const relevantTime = getTaskTime('breakEndTime', { endTime, breakEndTime, type });
    return !hideStartEndTime && relevantTime
      ? <DateTimeWithZone startTime={relevantTime} timezone={timezone} />
      : EMPTY_TEXT;
  },
};

const otStartTimeColumn = {
  title: 'OT Start Time',
  key: 'OTStartTime',
  dataIndex: 'otStartTime',
  width: 80,
  sorter: (a, b) => (getTaskTime('otStartTime', a) ?? 0) - (getTaskTime('otStartTime', b) ?? 0),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
  render: (_, {
    startTime,
    otStartTime,
    type,
    hideStartEndTime,
    timezone,
  }) => {
    const relevantTime = getTaskTime('otStartTime', { startTime, otStartTime, type });
    return !hideStartEndTime && relevantTime
      ? <DateTimeWithZone startTime={relevantTime} timezone={timezone} />
      : EMPTY_TEXT;
  },
};

const otEndTimeColumn = {
  title: 'OT End Time',
  key: 'OTEndTime',
  dataIndex: 'otEndTime',
  width: 80,
  sorter: (a, b) => (getTaskTime('otEndTime', a) ?? 0) - (getTaskTime('otEndTime', b) ?? 0),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
  render: (_, {
    endTime,
    otEndTime,
    type,
    hideStartEndTime,
    timezone,
  }) => {
    const relevantTime = getTaskTime('otEndTime', { endTime, otEndTime, type });
    return !hideStartEndTime && relevantTime
      ? <DateTimeWithZone startTime={relevantTime} timezone={timezone} />
      : EMPTY_TEXT;
  },
};

const doubleOTStartTimeColumn = {
  title: 'DT OT Start Time',
  key: 'DoubleOTStartTime',
  dataIndex: 'doubleOTStartTime',
  width: 80,
  sorter: (a, b) => (getTaskTime('doubleOTStartTime', a) ?? 0) - (getTaskTime('doubleOTStartTime', b) ?? 0),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
  render: (_, {
    doubleOTStartTime,
    hideStartEndTime,
    timezone,
  }) => (!hideStartEndTime && doubleOTStartTime
    ? <DateTimeWithZone startTime={doubleOTStartTime} timezone={timezone} />
    : EMPTY_TEXT),
};

const doubleOTEndTimeColumn = {
  title: 'DT OT End Time',
  key: 'DoubleOTEndTime',
  dataIndex: 'doubleOTEndTime',
  width: 80,
  sorter: (a, b) => (getTaskTime('doubleOTEndTime', a) ?? 0) - (getTaskTime('doubleOTEndTime', b) ?? 0),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
  render: (_, {
    doubleOTEndTime,
    hideStartEndTime,
    timezone,
  }) => (!hideStartEndTime && doubleOTEndTime
    ? <DateTimeWithZone startTime={doubleOTEndTime} timezone={timezone} />
    : EMPTY_TEXT),
};

const durationColumn = {
  title: 'Duration',
  key: 'Duration',
  dataIndex: 'duration',
  width: 100,
  sorter: (a, b) => (
    ((a?.endTime ?? 0) - (a?.startTime ?? 0)) - ((b?.endTime ?? 0) - (b?.startTime ?? 0))
  ),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
};

const typeColumn = {
  title: 'Type',
  key: 'Type',
  dataIndex: 'type',
  width: 80,
  sorter: sortByString('type'),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
};

const approvedByColumn = {
  title: 'Approved By',
  key: 'ApprovedBy',
  dataIndex: 'approvedBy',
  width: 80,
  sorter: sortByString('approvedBy'),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
};

const phaseColumn = {
  title: 'Phase',
  key: 'Phase',
  width: 100,
  dataIndex: 'phase',
  sorter: sortByString('phase'),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
};

const costCodeColumn = {
  title: 'Cost Code',
  key: 'CostCode',
  dataIndex: 'costCode',
  width: 120,
  sorter: sortByCode('costcode_code'),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
};

const unionColumn = {
  title: 'Union',
  key: 'Union',
  width: 100,
  dataIndex: 'union',
  sorter: sortByString('union'),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
};

const localClassColumn = {
  title: 'Local Class',
  key: 'LocalClass',
  dataIndex: 'localClass',
  width: 120,
  sorter: sortByString('localClass'),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
};

const workClassColumn = {
  title: 'Work Class',
  key: 'WorkClass',
  width: 120,
  dataIndex: 'workingClass',
  sorter: sortByString('workingClass'),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
};

const notesColumn = {
  title: 'Notes',
  key: 'Notes',
  dataIndex: 'note',
  width: 200,
  sorter: sortByString('note'),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
};

const nameColumn = {
  title: 'Name',
  key: 'name',
  dataIndex: 'name',
  width: 120,
  sorter: sortByString('name'),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
};

const summaryDateColumn = {
  title: 'Date',
  key: 'date',
  dataIndex: 'date',
  width: 150,
  render: (_date, record) => {
    const taskData = TaskHelpers.getTaskDate(record);
    return taskData?.toLocaleString?.(DateTime.DATE_FULL) ?? '';
  },
  sorter: (a, b) => {
    const dateTimeA = TaskHelpers.getTaskDate(a);
    const dateTimeB = TaskHelpers.getTaskDate(b);

    return dateTimeA - dateTimeB;
  },
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
};

const dayDateColumn = {
  title: 'Date',
  dataIndex: 'key',
  key: 'day',
  width: 150,
};

const dayTotalTimeColumn = ({ roundingInterval, roundingType, roundingSetting }) => ({
  title: 'Total Time',
  dataIndex: 'total',
  key: 'totalTime',
  width: 100,
  render: (data) => (
    <span>
      {msToString(
        roundTotalRuntime(data, roundingInterval, roundingType, roundingSetting)
      )}
    </span>
  ),
});

const dayStartTime = {
  title: 'Start Time',
  dataIndex: 'minTS',
  key: 'StartTime',
  width: 80,
  render: (data) => (
    <span>
      {parseDayBoundary(data)}
    </span>
  ),
};

const dayEndTime = {
  title: 'End Time',
  dataIndex: 'maxTS',
  key: 'EndTime',
  width: 80,
  render: (data) => (
    <span>
      {parseDayBoundary(data)}
    </span>
  ),
};

const dayRegularStartTime = {
  title: 'Regular Start Time',
  dataIndex: 'minRegularTS',
  key: 'RegularStartTime',
  width: 80,
  render: (data) => (
    <span>
      {parseDayBoundary(data)}
    </span>
  ),
};

const dayRegularEndTime = {
  title: 'Regular End Time',
  dataIndex: 'maxRegularTS',
  key: 'RegularEndTime',
  width: 80,
  render: (data) => (
    <span>
      {parseDayBoundary(data)}
    </span>
  ),
};

const dayBreakStartTime = {
  title: 'Break Start',
  dataIndex: 'minBreakTS',
  key: 'BreakStartTime',
  width: 80,
  render: (data) => (
    <span>
      {parseDayBoundary(data)}
    </span>
  ),
};

const dayBreakEndTime = {
  title: 'Break End',
  dataIndex: 'maxBreakTS',
  key: 'BreakEndTime',
  width: 80,
  render: (data) => (
    <span>
      {parseDayBoundary(data)}
    </span>
  ),
};

const dayOTStartTime = {
  title: 'OT Start',
  dataIndex: 'minOvertimeTS',
  key: 'OTStartTime',
  width: 80,
  render: (data) => (
    <span>
      {parseDayBoundary(data)}
    </span>
  ),
};

const dayOTEndTime = {
  title: 'OT End',
  dataIndex: 'maxOvertimeTS',
  key: 'OTEndTime',
  width: 80,
  render: (data) => (
    <span>
      {parseDayBoundary(data)}
    </span>
  ),
};

const dayDoubleOTStartTime = {
  title: 'DT OT Start',
  dataIndex: 'minDoubleOvertimeTS',
  key: 'DoubleOTStartTime',
  width: 80,
  render: (data) => (
    <span>
      {parseDayBoundary(data)}
    </span>
  ),
};

const dayDoubleOTEndTime = {
  title: 'DT OT End',
  dataIndex: 'maxDoubleOvertimeTS',
  key: 'DoubleOTEndTime',
  width: 80,
  render: (data) => (
    <span>
      {parseDayBoundary(data)}
    </span>
  ),
};

const violationColumn = ({
  isSummary = false,
}) => {
  const baseOpts = {
    title: 'Violation',
    dataIndex: 'hasViolation',
    key: 'hasViolation',
    width: 100,
    align: 'center',
    render: (_, record) => {
      let hasViolation;
      let hasOutsideWorkingHours;
      if (isSummary) {
        hasViolation = record.geofenceViolation;
        hasOutsideWorkingHours = record.outsideWorkingHours;
      } else {
        hasViolation = record.dayTasks.some((task) => task.geofenceViolation);
        hasOutsideWorkingHours = record.dayTasks.some((task) => task.outsideWorkingHours);
      }
      const content = [];
      if (hasViolation) content.push('This Time Card has a geofence breach');
      if (hasOutsideWorkingHours) content.push('This Time Card is outside of working hours');
      if (hasViolation || hasOutsideWorkingHours) {
        return (
          <Popover
            trigger="hover"
            content={content.join('\n')}
            placement="topRight"
          >
            <ExclamationOutlined
              style={{
                color: 'white',
                backgroundColor: Colors.ONTRACCR_RED,
                padding: 1,
                height: 12,
                width: 12,
                borderRadius: 6,
                fontSize: 10,
              }}
            />
          </Popover>
        );
      }
      return null;
    },
  };

  let sortOpts = {};

  if (isSummary) {
    sortOpts = {
      sorter: (a, b) => {
        const aViolation = (a.geofenceViolation || a.outsideWorkingHours) ? 1 : 0;
        const bViolation = (b.geofenceViolation || b.outsideWorkingHours) ? 1 : 0;

        return aViolation - bViolation;
      },
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    };
  }

  return { ...baseOpts, ...sortOpts };
};

const rejectionColumn = {
  title: 'Rejection',
  dataIndex: 'hasRejection',
  key: 'hasRejection',
  width: 100,
  align: 'center',
  render: (_, record) => {
    const latestTimeCardEvent = record.dayHistory[record.dayHistory.length - 1] ?? {};
    const isRecentlyRejected = latestTimeCardEvent?.type === 'rejected';
    if (isRecentlyRejected) {
      const { timestamp = 0, note } = latestTimeCardEvent;
      const date = DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATETIME_MED);
      const noteMsg = note ? ` with a note: ${note}` : '.';
      return (
        <Popover
          trigger="hover"
          placement="topRight"
          content={(
            <div>
              <Row>
                <b>{`Timecard Rejected - ${date}`}</b>
              </Row>
              <Row>
                {`This Timecard was rejected${noteMsg}`}
              </Row>
            </div>
          )}
        >
          <ExclamationOutlined
            style={{
              color: 'white',
              backgroundColor: Colors.ONTRACCR_RED,
              padding: 1,
              height: 12,
              width: 12,
              borderRadius: 6,
              fontSize: 10,
            }}
          />
        </Popover>
      );
    }
    return null;
  },
};

const statusColumn = {
  title: 'Status',
  key: 'Status',
  dataIndex: 'state',
  sorter: sortByString('state'),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
  render: (data) => {
    let fillColor;
    let text;

    switch (data) {
      case 'submitted':
        fillColor = 'yellow';
        text = 'Submitted';
        break;
      case 'approved':
        fillColor = 'green';
        text = 'Approved';
        break;
      default:
        fillColor = 'black';
        text = 'Unsubmitted';
        break;
    }

    return (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <svg
          height="15"
          width="15"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginRight: 5 }}
        >
          <circle cx="10" cy="10" r="10" fill={fillColor} stroke="black" strokeWidth="1" />
        </svg>
        { text }
      </div>
    );
  },
};

const approveRejectColumn = ({
  userId = null,
  isSummary = false,
}) => ({
  title: '',
  key: 'approveReject',
  dataIndex: 'approveReject',
  width: 100,
  fixed: isSummary ? 'right' : undefined,
  render: (_, record) => (
    <div style={{ display: 'flex' }}>
      <TimeCardApprovalButton
        record={record}
        type={APPROVE_TIMECARD_SUBMISSION}
        userId={isSummary ? record.userId : userId}
        isSummary={isSummary}
      />
      <TimeCardApprovalButton
        record={record}
        type={REJECT_TIMECARD_SUBMISSION}
        style={{ backgroundColor: 'grey', borderColor: 'grey' }}
        userId={isSummary ? record.userId : userId}
        isSummary={isSummary}
      />
    </div>
  ),
});

const editColumn = ({
  onEdit,
  isSummary = false,
}) => ({
  title: '',
  dataIndex: 'edit',
  key: 'edit',
  width: 50,
  align: 'center',
  render: (_, record) => {
    let canEditCards;
    if (isSummary) {
      canEditCards = record.canEdit;
    } else {
      canEditCards = record.dayTasks.some((task) => task.canEdit);
    }

    if (!canEditCards) return null;
    return (
      <BorderlessButton
        style={{ padding: 0, width: 20 }}
        iconNode={<EditOutlined style={{ marginLeft: 0 }} />}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          return isSummary ? onEdit(record.id, false) : onEdit(record.key);
        }}
      />
    );
  },
});

const copyColumn = ({
  onCopy,
}) => ({
  title: '',
  dataIndex: 'copy',
  key: 'copy',
  width: 50,
  align: 'center',
  fixed: 'right',
  render: (_, record) => {
    const { canEdit } = record;

    if (!canEdit) return null;

    return (
      <BorderlessButton
        style={{ padding: 0, width: 20 }}
        iconNode={<CopyOutlined style={{ marginLeft: 0 }} />}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onCopy(record.id, true);
        }}
      />
    );
  },
});

const projectColumn = (({ t }) => ({
  title: t('Project'),
  key: 'Project',
  dataIndex: 'projectName',
  width: 100,
  sorter: sortByString('projectName'),
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
  render: (projectName, data) => (
    data?.projectNumber
      ? `${data.projectNumber} - ${projectName}`
      : projectName
  ),
}));

const enteredViaColumn = ({
  title: 'Entered Via',
  key: 'enteredVia',
  width: 200,
  render: (_, record) => getEnteredViaTableText(record.enteredViaMetadata),
});

// table columns

export const getSummaryViewColumns = ({
  t,
  onEditCopy,
  isApprovals,
  divisionIds,
  customFieldMap,
  selectedTimeCardSummaryColumns,
  selectedCustomFieldColumns,
}) => {
  let baseCols = [
    editColumn({ onEdit: onEditCopy, isSummary: true }),
    nameColumn,
    summaryDateColumn,
    startTimeColumn,
    endTimeColumn,
    breakStartTimeColumn,
    breakEndTimeColumn,
    otStartTimeColumn,
    otEndTimeColumn,
    doubleOTStartTimeColumn,
    doubleOTEndTimeColumn,
    durationColumn,
    typeColumn,
    projectColumn({ t }),
    phaseColumn,
    costCodeColumn,
    unionColumn,
    localClassColumn,
    workClassColumn,
    notesColumn,
    enteredViaColumn,
    approvedByColumn,
    violationColumn({ isSummary: true }),
  ];

  const selectedColumns = selectedTimeCardSummaryColumns || [];

  if (selectedCustomFieldColumns) {
    selectedCustomFieldColumns.forEach((fieldId) => {
      const customField = customFieldMap[fieldId];

      if (customField && divisionIds.has(customField.divisionId)) {
        const children = getCustomFieldChildren(customField, fieldId);
        baseCols.push({
          title: customField.title,
          dataIndex: fieldId,
          key: fieldId,
          width: 100,
          children,
        });
        selectedColumns.push(fieldId);
      }
    });
  }

  if (!isApprovals) {
    baseCols.push(statusColumn);
    baseCols.push(copyColumn({ onCopy: onEditCopy }));
  } else {
    baseCols.push(approveRejectColumn({ isSummary: true }));
  }

  if (selectedTimeCardSummaryColumns) {
    const columnMap = new Set(selectedColumns);
    baseCols = baseCols.filter((item) => {
      if (COLUMNS_TO_IGNORE.has(item.key)) return true;
      return columnMap.has(item.key);
    });
  }

  return baseCols;
};

export const getListViewDayColumns = ({
  roundingInterval,
  roundingType,
  roundingSetting,
  selectedDayEntryColumns,
  isApprovals,
  onEdit,
  userId,
}) => {
  const baseCols = [
    dayDateColumn,
    dayTotalTimeColumn({ roundingInterval, roundingType, roundingSetting }),
    dayStartTime,
    dayEndTime,
    dayRegularStartTime,
    dayRegularEndTime,
    dayBreakStartTime,
    dayBreakEndTime,
    dayOTStartTime,
    dayOTEndTime,
    dayDoubleOTStartTime,
    dayDoubleOTEndTime,
    violationColumn({ isSummary: false }),
    rejectionColumn,
  ];

  let relevantItems = [...baseCols];

  if (selectedDayEntryColumns) {
    const columnMap = new Set(selectedDayEntryColumns);
    relevantItems = baseCols.filter((item) => columnMap.has(item.key));
  }

  if (isApprovals) {
    relevantItems.push(approveRejectColumn({ userId, isSummary: false }));
  }

  relevantItems.push(editColumn({ onEdit }));

  return relevantItems;
};

export const getListViewExpandedColumns = ({
  t,
  selectedTimeCardEntryColumns,
  selectedCustomFieldColumns,
  divisionIds,
  customFieldMap,
}) => {
  let baseCols = [
    startTimeColumn,
    endTimeColumn,
    breakStartTimeColumn,
    breakEndTimeColumn,
    otStartTimeColumn,
    otEndTimeColumn,
    doubleOTStartTimeColumn,
    doubleOTEndTimeColumn,
    durationColumn,
    typeColumn,
    projectColumn({ t }),
    phaseColumn,
    costCodeColumn,
    unionColumn,
    localClassColumn,
    workClassColumn,
    notesColumn,
    enteredViaColumn,
    approvedByColumn,
  ];

  const selectedColumns = selectedTimeCardEntryColumns || [];

  if (selectedCustomFieldColumns) {
    selectedCustomFieldColumns.forEach((fieldId) => {
      const customField = customFieldMap[fieldId];

      if (customField && divisionIds.has(customField.divisionId)) {
        const children = getCustomFieldChildren(customField, fieldId);
        baseCols.push({
          title: customField.title,
          dataIndex: fieldId,
          key: fieldId,
          width: 100,
          children,
        });
        selectedColumns.push(fieldId);
      }
    });
  }

  baseCols.push(statusColumn);

  if (selectedTimeCardEntryColumns) {
    const columnMap = new Set(selectedColumns);
    baseCols = baseCols.filter((item) => columnMap.has(item.key));
  }

  return baseCols;
};

export const sortEntries = ({
  startDt,
  endDt,
  payrollTasks = [],
  userMap = {},
}) => {
  if (endDt < startDt) return [];
  const numDays = Math.ceil(endDt.diff(startDt, 'days').as('days'));
  if (Number.isNaN(numDays)) return [];
  const daysArr = new Array(numDays + 1).fill().map(() => []);
  /*
      Need to sort by startTime per day to ensure the ordering
      of hour based entries
  */
  let dayEntries = [];
  let lastDay = null;
  const numEntries = payrollTasks.length;
  payrollTasks.forEach((entry, idx) => {
    const entryDT = TaskHelpers.getTaskDate(entry);
    const isLast = idx === (numEntries - 1);
    const newDate = entryDT?.toSQLDate() !== lastDay?.toSQLDate();
    if (newDate) {
      if (lastDay && dayEntries.length) {
        dayEntries.sort(sortByNameAndStartTime(userMap));
        const index = lastDay?.diff(startDt, 'days').as('days') ?? 0;
        daysArr[index]?.push?.(...dayEntries);
      }
      lastDay = entryDT?.startOf('day') ?? null;
      dayEntries = [entry];
    } else {
      dayEntries.push(entry);
    }

    if (isLast) {
      dayEntries.sort(sortByNameAndStartTime(userMap));
      const index = lastDay?.diff(startDt, 'days').as('days') ?? 0;
      daysArr[index]?.push?.(...dayEntries);
    }
  });
  return daysArr.flat();
};

export default {};
