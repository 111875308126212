import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  List,
  notification,
  message,
  Spin,
} from 'antd';
import { AutoBreak, TaskHelpers } from 'ontraccr-common';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import FullPhoto from '../../common/photos/FullPhoto';

import ManualEntryFormDrawer from './ManualEntryFormDrawer';
import ManualEntryCard from './ManualEntryCard';
import ManualEntryFooter from './ManualEntryFooter';
import ManualEntrySignature from './ManualEntrySignature';

import {
  loadManualEntryData,
  addOrUpdateEntries,
  prepareManualEntryPayload,
  foundTaskConflict,
} from './manualEntryHelpers';

import { getApprovals } from '../state/clockin.actions';
import { getCustomFields, getSignatureURL } from '../../timecards/state/timecards.actions';
import { getUsers } from '../../users/state/users.actions';
import { getCustomerLabels, getCustomers } from '../../contacts/customers/state/customers.actions';
import { getEquipment } from '../../equipment/state/equipment.actions';
import { getMaterials } from '../../materials/state/materials.actions';
import { getCustomTables } from '../../forms/state/forms.actions';
import TimeTracking from '../../state/timeTracking';

import { getIdMap, toTitleCase } from '../../helpers/helpers';
import { downloadFile, getFileType } from '../../files/fileHelpers';
import {
  prepareResponsePayload,
} from '../../forms/ResponderHelpers';
import ManualEntryLocation from './ManualEntryLocation';
import { onNextFileClick } from '../../helpers/fileHelpers';
import { TIME_TYPE_FLAG } from '../../constants/Flags';

function ManualEntry({
  user = {},
  onClose,
  visible,
  personal,
  initialEntries,
  defaultDate,
  isDisplay,
  onEditClicked,
  canEditApproved,
  canEditUnapproved,
  showFormDrawer,
  setShowFormDrawer,
  day,
  isApprovals = false,
  isSummary,
  updateManualEntryData,
  isCopying,
  setIsCopying,
  setIsLoading,
}) {
  const {
    classId: defaultClassId,
  } = user;

  const dispatch = useDispatch();

  const { classes = [] } = useSelector((state) => state.unions);
  const profile = useSelector((state) => state.profile.profile);
  const phases = useSelector((state) => state.costcodes.phases);
  const {
    settings = {},
  } = useSelector((state) => state.settings.company || {});
  const customFields = useSelector((state) => state.timecards.customFields);
  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);
  const users = useSelector((state) => state.users.users);
  const projects = useSelector((state) => state.projects.projects);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const positions = useSelector((state) => state.settings.positions);
  const { paidFlags = [] } = useSelector((state) => state.settings.company);
  const mainLoaded = useSelector((state) => (state.main.loaded));

  const paidFlagSet = new Set(paidFlags);

  const {
    enableHourBasedTracking = false,
  } = settings;

  const userMap = useMemo(() => getIdMap(users), [users]);
  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);

  const userId = user?.id ?? profile?.id;
  const tasks = timeEntryUserMap[userId] ?? [];

  const [entries, setEntries] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState();
  const [loading, setLoading] = useState(false);
  const [customDataLoading, setCustomDataLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileDetails, setSelectedFileDetails] = useState();
  const [fileMap, setFileMap] = useState({});
  const [locationDrawerConfig, setLocationDrawerConfig] = useState();
  const [signatureUrl, setSignatureURL] = useState();
  const [hasLoadedCustomData, setHasLoadedCustomData] = useState(false);
  const [hasLoadedSummaryTask, setHasLoadedSummaryTask] = useState(false);
  const [deletedIds, setDeletedIds] = useState(new Set());
  const [initialLoaded, setInitialLoaded] = useState(false);

  const onCloseLocationDrawer = useCallback(() => setLocationDrawerConfig(), []);

  const hideFormDrawer = useCallback(() => {
    setShowFormDrawer(false);
    setSelectedEntry();
    setIsCopying(false);
  }, []);
  const onFileClose = useCallback(() => {
    setSelectedFile();
    setSelectedFileDetails();
  }, []);

  const onSubmit = useCallback(async () => {
    setLoading(true);

    const newEntries = entries.map((task) => {
      // If customData is an array, the user hasnt modified it
      // If customData is a response object we need to prepare for uploading
      if (!task.customData || Array.isArray(task.customData)) return task;
      const { divisionId } = task;
      const {
        [divisionId]: { fields: sections = [] } = {},
      } = customFields;
      const {
        responses: preparedResponses,
      } = prepareResponsePayload({
        sections,
        responses: task.customData,
      });
      return {
        ...task,
        customData: preparedResponses,
        isUpdated: true,
      };
    });
    const payloadTasks = TaskHelpers.getEntriesDiff({
      entries: newEntries,
      initialEntries: initialEntries.filter((entry) => !!TaskHelpers.getEndTime(entry)),
      // New entries have uuid that is like acfaeeb8-b766-419a-a099-082f01263fe3
      // Existing like d34cf3491624ca1a95a52a2897cecc8af3c2
      entryIsNew: (e) => e.id?.includes('-'),
      onFail: () => {
        notification.error({
          key: 'error',
          message: 'Error',
          description:
            'Unable to create tasks in the future, please change the clock-in/out times',
          duration: 5,
        });
      },
      keepId: true, // We need the ids for auto break merging
    });

    const tasksByUserId = {};
    const userIds = new Set();
    newEntries.forEach((entry) => {
      userIds.add(entry.userId);
      if (!tasksByUserId[entry.userId]) tasksByUserId[entry.userId] = [];
      tasksByUserId[entry.userId].push(entry);
    });
    const initialEntryIds = new Set(initialEntries.map(({ id }) => id));
    Array.from(userIds).forEach((taskUserId) => {
      const ourUser = userMap[taskUserId];
      const userPermissions = new Set(positions[ourUser.position] || []);
      const ourTasks = timeEntryUserMap[taskUserId] ?? [];
      const ourNewTasks = tasksByUserId[taskUserId];

      // Get the new set of user's tasks including their history, excluding running
      const ourFullEditedTasks = [];
      ourTasks.forEach((task) => {
        if (!initialEntryIds.has(task.id) && task.endTime) {
          ourFullEditedTasks.push(task);
        }
      });
      ourFullEditedTasks.push(...ourNewTasks);

      const affectedDays = new Set();
      ourNewTasks.forEach((task) => {
        affectedDays.add(TaskHelpers.getTaskDate(task).toSQLDate());
      });

      Array.from(affectedDays).forEach((taskDay) => {
        const userAutoBreakDiff = AutoBreak({
          settings,
          tasks: ourFullEditedTasks,
          userPermissions,
          notifyError: () => {
            notification.error({
              key: 'error',
              message: 'Error',
              description:
                `We were unable to add an automatic break for ${ourUser.name} on ${taskDay}. Please add one manually if applicable.`,
              duration: 5,
            });
          },
          day: DateTime.fromSQL(taskDay),
        });
        TaskHelpers.mergeManualEntries(payloadTasks, userAutoBreakDiff);
      });
    });
    if (!payloadTasks) {
      setLoading(false);
      return;
    }
    const {
      tasksToEdit,
    } = payloadTasks;
    const payload = {
      payload: await prepareManualEntryPayload(payloadTasks),
      userId,
      isSubmitted: isApprovals,
    };
    if (await dispatch(TimeTracking.manualEntry(payload))) {
      if (isApprovals) dispatch(getApprovals());
      onClose();
      updateManualEntryData?.(tasksToEdit.map((task) => task.id));
    }
    setLoading(false);
  }, [
    entries,
    userId,
    personal,
    initialEntries,
    customFields,
    isApprovals,
    updateManualEntryData,
    settings,
    userMap,
    positions,
    timeEntryUserMap,
    defaultDate,
  ]);

  const onSubmitEntry = useCallback(({
    task, breakObject, overtime, users: taskUsers,
  }, hourBased) => {
    let conflictMessage;
    if (!task && !breakObject && !overtime && !taskUsers) return;
    let parsedEntries = [];
    let hasSelectedEntryUser = false;
    taskUsers.forEach((taskUserId) => {
      const splitFn = paidFlagSet.has(TIME_TYPE_FLAG)
        ? TaskHelpers.createTasksFromTaskAndBreak
        : TaskHelpers.createTasksFromTaskAndBreakOriginal;
      const splitEntries = !hourBased || !paidFlagSet.has(TIME_TYPE_FLAG)
        ? splitFn({
          task,
          breakObject,
        }) : [task];

      const fullEntries = overtime && !paidFlagSet.has(TIME_TYPE_FLAG)
        ? splitEntries.concat([overtime])
        : splitEntries;

      let ourParsedEntries = entries.filter(({ userId: entryUserId }) => (
        taskUserId === entryUserId
      ));
      const editIds = new Set();

      fullEntries.forEach((newEntry) => {
        let selectedEntryToPass = isCopying ? null : selectedEntry;

        // This can only occur if the user is editing an existing entry
        // And adds an inline break that results in an entry being split into two
        // In this case we want to update the existing entry
        // And create a new entry for the split
        // The creation will occur when we pass a null selected entry
        if (
          selectedEntry
          && paidFlagSet.has(TIME_TYPE_FLAG)
          && splitEntries.length > 1
          && newEntry.breakStartTime
          && newEntry.startTime
        ) {
          selectedEntryToPass = null;
        }

        const { formattedEntries, newId } = addOrUpdateEntries({
          entries: ourParsedEntries,
          newEntry,
          selectedEntry: selectedEntryToPass,
          userId: taskUserId,
        });
        ourParsedEntries = formattedEntries;
        if (newId) {
          editIds.add(newId);
        } else if (selectedEntry?.id) {
          editIds.add(selectedEntry.id);
        }
      });

      const daysManualEntries = [];
      const daysManualEntryIds = new Set();
      entries.forEach((entry) => {
        if (
          !editIds.has(entry.id)
          && entry.userId === taskUserId
        ) {
          daysManualEntries.push(entry);
          daysManualEntryIds.add(entry.id);
        }
      });

      const ourTasks = timeEntryUserMap[taskUserId] ?? [];

      ourTasks.forEach((entry) => {
        if (
          !editIds.has(entry.id)
          && !daysManualEntryIds.has(entry.id)
          && !deletedIds.has(entry.id)
        ) {
          daysManualEntries.push(entry);
          daysManualEntryIds.add(entry.id);
        }
      });

      const userConflictMessage = foundTaskConflict({
        editIds,
        currentTasks: daysManualEntries,
        newTasks: ourParsedEntries.filter((entry) => editIds.has(entry.id)),
        phases,
        projectIdMap,
        userMap,
        costcodeIdMap,
      });

      if (userConflictMessage) conflictMessage = userConflictMessage;

      if (selectedEntry && taskUserId === selectedEntry.userId) hasSelectedEntryUser = true;

      parsedEntries = parsedEntries.concat(ourParsedEntries);
    });

    if (conflictMessage) {
      notification.error({
        message: conflictMessage,
        getContainer: null,
        placement: 'bottomRight',
        bottom: 50,
        duration: 10,
      });
      return;
    }

    // add previous users entries if the user was not in a new entry/edit
    const prevUsers = new Set(entries.map((entry) => entry.userId));
    const newUsers = new Set(parsedEntries.map((entry) => entry.userId));
    const missingUsers = new Set(Array.from(prevUsers).filter((prevId) => !newUsers.has(prevId)));
    const missingUsersEntries = entries.filter((entry) => missingUsers.has(entry.userId));
    parsedEntries = missingUsersEntries.concat(parsedEntries);

    // remove selected entry if the user was removed from it
    if (selectedEntry && !hasSelectedEntryUser && !isCopying) {
      parsedEntries = parsedEntries.filter((entry) => entry.id !== selectedEntry.id);
    }

    setEntries(parsedEntries);
    setSelectedEntry();
    setIsCopying(false);
    setShowFormDrawer(false);
  }, [
    entries,
    selectedEntry,
    isCopying,
    userMap,
    projectIdMap,
    costcodeIdMap,
    timeEntryUserMap,
    deletedIds,
    paidFlags,
  ]);

  const onDeleteEntry = useCallback((recordId) => {
    setEntries(entries.filter((entry) => entry.id !== recordId));
    const newDeleted = new Set(deletedIds);
    newDeleted.add(recordId);
    setDeletedIds(newDeleted);
  }, [entries, deletedIds]);

  const onClickEntry = useCallback((recordId, shouldDuplicateEntry) => {
    const ourEntry = entries.find((entry) => entry.id === recordId);
    if (!ourEntry) return;

    setSelectedEntry(ourEntry);
    setIsCopying(shouldDuplicateEntry);
    setShowFormDrawer(true);
  }, [entries]);

  const renderItem = useCallback((task) => (
    <ManualEntryCard
      task={task}
      onDelete={onDeleteEntry}
      onClick={onClickEntry}
      onFileOpen={setSelectedFile}
      onFileDetailChange={setSelectedFileDetails}
      fileMap={fileMap}
      isDisplay={isDisplay}
      onOpenLocation={setLocationDrawerConfig}
      userMap={userMap}
      projectIdMap={projectIdMap}
      settings={settings}
    />
  ), [onDeleteEntry, onClickEntry, fileMap, isDisplay, projectIdMap, settings]);

  const onDownload = useCallback(() => {
    if (!selectedFile) return;
    const params = selectedFile instanceof File
      ? { fileObject: selectedFile }
      : { fileURL: selectedFile.url };
    downloadFile(params);
  }, [selectedFile]);

  const onNext = useCallback((isRight) => () => onNextFileClick({
    isRight,
    selectedFileDetails,
    fileMap,
    setSelectedFile,
    setSelectedFileDetails,
  }), [selectedFileDetails, fileMap, setSelectedFile, setSelectedFileDetails]);
  const classMap = useMemo(() => getIdMap(classes), [classes]);
  const defaultSageShiftId = useMemo(() => {
    if (personal && profile && profile.defaultSageShiftId) return profile.defaultSageShiftId;
    if (tasks && tasks.length > 0) {
      // For someone else
      const { sageShiftId: lastTaskSageShiftId } = tasks.length > 0 ? tasks[tasks.length - 1] : {};
      return lastTaskSageShiftId;
    }
    return null;
  }, [personal, tasks, profile]);

  useEffect(() => {
    const load = async () => {
      try {
        const taskIds = initialEntries.map((task) => task.id);
        const {
          dataMap: customDataMap,
          fileMap: newFileMap,
        } = await loadManualEntryData(taskIds, true);

        const seen = new Set();
        const fullTasks = initialEntries.map((entry) => {
          const {
            [entry.id]: {
              data: initialData = [],
              divisionId,
            } = {},
          } = customDataMap;

          return {
            ...entry,
            customData: initialData,
            divisionId,
          };
        }).filter((entry) => (
          !!TaskHelpers.getEndTime(entry)
          && (
            !entry.id || (!seen.has(entry.id) && seen.add(entry.id)
            )
          )
        ));
        setEntries(fullTasks);
        setFileMap(newFileMap);
      } catch (err) {
        message.error('Failed to load timecard custom fields');
      }
      setCustomDataLoading(false);
      setHasLoadedCustomData(true);
    };
    if (visible && !hasLoadedCustomData) {
      setCustomDataLoading(true);
      setTimeout(() => {
        load();
      }, 250);
    }
  }, [
    visible,
    initialEntries,
    defaultClassId,
    classMap,
    enableHourBasedTracking,
    customFields,
    isSummary,
  ]);

  useEffect(() => {
    if (visible && !hasLoadedSummaryTask && hasLoadedCustomData && isSummary && entries.length) {
      setSelectedEntry(entries[0]);
      setShowFormDrawer(true);
      setHasLoadedSummaryTask(true);
    }
  }, [visible, hasLoadedCustomData, isSummary, entries]);

  useEffect(() => {
    const load = async () => {
      setInitialLoaded(false);
      await Promise.all([
        dispatch(getCustomFields()),
        dispatch(getUsers()),
        dispatch(getCustomers()),
        dispatch(getCustomerLabels()),
        dispatch(getMaterials()),
        dispatch(getEquipment()),
        dispatch(getCustomTables()),
      ]);
      setInitialLoaded(true);
    };
    if (visible) {
      load();
    } else {
      setFileMap({});
      setEntries([]);
      setHasLoadedCustomData(false);
      setHasLoadedSummaryTask(false);
      setDeletedIds(new Set());
    }
  }, [dispatch, visible]);

  useEffect(() => {
    const getSignedURL = async () => {
      const signedTask = entries.find((t) => t.signatureReferenceId);
      if (signedTask) {
        const signedURL = await dispatch(getSignatureURL(signedTask));
        setSignatureURL(signedURL);
      }
    };
    if (entries && entries.length) {
      getSignedURL();
    } else {
      setSignatureURL();
    }
  }, [entries]);

  const listHeight = signatureUrl ? 'calc(100% - 100px)' : '100%';

  const canEdit = canEditApproved || canEditUnapproved;

  const dataLoading = useMemo(() => (customDataLoading || !mainLoaded || !initialLoaded), [
    customDataLoading, mainLoaded, initialLoaded,
  ]);

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(dataLoading || loading);
    }
  }, [setIsLoading, dataLoading, loading]);

  const style = dataLoading
    ? {
      position: 'absolute',
      top: '115px',
      bottom: '0px',
      width: '100%',
    } : {
      padding: 0,
      paddingBottom: isDisplay && !canEdit ? 0 : 53,
    };

  const numEntryFiles = (
    selectedFileDetails?.response?.fileIds?.length ?? selectedFileDetails?.response?.files?.length
  );

  return (
    <div style={style}>
      {
        (dataLoading)
          ? <Row justify="center" align="middle" style={{ height: '100%' }}><Col><Spin /></Col></Row>
          : (
            <List
              dataSource={entries}
              renderItem={renderItem}
              rowKey={(item) => item.id}
              style={{ height: listHeight, overflow: 'auto' }}
            />
          )
      }
      <ManualEntrySignature
        signatureUrl={signatureUrl}
      />
      <ManualEntryFooter
        isDisplay={isDisplay}
        canEdit={canEdit}
        onEditClicked={onEditClicked}
        loading={loading}
        onSubmit={onSubmit}
        onClose={onClose}
        day={day}
        isApprovals={isApprovals}
        userId={userId}
        customDataLoading={dataLoading}
      />
      <ManualEntryFormDrawer
        visible={showFormDrawer}
        onClose={hideFormDrawer}
        onSubmit={onSubmitEntry}
        defaultClassId={defaultClassId}
        defaultSageShiftId={defaultSageShiftId}
        selectedEntry={selectedEntry}
        defaultDate={defaultDate}
        fileMap={fileMap}
        userId={userId}
        entries={entries}
        user={user}
        allowInlineTimes
      />
      <ManualEntryLocation
        visible={!!locationDrawerConfig}
        options={locationDrawerConfig}
        onClose={onCloseLocationDrawer}
      />
      <FullPhoto
        type={selectedFile ? getFileType(selectedFile) : null}
        url={selectedFile ? selectedFile.url : null}
        title={selectedFile ? selectedFile.name : null}
        file={selectedFile && selectedFile instanceof File ? selectedFile : null}
        onClose={onFileClose}
        onDownload={onDownload}
        onLeft={onNext(false)}
        onRight={onNext(true)}
        showLeft={selectedFile && selectedFileDetails?.index > 0}
        showRight={selectedFile && selectedFileDetails?.index < (numEntryFiles - 1)}
        useApryse
      />
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
ManualEntry.propTypes = {
  user: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  personal: PropTypes.bool,
  initialEntries: PropTypes.array,
  defaultDate: PropTypes.string,
  isDisplay: PropTypes.bool,
  onEditClicked: PropTypes.func.isRequired,
  canEditApproved: PropTypes.bool,
  canEditUnapproved: PropTypes.bool,
  showFormDrawer: PropTypes.bool,
  setShowFormDrawer: PropTypes.func.isRequired,
  day: PropTypes.object,
  isApprovals: PropTypes.bool,
  isSummary: PropTypes.bool,
  updateManualEntryData: PropTypes.func.isRequired,
  isCopying: PropTypes.bool.isRequired,
  setIsCopying: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

ManualEntry.defaultProps = {
  user: {},
  visible: false,
  personal: false,
  initialEntries: [],
  defaultDate: DateTime.local().toSQLDate(),
  isDisplay: false,
  canEditApproved: false,
  canEditUnapproved: false,
  showFormDrawer: false,
  day: {},
  isApprovals: false,
  isSummary: false,
};

export default ManualEntry;
