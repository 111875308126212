import React from 'react';
import { CheckCircleOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Row, Badge, Popover } from 'antd';

import sortByString, { arrayIncludesTerm } from '../helpers/helpers';
import FormApprovalButton, {
  APPROVE_FORM_SUBMISSION, REJECT_FORM_SUBMISSION,
} from './FormApprovalButton';
import FormMoreButton from './CompletedForms/FormMoreButton';
import SearchFormFilterHeader from './CompletedForms/SearchFormFilterHeader';
import { getFormattedCurrency, getStatus } from './formHelpers';
import { MANUAL_GLOBAL_TAB } from './ManualForms/ManualForms';
import MultiValuePopoverColumn from '../common/tables/MultiValuePopoverColumn';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import { onPOCloseModal } from '../payables/invoices/invoiceHelpers';
import { formatProjectLabelFromCompanySettings } from '../projects/projectHelpers';

const STATE_FILTERS = [
  { text: 'Assigned', value: 'Assigned' },
  { text: 'Draft', value: 'Draft' },
  { text: 'Editing', value: 'Editing' },
];

const multiValueSorter = (valueMap, key) => (a, b) => {
  const aValues = a[key] || [];
  const bValues = b[key] || [];
  const aNames = aValues.map((value) => valueMap[value]?.name).join('') || '';
  const bNames = bValues.map((value) => valueMap[value]?.name).join('') || '';
  return aNames.localeCompare(bNames);
};

const getPoValueColumn = (type = 'PO') => ({
  title: `${type} Value`,
  dataIndex: 'formValue',
  key: 'formValue',
  align: 'center',
  width: type === 'Sub-Contract' ? 180 : 140,
  render: getFormattedCurrency,
  sorter: (a, b) => a.formValue - b.formValue,
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
});

const costToDateColumn = {
  title: 'Cost to Date',
  dataIndex: 'costToDate',
  key: 'costToDate',
  align: 'center',
  width: 140,
  render: getFormattedCurrency,
  sorter: (a, b) => a.costToDate - b.costToDate,
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
};

const committedCostColumn = {
  title: 'Committed Cost',
  dataIndex: 'committedCost',
  key: 'committedCost',
  align: 'center',
  width: 140,
  render: (_, record) => {
    const committedCost = record.formValue - record.costToDate;

    return getFormattedCurrency(committedCost);
  },
};

const getPOClosedColumn = (onPOCloseClick, isPO = false) => ({
  title: 'Closed',
  dataIndex: 'isClosed',
  key: 'isClosed',
  align: 'center',
  width: 150,
  showSorterTooltip: false,
  filters: [
    {
      text: 'Closed',
      value: 'Closed',
    },
    {
      text: 'Not Closed',
      value: 'Not Closed',
    },
  ],
  onFilter: (value, record) => {
    if (value === 'Closed') return record.isClosed;

    return !record.isClosed;
  },
  render: (val, record) => {
    if (!val) {
      const {
        id: formId,
        number,
        templateName,
      } = record ?? {};

      const nameSuffix = number ? ` - ${number}` : '';
      const formName = `${templateName}${nameSuffix}`;

      const onPOClose = (
        onPOCloseModal({
          formName,
          formId,
          onPOCloseClick,
          setPopoverVisible: null,
          isPO,
        })
      );

      return <OnTraccrButton title="Close" onClick={onPOClose} />;
    }

    return <CheckCircleOutlined style={{ color: 'green' }} />;
  },
});

const getApprovalColumn = (range, templates) => (
  {
    title: null,
    dataIndex: 'approval',
    key: 'approval',
    align: 'center',
    width: 180,
    render: (_, record) => {
      const { formId, templateId } = record;
      const responses = record?.data?.fields?.reduce((acc, field = {}) => {
        const {
          fieldId,
          response,
        } = field;

        return {
          ...acc,
          [fieldId]: response,
        };
      }, {});

      const sections = templates[templateId]?.sections || [];
      return record.status !== 'Cancelled'
        ? (
          <Row justify="center" align="middle" gutter={16}>
            <FormApprovalButton formId={formId} type={REJECT_FORM_SUBMISSION} range={range} />
            <FormApprovalButton
              formId={formId}
              type={APPROVE_FORM_SUBMISSION}
              range={range}
              responses={responses}
              sections={sections}
            />
          </Row>
        )
        : null;
    },
    fixed: 'left',
  }
);

const getUsersColumn = (userFilters, userMap) => (
  {
    title: 'User(s)',
    dataIndex: 'userId',
    exportDataIndex: 'userNames',
    key: 'userId',
    align: 'center',
    width: 200,
    filters: userFilters,
    filterSearch: true,
    onFilter: (value, { users } = {}) => users.includes(value),
    filterDropdown: SearchFormFilterHeader,
    render: (_, record) => (
      <MultiValuePopoverColumn
        valueMap={userMap}
        record={record}
        type="users"
      />
    ),
    sorter: multiValueSorter(userMap, 'users'),
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
  }
);

const getTemplateNameColumn = ({
  sorter, sortDirection, filters, onFilter,
}) => {
  const useSort = sorter && sortDirection;
  const useFilter = filters && onFilter;
  const templateNameCol = {
    title: 'Form',
    dataIndex: 'templateName',
    key: 'templateName',
    align: 'center',
    width: 150,
  };

  if (useSort) {
    templateNameCol.sorter = sorter;
    templateNameCol.showSorterTooltip = false;
    templateNameCol.sortDirections = sortDirection;
  }

  if (useFilter) {
    templateNameCol.filters = filters;
    templateNameCol.onFilter = onFilter;
  }

  return templateNameCol;
};

const getNumberColumn = () => (
  {
    title: 'Number',
    dataIndex: 'number',
    key: 'number',
    align: 'center',
    width: 150,
    sorter: (a, b) => a.number - b.number,
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
  }
);

const getVendorColumn = (vendorFilters, vendorMap) => (
  {
    title: 'Vendor(s)',
    key: 'vendors',
    align: 'center',
    width: 150,
    filters: vendorFilters,
    filterSearch: true,
    onFilter: (value, { vendors = [] } = {}) => arrayIncludesTerm(vendors, value),
    filterDropdown: SearchFormFilterHeader,
    render: (_, record) => (
      <MultiValuePopoverColumn
        valueMap={vendorMap}
        record={record}
        type="vendors"
      />
    ),
    sorter: multiValueSorter(vendorMap, 'vendors'),
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
  }
);

const getCustomerColumn = (t, customerFilters) => (
  {
    title: t('Customer'),
    dataIndex: 'customer',
    align: 'center',
    width: 150,
    filters: customerFilters,
    onFilter: (value, record) => record.customerId === value,
    filterDropdown: SearchFormFilterHeader,
    render: (val) => (
      <Popover
        placement="top"
        content={<div style={{ width: 250 }}>{val}</div>}
      >
        <div>{val}</div>
      </Popover>
    ),
    sorter: (a, b) => a.customer?.localeCompare(b.customer),
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
  }
);

const getProjectColumn = (t, projectFilters, projectIdMap = {}, settings = {}) => (
  {
    title: t('Project', { count: 2 }),
    dataIndex: 'project',
    align: 'center',
    width: 150,
    filters: projectFilters,
    filterDropdown: SearchFormFilterHeader,
    onFilter: (value, record) => record.projects?.includes(value),
    render: (val, record) => {
      const { projects = [] } = record;
      const projectLabels = projects.map((project) => {
        const {
          [project]: {
            name,
            number,
          } = {},
        } = projectIdMap;

        return formatProjectLabelFromCompanySettings({ name, number, settings });
      });
      const projectString = projectLabels.join(', ');
      return (
        <Popover
          placement="top"
          content={<div style={{ width: 250 }}>{projectString}</div>}
        >
          <div>{projectString}</div>
        </Popover>
      );
    },
  }
);

const getCardColumn = (cardFilters) => (
  {
    title: 'Card',
    dataIndex: 'card',
    align: 'center',
    width: 150,
    filters: cardFilters,
    filterDropdown: SearchFormFilterHeader,
    onFilter: (value, record) => record.card === value,
    sorter: (a, b) => a.card?.localeCompare(b.card),
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
  }
);

const getCreatedOnColumn = () => (
  {
    title: 'Created On',
    dataIndex: 'createdAtDate',
    key: 'createdAtDate',
    align: 'center',
    width: 200,
    sorter: (a, b) => a.createdAt - b.createdAt,
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
  }
);

const getFavoriteColumn = (onClick) => (
  {
    title: '',
    key: 'favorite',
    dataIndex: 'isFavorite',
    render: (isFavorite, record) => (
      isFavorite
        ? <StarFilled onClick={(e) => onClick(e, record)} style={{ fontSize: 16, color: 'gold' }} />
        : <StarOutlined onClick={(e) => onClick(e, record)} style={{ fontSize: 16 }} />
    ),
    width: 30,
    align: 'center',
  }
);

const getLastUpdatedColumn = () => (
  {
    title: 'Last Updated',
    dataIndex: 'lastUpdatedDate',
    key: 'lastUpdatedDate',
    align: 'center',
    width: 200,
    sorter: (a, b) => a.lastUpdated - b.lastUpdated,
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
  }
);

const getStateColumn = () => (
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    align: 'center',
    width: 100,
    filters: STATE_FILTERS,
    onFilter: (value, record) => record.state === value,
  }
);
const getStatusColumn = (statusFilters) => (
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (val) => getStatus(val),
    align: 'center',
    width: 150,
    filters: statusFilters,
    onFilter: (value, record) => record.statusId === value,
  }
);

const getMoreColumn = (isApproval, onAddInvoiceClick, onPOClose) => {
  const renderCallback = (_, record) => {
    const recordRef = record;
    if (isApproval) recordRef.id = record.formId; // if approval form, adjust id to be form id
    const isNotCompleted = record.status !== 'completed' && record.status !== 'Cancelled' && !record.isClosed;
    const shouldShowPayableOptions = !record.isClosed && (record.type === 'PO' || record.type === 'Sub-Contract');
    return record.hasQuickBooksInvoice || isNotCompleted || shouldShowPayableOptions
      ? <FormMoreButton form={record} onAddInvoiceClick={onAddInvoiceClick} onPOClose={onPOClose} />
      : null;
  };

  return {
    title: null,
    dataIndex: 'hasQuickBooksInvoice',
    render: renderCallback,
    align: 'center',
    width: 25,
  };
};

const getApprovalStatusColumn = (formApprovals) => (
  {
    title: null,
    dataIndex: 'approval',
    key: 'approval',
    render: (_, record) => ((record.id in formApprovals && record.status !== 'Cancelled') ? <Badge status="error" /> : null),
    align: 'center',
    width: 50,
  }
);

export const getFormLogColumns = ({
  userMap,
  vendorMap,
  projectIdMap,
  templateFilters,
  formApprovals,
  statusFilters,
  userFilters,
  vendorFilters,
  customerFilters,
  projectFilters,
  cardFilters,
  query,
  isPayable,
  onAddInvoiceClick,
  onPOClose,
  settings,
  t,
  isPO,
}) => {
  const {
    customerId: queryCustomer,
    projectId: queryProject,
    cardId: queryCard,
    type: formType,
  } = query || {};
  const onFilter = (value, record) => record.templateName === value;

  let cols = [
    getUsersColumn(userFilters, userMap),
    getTemplateNameColumn({
      filters: templateFilters,
      onFilter,
      sorter: sortByString('templateName'),
      sortDirection: ['descend', 'ascend'],
    }),
    getNumberColumn(),
  ];

  if (isPayable) {
    cols.push(getPoValueColumn(formType));
    cols.push(costToDateColumn);
    cols.push(committedCostColumn);
    cols.push(getPOClosedColumn(onPOClose, isPO));
    cols.push(getVendorColumn(vendorFilters, vendorMap));
  }
  if (!queryCustomer && !queryProject) {
    cols.push(getCustomerColumn(t, customerFilters));
  }
  if (!queryProject) {
    cols.push(getProjectColumn(t, projectFilters, projectIdMap, settings));
  }

  if (!queryCard) {
    cols.push(getCardColumn(cardFilters));
  }

  cols = cols.concat([
    getCreatedOnColumn(),
    getLastUpdatedColumn(),
    getStatusColumn(statusFilters),
    getMoreColumn(false, onAddInvoiceClick, onPOClose),
    getApprovalStatusColumn(formApprovals),
  ]);
  return cols;
};

export const getApproveFormColumns = ({
  projectFilters,
  customerFilters,
  usersFilters,
  statusFilters,
  userMap,
  range,
  projectIdMap,
  settings,
  t,
  templates,
}) => [
  getApprovalColumn(range, templates),
  getTemplateNameColumn({ sorter: sortByString('templateName'), sortDirection: ['descend', 'ascend'] }),
  getUsersColumn(usersFilters, userMap),
  getNumberColumn(),
  getProjectColumn(t, projectFilters, projectIdMap, settings),
  getCustomerColumn(t, customerFilters),
  getCreatedOnColumn(),
  getLastUpdatedColumn(),
  getStatusColumn(statusFilters),
  getMoreColumn(true),
];

export const getManualFormColumns = ({
  projectFilters,
  tab,
  onFavoriteClick,
  projectIdMap,
  settings,
  t,
}) => {
  const arr = [
    getTemplateNameColumn({ sorter: sortByString('templateName'), sortDirection: ['descend', 'ascend'] }),
  ];
  if (tab !== MANUAL_GLOBAL_TAB) {
    arr.push(getProjectColumn(t, projectFilters, projectIdMap, settings));
  }
  arr.push(getCreatedOnColumn());
  arr.push(getFavoriteColumn(onFavoriteClick));
  return arr;
};

export const getAssignedFormColumns = ({
  projectFilters,
  customerFilters,
  cardFilters,
  projectIdMap,
  settings,
  t,
}) => [
  getTemplateNameColumn({ sorter: sortByString('templateName'), sortDirection: ['descend', 'ascend'] }),
  getNumberColumn(),
  getProjectColumn(t, projectFilters, projectIdMap, settings),
  getCustomerColumn(t, customerFilters),
  getCardColumn(cardFilters),
  getStateColumn(),
  getLastUpdatedColumn(),
];
