import moment from 'moment';
import { DateTime } from 'luxon';

import { getIdMap, isNullOrUndefined } from '../helpers/helpers';
import { getActiveMember } from '../helpers/users';
import { decoratePayloadWithLabels } from '../helpers/labels';
import { formatCustomFieldCreatePayload } from '../helpers/costcodeHelpers';

export const CATEGORIES = ['Material', 'Overhead', 'Labor', 'Equipment'];

export const categoriesWithHours = new Set(['Overhead', 'Labor', 'Equipment']);

export const condensePhasesAndCostcodes = (phases = [], costcodes = []) => {
  const phaseMap = {};
  const costcodeIdMap = getIdMap(costcodes);
  phases.forEach((phase) => {
    const {
      name,
      description,
      hours,
      estimatedCost,
      estimatedQuantity,
      units,
      startDate,
      endDate,
      costcodeId,
      id,
      estimatedLabourHours,
      estimatedLabourCost,
      estimatedOverheadHours,
      estimatedOverheadCost,
      estimatedMaterialCost,
      estimatedEquipmentHours,
      estimatedEquipmentCost,
    } = phase;
    if (!(id in phaseMap)) {
      phaseMap[id] = {
        id,
        name,
        description,
        estimatedLabourHours,
        estimatedLabourCost,
        estimatedOverheadHours,
        estimatedOverheadCost,
        estimatedMaterialCost,
        estimatedEquipmentHours,
        estimatedEquipmentCost,
        costcodes: [],
      };
    }
    const ourPhase = phaseMap[id];
    if (costcodeId in costcodeIdMap) {
      ourPhase.costcodes.push({
        ...costcodeIdMap[costcodeId],
        hours,
        estimatedCost,
        estimatedQuantity,
        units,
        startDate,
        endDate,
      });
    }
  });
  return Object.values(phaseMap);
};

const createPhaseLinkMap = (phases = []) => {
  const links = {};
  const idMap = {};
  phases.forEach((phase) => {
    const {
      costcodes = [],
      id,
    } = phase;
    costcodes.forEach((cc) => {
      links[`${id}-${cc.id}`] = {
        costcodeId: cc.id,
        phaseId: id,
        hours: cc.hours,
        estimatedCost: cc.estimatedCost,
        estimatedQuantity: cc.estimatedQuantity,
        units: cc.units,
        startDate: cc.startDate,
        endDate: cc.endDate,
      };
    });
    idMap[id] = phase;
  });
  return { links, idMap };
};

const momentsAreEqual = (m1, m2, granularity = 'day') => {
  const m1IsMoment = moment.isMoment(m1);
  const m2IsMoment = moment.isMoment(m2);
  if (m1IsMoment && m2IsMoment) return m1.isSame(m2, granularity);
  if (m1 === undefined || m1 === null) return m2 === undefined || m2 === null;
  return false;
};

export const momentToTimestamp = (date) => (date ? date.valueOf() : null);

export const mergeProjectsAndCostcodes = ({
  timeEntryUserMap,
  projects,
  costcodes,
  users,
  teams,
  phases = [],
  projectEquipment = {},
  equipment = [],
}) => {
  const now = DateTime.local().startOf('day');
  const projectMap = getIdMap(projects.map((project) => ({
    ...project,
    projectCostcodes: [],
    activeUsers: [],
    inactiveUsers: [],
    users: [],
    phases: [],
  })));

  const equipmentMap = getIdMap(equipment);
  const teamsMap = getIdMap(teams);
  const projectUserAdded = {};
  const costcodeIdMap = {};

  costcodes.forEach((costcode) => {
    const { projectId } = costcode;
    costcodeIdMap[costcode.id] = costcode;
    if (!projectId || !(projectId in projectMap)) return;
    const ourProject = projectMap[projectId];
    if (!('projectCostcodes' in ourProject)) ourProject.projectCostcodes = [];
    ourProject.projectCostcodes.push(costcode);
  });

  phases.forEach((phase) => {
    const {
      projectId,
      name,
      description,
      hours,
      estimatedCost,
      estimatedQuantity,
      units,
      startDate,
      endDate,
      costcodeId,
      id,
      estimatedLabourHours,
      estimatedLabourCost,
      estimatedOverheadHours,
      estimatedMaterialCost,
      estimatedOverheadCost,
      estimatedEquipmentHours,
      estimatedEquipmentCost,
    } = phase;
    if (!projectId || !(projectId in projectMap)) return;
    const ourProject = projectMap[projectId];

    if (!('phases' in ourProject)) ourProject.phases = {};
    const ourPhases = ourProject.phases;
    if (!(id in ourPhases)) {
      ourPhases[id] = {
        id,
        name,
        description,
        estimatedLabourHours,
        estimatedLabourCost,
        estimatedOverheadHours,
        estimatedMaterialCost,
        estimatedOverheadCost,
        estimatedEquipmentCost,
        estimatedEquipmentHours,
        costcodes: [],
      };
    }
    const ourPhase = ourPhases[id];
    if (!(costcodeId in costcodeIdMap)) return;
    ourPhase.costcodes.push({
      ...costcodeIdMap[costcodeId],
      hours,
      estimatedCost,
      estimatedQuantity,
      units,
      startDate,
      endDate,
    });
  });

  users.forEach((user) => {
    const { id: userId, projects: userProjects = [], teams: userTeams = [] } = user;

    let ourProjects = userProjects;
    userTeams?.forEach((team) => {
      if (team.id in teamsMap) {
        ourProjects = ourProjects.concat(teamsMap[team.id].projects);
      }
    });

    ourProjects.forEach(({ id }) => {
      if (id in projectMap) {
        if (!(id in projectUserAdded)) projectUserAdded[id] = new Set();
        if (projectUserAdded[id].has(user.id)) return;
        projectUserAdded[id].add(user.id);
        projectMap[id].users.push(user);
        if (getActiveMember({ user, projectId: id, timeEntryUserMap })) {
          projectMap[id].activeUsers.push(userId);
        } else {
          projectMap[id].inactiveUsers.push(userId);
        }
      }
    });
  });

  const projectValues = Object.values(projectMap);
  return projectValues.map((project) => {
    const {
      id: projectId,
      contractReminderTimestamp,
      contractExpirationTimestamp,
    } = project;
    const {
      [projectId]: ourProjectEquipment = {},
    } = projectEquipment;
    const fullProjEquip = [];
    const equipmentSchedules = [];

    Object.keys(ourProjectEquipment).forEach((eqId) => {
      const schedule = ourProjectEquipment[eqId];
      if (equipmentMap[eqId]) fullProjEquip.push(equipmentMap[eqId]);
      schedule.forEach(({ startTime, endTime }) => {
        equipmentSchedules.push({
          ...equipmentMap[eqId],
          id: `${eqId}.${startTime}`,
          startTime: startTime ? moment(startTime) : null,
          endTime: endTime ? moment(endTime) : null,
        });
      });
    });
    let contractExpiration = 'valid';
    if (contractExpirationTimestamp) {
      if (contractExpirationTimestamp < now) {
        contractExpiration = 'expired';
      } else if (contractReminderTimestamp && contractReminderTimestamp < now) {
        contractExpiration = 'soon';
      }
    }
    return {
      ...project,
      contractExpiration,
      phases: Object.values(project.phases),
      equipment: fullProjEquip,
      equipmentSchedules,
    };
  });
};

export default {};

export const compareCodes = (oldCode, newCode) => {
  const changed = Object.keys(oldCode).some((key) => oldCode[key] !== newCode[key]);
  return changed ? newCode : null;
};

const phaseCodeHasChanged = (costcode, oldPhaseLinkRecord) => (
  costcode.hours !== oldPhaseLinkRecord.hours
  || costcode.estimatedCost !== oldPhaseLinkRecord.estimatedCost
  || costcode.estimatedQuantity !== oldPhaseLinkRecord.estimatedCost
  || costcode.units !== oldPhaseLinkRecord.units
  || !momentsAreEqual(costcode.startDate, oldPhaseLinkRecord.startDate)
  || !momentsAreEqual(costcode.endDate, oldPhaseLinkRecord.endDate)
);

const phaseEstimateKeys = new Set([
  'estimatedLabourHours',
  'estimatedLabourCost',
  'estimatedOverheadHours',
  'estimatedOverheadCost',
  'estimatedMaterialCost',
  'estimatedEquipmentHours',
  'estimatedEquipmentCost',
]);

const isEstimateDifferent = (phase, oldPhase, key) => (phase?.[key] !== oldPhase?.[key]);

const phaseHasNewEstimates = (phase, oldPhase) => (
  Array.from(phaseEstimateKeys).some((key) => isEstimateDifferent(phase, oldPhase, key))
);

export const generateCostcodePayload = ({
  newCostcodes,
  oldCostcodes = [],
  oldPhases = [],
  newPhases = oldPhases,
}) => {
  const oldCostcodeMap = getIdMap(oldCostcodes);
  const newCostcodeMap = newCostcodes ? getIdMap(newCostcodes) : {};
  const fromGlobalCostcodes = [];
  const createdCostcodes = [];
  const removedCostcodes = newCostcodes ? oldCostcodes
    .filter((cc) => !(cc.id in newCostcodeMap))
    .map((cc) => cc.id) : [];
  const modifiedCostcodes = [];

  if (newCostcodes) {
    newCostcodes.forEach((costcode) => {
      if (!(costcode.id in oldCostcodeMap)) {
        if (costcode.isNew) {
          createdCostcodes.push({
            id: costcode.id,
            code: costcode.code,
            name: costcode.name,
            description: costcode.description,
            phased: costcode.phased,
            hours: costcode.hours,
            estimatedCost: costcode.estimatedCost,
            estimatedQuantity: costcode.estimatedQuantity,
            units: costcode.units,
            categoryId: costcode.categoryId,
            hourlyWage: costcode.hourlyWage,
            dailyWage: costcode.dailyWage,
            wageMultiplier: costcode.wageMultiplier,
            hourlyBillingRate: costcode.hourlyBillingRate,
            dailyBillingRate: costcode.dailyBillingRate,
            customData: costcode.customData,
            intuitId: costcode.intuitId,
          });
        } else {
          fromGlobalCostcodes.push({
            id: costcode.id,
            phased: costcode.phased,
            hours: costcode.hours,
            estimatedCost: costcode.estimatedCost,
            estimatedQuantity: costcode.estimatedQuantity,
            units: costcode.units,
            hourlyWage: costcode.hourlyWage,
            dailyWage: costcode.dailyWage,
            wageMultiplier: costcode.wageMultiplier,
            hourlyBillingRate: costcode.hourlyBillingRate,
            dailyBillingRate: costcode.dailyBillingRate,
          });
        }
      } else {
        const oldCode = oldCostcodeMap[costcode.id];
        const codeChanged = compareCodes(oldCode, costcode);

        if (codeChanged) {
          modifiedCostcodes.push({
            phased: codeChanged.phased,
            code: codeChanged.code,
            name: codeChanged.name,
            description: codeChanged.description,
            id: codeChanged.id,
            hours: codeChanged.hours,
            estimatedCost: codeChanged.estimatedCost,
            estimatedQuantity: costcode.estimatedQuantity,
            units: costcode.units,
            categoryId: costcode.categoryId,
            hourlyWage: codeChanged.hourlyWage,
            dailyWage: codeChanged.dailyWage,
            wageMultiplier: codeChanged.wageMultiplier,
            hourlyBillingRate: codeChanged.hourlyBillingRate,
            dailyBillingRate: codeChanged.dailyBillingRate,
          });
        }
      }
    });
  }

  const {
    links: oldPhaseLinks,
    idMap: oldPhaseMap,
  } = createPhaseLinkMap(oldPhases);

  const {
    links: newPhaseLinks,
    idMap: newPhaseMap,
  } = createPhaseLinkMap(newPhases);

  const createdPhases = [];
  const modifiedDetailPhases = [];
  const removedPhases = [];

  const createdPhaseLinks = [];
  const removePhaseLinks = [];
  const modifiedDetailPhaseLinks = [];

  if (newPhases) {
    newPhases.forEach((phase) => {
      if (phase.id in oldPhaseMap) {
        const oldPhase = oldPhaseMap[phase.id];
        if (
          oldPhase.name !== phase.name
          || oldPhase.description !== phase.description
          || phaseHasNewEstimates(phase, oldPhase)
        ) {
          const updatedPhase = { phaseId: phase.id, name: phase.name };

          Array.from(phaseEstimateKeys).forEach((key) => {
            if (isEstimateDifferent(phase, oldPhase, key)) {
              updatedPhase[key] = phase[key];
            }
          });

          modifiedDetailPhases.push(updatedPhase);
        }

        phase.costcodes.forEach((costcode) => {
          const phaseCostcodeKey = `${phase.id}-${costcode.id}`;
          if (!(phaseCostcodeKey in oldPhaseLinks)) {
            createdPhaseLinks.push({
              phaseId: phase.id,
              costcodeId: costcode.id,
              hours: costcode.hours,
              estimatedCost: costcode.estimatedCost,
              estimatedQuantity: costcode.estimatedQuantity,
              units: costcode.units,
              startDate: momentToTimestamp(costcode.startDate),
              endDate: momentToTimestamp(costcode.endDate),
            });
          } else {
            const oldPhaseLinkRecord = oldPhaseLinks[phaseCostcodeKey];

            if (phaseCodeHasChanged(costcode, oldPhaseLinkRecord)) {
              modifiedDetailPhaseLinks.push({
                phaseId: phase.id,
                costcodeId: costcode.id,
                hours: costcode.hours,
                estimatedCost: costcode.estimatedCost,
                estimatedQuantity: costcode.estimatedQuantity,
                units: costcode.units,
                startDate: momentToTimestamp(costcode.startDate),
                endDate: momentToTimestamp(costcode.endDate),
              });
            }
          }
        });
      } else {
        const newPhase = {
          name: phase.name,
          description: phase.description,
          costcodes: phase.costcodes.map((cc) => ({
            id: cc.id,
            hours: cc.hours,
            estimatedCost: cc.estimatedCost,
            estimatedQuantity: cc.estimatedQuantity,
            units: cc.units,
            startDate: momentToTimestamp(cc.startDate),
            endDate: momentToTimestamp(cc.endDate),
          })),
        };

        Array.from(phaseEstimateKeys).forEach((key) => {
          if (!isNullOrUndefined(phase[key])) {
            newPhase[key] = phase[key];
          }
        });

        createdPhases.push(newPhase);
      }
    });
  }

  oldPhases.forEach((phase) => {
    if (phase.id in newPhaseMap) {
      phase.costcodes.forEach((costcode) => {
        const phaseCostcodeKey = `${phase.id}-${costcode.id}`;
        if (!(phaseCostcodeKey in newPhaseLinks)) {
          removePhaseLinks.push({
            phaseId: phase.id,
            costcodeId: costcode.id,
          });
        }
      });
    } else {
      removedPhases.push(phase.id);
    }
  });

  return {
    fromGlobalCostcodes,
    createdCostcodes,
    removedCostcodes,
    modifiedCostcodes,
    createdPhases,
    modifiedDetailPhases,
    removedPhases,
    createdPhaseLinks,
    removePhaseLinks,
    modifiedDetailPhaseLinks,
  };
};

export const generateEquipmentPayload = ({
  newEquipment = [],
  oldEquipment = [],
  equipmentSchedule: scheduleObject = {},
}) => {
  const oldSet = new Set(oldEquipment.map((eq) => eq.id));
  const newSet = new Set();

  const createdEquipment = [];
  const fromExistingEquipment = [];
  const removedEquipment = [];
  const equipmentSchedule = [];

  newEquipment.forEach((eq) => {
    if (eq.isNew) {
      createdEquipment.push(eq);
    } else {
      if (!oldSet.has(eq.id)) {
        fromExistingEquipment.push(eq.id);
      }
      newSet.add(eq.id);
    }
  });
  oldEquipment.forEach((eq) => {
    if (!newSet.has(eq.id)) {
      removedEquipment.push(eq.id);
    }
  });

  Object.keys(scheduleObject).forEach((key) => {
    const [equipmentId] = key.split('.');
    const {
      range: [startMoment, endMoment],
      name,
    } = scheduleObject[key];
    const payload = {
      startTime: momentToTimestamp(startMoment),
      endTime: momentToTimestamp(endMoment),
    };
    if (equipmentId.length < 36) {
      payload.name = name;
    } else {
      payload.equipmentId = equipmentId;
    }
    if (payload.startTime && payload.endTime) {
      equipmentSchedule.push(payload);
    }
  });

  return {
    createdEquipment,
    fromExistingEquipment,
    removedEquipment,
    equipmentSchedule,
  };
};

export const calculateExpirationReminder = ({
  contractExpirationTimestamp,
  contractReminderTimestamp,
  isNotDisplay,
}) => {
  if (!contractExpirationTimestamp || !contractReminderTimestamp) {
    return isNotDisplay ? { type: 'months', value: 3 } : null;
  }
  const expireDT = DateTime.fromMillis(contractExpirationTimestamp);
  const reminderDT = DateTime.fromMillis(contractReminderTimestamp);
  const months = expireDT.diff(reminderDT, 'months').as('months');
  if (months > 0 && Number.isInteger(months)) {
    return isNotDisplay ? { type: 'months', value: months } : `${months} Months`;
  }
  const weeks = expireDT.diff(reminderDT, 'weeks').as('weeks');
  if (weeks > 0 && Number.isInteger(weeks)) {
    return isNotDisplay ? { type: 'weeks', value: weeks } : `${weeks} Weeks`;
  }
  const days = expireDT.diff(reminderDT, 'days').as('days');
  return isNotDisplay ? { type: 'days', value: days } : `${days} Days`;
};

export const formatContractExpiration = (formValues = {}) => {
  const ourValues = { ...formValues };
  if (!ourValues.hasContract) {
    delete ourValues.hasContract;
    ourValues.contractExpirationTimestamp = null;
    ourValues.contractReminderTimestamp = null;
    return ourValues;
  }
  if (ourValues.contractExpiration && moment.isMoment(ourValues.contractExpiration)) {
    const expireMoment = ourValues.contractExpiration;
    ourValues.contractExpirationTimestamp = expireMoment.startOf('day').valueOf();
    const {
      contractReminder: {
        type: reminderType = 'months',
        value: reminderDuration = 3,
      } = {},
    } = ourValues;
    if (reminderType && reminderDuration !== undefined) {
      const newDuration = reminderDuration || 0;
      ourValues.contractReminderTimestamp = expireMoment
        .clone().startOf('day').subtract(reminderType, newDuration).valueOf();
    }
  }
  delete ourValues.contractExpiration;
  delete ourValues.contractReminder;
  delete ourValues.hasContract;
  return ourValues;
};

export const prepareProjectPayload = ({
  formValues = {},
  storedValues = {},
  divisions = {},
  showFiles,
  customFields = [],
}) => {
  if (!formValues) return {};

  const ourValues = formatContractExpiration(formValues);
  if (ourValues.materialDiscount) {
    ourValues.materialDiscount /= 100;
  }
  if (ourValues.customer) {
    delete ourValues.customerId;
    delete ourValues.customer.id;
    ourValues.customer = decoratePayloadWithLabels(ourValues.customer);
  } else {
    delete ourValues.customer;
  }

  if (ourValues.billingContact) {
    ourValues.billingContact = ourValues.billingContact.trim();
  }

  const {
    costcodes = [],
    phases = [],
    geofence,
    fileMap = {},
    equipment = [],
    equipmentSchedule = {},
    projectTypeId,
  } = storedValues;

  const ourFileMap = showFiles ? fileMap : {};
  const {
    fromGlobalCostcodes,
    createdCostcodes,
    createdPhases,
  } = generateCostcodePayload({
    newCostcodes: costcodes,
    newPhases: phases,
  });

  const {
    createdEquipment,
    fromExistingEquipment,
    equipmentSchedule: payloadSchedule,
  } = generateEquipmentPayload({
    newEquipment: equipment,
    equipmentSchedule,
  });

  const payload = {
    ...ourValues,
    costcodes: fromGlobalCostcodes,
    phases: createdPhases,
    newCostcodes: createdCostcodes,
    geofence,
    createdEquipment,
    fromExistingEquipment,
    equipmentSchedule: payloadSchedule,
    projectTypeId,
  };

  const { divisionId } = payload;
  const {
    [divisionId]: {
      name: divisionName,
    } = {},
  } = divisions;

  if (formValues.dateRange && formValues.dateRange.length === 2) {
    delete payload.dateRange;
    const [startDate, endDate] = formValues.dateRange;
    if (moment.isMoment(startDate)) payload.startDate = startDate.format('YYYY-MM-DD');
    if (moment.isMoment(endDate)) payload.endDate = endDate.format('YYYY-MM-DD');
  }

  const formattedPayload = formatCustomFieldCreatePayload({
    payload,
    customFields,
  });

  return {
    payload: formattedPayload,
    ourFileMap,
    divisionName,
  };
};

/**
   * Check if dates are being used in any costcodes
   * @param {array} data
   * @returns {boolean}
   */
export const hasDates = (ourData) => ourData.some((data) => data.costcodes
&& data.costcodes.some((cc) => cc.startDate && cc.endDate));

/**
   * Creates a map of categoryId to costcodes
   * @param {array} costcodes
   * @returns {object} category to code map
   */
export const costCodeCategoryMap = (costcodes = []) => {
  const ccMap = {};
  costcodes.forEach((costcode) => {
    const { categoryId } = costcode;
    if (!(categoryId in ccMap)) {
      ccMap[categoryId] = [];
    }
    ccMap[categoryId].push({
      ...costcode,
      key: costcode.id,
    });
  });
  return ccMap;
};

/**
 * Sums category estimate values
 * @param {array} costcodes
 * @param {obj} categories object of cc categories
 * @param {string} category
 * @param {string} field parameter to sum
 * @param {bool} shouldInferCost infer cost from wage * hours (used in build estimate)
 * @returns {number} sum
 */
export const totalCategoryValues = (
  costcodes,
  categories,
  category,
  field,
  shouldInferCost = false,
) => {
  const categoryMap = costCodeCategoryMap(costcodes);
  const {
    [category]: {
      id: categoryId,
    } = {},
  } = categories;
  const {
    [categoryId]: categoryCostCodes = [],
  } = categoryMap;

  if (!categoryCostCodes) return 0;

  return categoryCostCodes.reduce((acc, cc) => {
    const { hours = 0, hourlyWage = 0 } = cc;
    let val = cc[field];
    if (isNullOrUndefined(val) && shouldInferCost) {
      val = hours * hourlyWage;
    }

    return acc + val;
  }, 0);
};

/**
 * Calculates budget tracking estimates
 * based on costcodes hours/costs
 * @param {object} categories costcode categories
 * @param {array} unphasedCostcodes
 * @param {array} phases
 */
export const calculateBuildEstimateValues = ({
  categories,
  unphasedCostcodes,
  phases,
}) => {
  const {
    Material: { id: materialId } = {},
    Overhead: { id: overheadId } = {},
    Equipment: { id: equipmentId } = {},
  } = categories ?? {};
  let buildLabourHours = 0;
  let buildLabourCost = 0;
  let buildMaterialCost = 0;
  let buildOverheadCost = 0;
  let buildEquipmentCost = 0;

  // unphased
  unphasedCostcodes.forEach((cc) => {
    const {
      categoryId,
      category,
      estimatedCost,
      hours = 0,
      hourlyWage = 0,
    } = cc;
    const costToUse = estimatedCost ?? (hours * hourlyWage);
    if (category === 'Material' || categoryId === materialId) {
      buildMaterialCost += estimatedCost ?? 0;
    } else if (category === 'Overhead' || categoryId === overheadId) {
      buildOverheadCost += costToUse;
    } else if (category === 'Equipment' || categoryId === equipmentId) {
      buildEquipmentCost += costToUse;
    } else {
      buildLabourCost += costToUse;
      buildLabourHours += hours;
    }
  });

  // takes phase estimate, otherwise sums costcode values
  phases.forEach((phase) => {
    buildLabourHours += phase?.estimatedLabourHours ?? totalCategoryValues(phase.costcodes, categories, 'Labor', 'hours', false);
    buildLabourCost += phase?.estimatedLabourCost ?? totalCategoryValues(phase.costcodes, categories, 'Labor', 'estimatedCost', true);
    buildMaterialCost += phase?.estimatedMaterialCost ?? totalCategoryValues(phase.costcodes, categories, 'Material', 'estimatedCost', true);
    buildOverheadCost += phase?.estimatedOverheadCost ?? totalCategoryValues(phase.costcodes, categories, 'Overhead', 'estimatedCost', true);
    buildEquipmentCost += phase?.estimatedEquipmentCost ?? totalCategoryValues(phase.costcodes, categories, 'Equipment', 'estimatedCost', true);
  });

  return {
    buildLabourHours,
    buildLabourCost,
    buildMaterialCost,
    buildOverheadCost,
    buildEquipmentCost,
  };
};

export const formatProjectLabelFromCompanySettings = ({ name, number, settings = {} }) => {
  const { projectLabelFormat = 'name' } = settings;

  switch (projectLabelFormat) {
    case 'number': {
      if (isNullOrUndefined(number) || number === '') break;
      return number;
    }
    case 'both': {
      if (isNullOrUndefined(number) || number === '') break;
      return `${number} - ${name}`;
    }
    default: {
      return name;
    }
  }
  return name;
};

export const formatProjectLabelsFromDropdownValues = ({
  values = [],
  projectIdMap = {},
  settings = {},
}) => {
  const formattedValues = values.map(({ id }) => {
    const {
      [id]: {
        name,
        number,
      } = {},
    } = projectIdMap;

    return formatProjectLabelFromCompanySettings({ name, number, settings });
  });

  return formattedValues;
};
