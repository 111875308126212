import { DateTime } from 'luxon';
import { safeExtractFromObject } from './helpers';

export const counters = {
  tablets: {
    getTablets: null,
  },
};

export const counterWasRecentlyCalled = (stringPath, debounceTime = 5) => {
  const now = DateTime.local();
  const ourCounter = safeExtractFromObject(counters, stringPath);

  if (ourCounter && now.diff(ourCounter).as('minutes') < debounceTime) return true;
  return false;
};

export default {};
