import io from 'socket.io-client';
import config from '../config';
import Permissions from '../auth/Permissions';
import {
  updateUserClockInState,
  updateUserClockOut,
  goOnBreak,
  endBreak,
  switchTask,
  handleMultipleTasks,
  getUsers,
} from '../users/state/users.actions';
import {
  getApprovals,
} from '../clock/state/clockin.actions';
import {
  getAssignedForms,
} from '../forms/state/forms.actions';
import {
  updateUserLimit,
} from '../settings/state/settings.actions';
import {
  addEvent,
  createPostFromSocket,
} from '../dashboard/LiveFeed/state/livefeed.actions';
import {
  getTeams,
} from '../teams/state/teams.actions';
import {
  DELETE_COSTCODE_BY_ID,
  CREATE_PROJECT,
  ARCHIVE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  ARCHIVE_COSTCODE,
  UPDATE_COSTCODE,
  CREATE_COSTCODE,
  CREATE_SHIFT,
  UPDATE_SHIFT,
  DELETE_SHIFT,
  FORM_SOCKET_UPDATE,
  FORM_APPROVAL_SOCKET_UPDATE,
  DELETE_FORM,
  FORM_APPROVAL_DELETE,
  NEW_TAGS_FROM_SOCKET,
  UPDATE_EQUIPMENT_AVAILABILITY,
  FORM_NUMBER_SOCKET_UPDATE,
} from '../state/actionTypes';
import store from '../state/store';

import {
  USER_CREATE_ACTION,
  TEAM_CREATE_ACTION,
} from '../dashboard/LiveFeed/liveFeed.constants';
import TimeTracking from '../state/timeTracking';

const { dispatch } = store;

class SocketService {
  constructor() {
    this.connected = false;
    this.events = {};
  }

  connect({
    companyId,
    token,
    position,
  }, rerender) {
    this.companyId = companyId;
    this.token = token;
    this.position = position;
    this.userId = window.sessionStorage.getItem('id');
    this.rerender = rerender;
    this.connectSocket = this.connectWrapper.bind(this);

    if (this.connected) return;
    this.socket = io(`${config.baseURL}/${companyId}`, {
      query: {
        token,
      },
    });
    this.socket.on('connect', this.onConnect.bind(this));
    this.socket.on('error', (err) => {
      console.error('Socket err', err);
    });
    document.addEventListener('visibilitychange', () => {
      // If tab is no longer hidden then reconnect socket
      if (!document.hidden && this.socket.disconnected) {
        this.connectSocket();
      }
    });
  }

  connectWrapper() {
    if (this.socket.connected) return;
    this.socket.connect();
  }

  onConnect() {
    this.connected = true;

    this.socket.on('clockin', (data) => {
      dispatch(updateUserClockInState(data));
    });

    this.socket.on('multipleTasks', (data) => {
      dispatch(handleMultipleTasks(data));
    });

    this.socket.on('createdCostcode', (data) => {
      dispatch({
        type: CREATE_COSTCODE,
        payload: {
          costcodes: data,
        },
      });
    });

    this.socket.on('updatedCostcodes', (data) => {
      dispatch({
        type: UPDATE_COSTCODE,
        payload: {
          costcode: { id: data.id, ...data.payload },
        },
      });
    });

    this.socket.on('deletedCostcode', (data) => {
      dispatch({
        type: DELETE_COSTCODE_BY_ID,
        payload: {
          deletedId: data,
        },
      });
    });

    this.socket.on('archivedCostcode', (data) => {
      dispatch({
        type: ARCHIVE_COSTCODE,
        payload: {
          costcodeId: data.id,
          active: data.active,
        },
      });
    });

    this.socket.on('updateAvailability', (data) => {
      dispatch({
        type: UPDATE_EQUIPMENT_AVAILABILITY,
        payload: {
          equipmentId: data.id,
          availability: data.availability,
        },
      });
    });

    this.socket.on('updatedProject', (data) => {
      dispatch({
        type: UPDATE_PROJECT,
        payload: {
          projectId: data.projectId,
          data: {
            details: data,
          },
        },
      });
    });

    this.socket.on('createdProject', (data) => {
      dispatch({
        type: CREATE_PROJECT,
        payload: {
          project: data,
        },
      });
    });

    this.socket.on('deletedProject', (data) => {
      dispatch({
        type: DELETE_PROJECT,
        payload: {
          id: data.id,
        },
      });
    });

    this.socket.on('archivedProject', (data) => {
      dispatch({
        type: ARCHIVE_PROJECT,
        payload: {
          projectId: data.id,
          active: data.active,
        },
      });
    });

    this.socket.on(`updateSubmissions/${this.userId}`, () => {
      dispatch(getApprovals());
    });

    this.socket.on(`updateTimeCards/${this.userId}`, (payload) => {
      dispatch(TimeTracking.updateTimeEntry(payload));
    });

    this.socket.on('clockout', (data) => {
      const {
        userId,
        startTime,
        endTime,
        id: taskId,
        isAutoRounded,
      } = data;

      dispatch(updateUserClockOut({
        userId, taskId, startTime, previousEnd: endTime, isAutoRounded,
      }));
    });

    this.socket.on('breakStart', (payload) => dispatch(goOnBreak(payload)));
    this.socket.on('breakEnd', (payload) => dispatch(endBreak(payload)));
    this.socket.on('switchTask', (payload) => dispatch(switchTask(payload)));

    this.socket.on('userLimitUpdate', (payload) => dispatch(updateUserLimit(payload)));
    this.socket.on('newEvent', (payload) => {
      let hasUser = false;
      let hasTeam = false;
      payload.forEach((event) => {
        if (event.action === USER_CREATE_ACTION) hasUser = true;
        if (event.action === TEAM_CREATE_ACTION) hasTeam = true;
      });
      if (hasUser) {
        dispatch(getUsers());
      }
      if (hasTeam) {
        dispatch(getTeams());
      }
      dispatch(addEvent(payload));
    });

    this.socket.on(`newPost/${this.userId}`, (payload) => dispatch(createPostFromSocket(payload)));
    // Get public posts
    this.socket.on('newPost', (payload) => dispatch(createPostFromSocket(payload)));

    this.socket.on('createShift', this.dispatchShiftEvent(CREATE_SHIFT));
    this.socket.on('updateShift', this.dispatchShiftEvent(UPDATE_SHIFT));
    this.socket.on('deleteShift', this.dispatchShiftEvent(DELETE_SHIFT, 'shiftIds'));
    this.socket.on('formUpdate', (payload) => {
      dispatch({
        type: FORM_SOCKET_UPDATE,
        payload,
      });
    });

    this.socket.on(`formApprovalUpdate/${this.userId}`, (approval) => {
      dispatch({
        type: FORM_APPROVAL_SOCKET_UPDATE,
        payload: {
          approval,
        },
      });
    });

    this.socket.on('formNumberUpdates', (payload) => {
      dispatch({
        type: FORM_NUMBER_SOCKET_UPDATE,
        payload,
      });
    });

    this.socket.on(`formEditRequest/${this.userId}`, () => {
      dispatch(getAssignedForms());
    });
    // TODO: Add position support
    // Backend sends position ID, but this.position is the name of the position
    this.socket.on(`formApprovalUpdate/${this.position}`, (approval) => {
      dispatch({
        type: FORM_APPROVAL_SOCKET_UPDATE,
        payload: {
          approval,
        },
      });
    });

    this.socket.on('formDelete', (payload) => {
      dispatch({
        type: DELETE_FORM,
        payload,
      });
    });

    this.socket.on('formApprovalDelete', (payload) => {
      dispatch({
        type: FORM_APPROVAL_DELETE,
        payload,
      });
    });

    this.socket.on(`newBoardTags/${this.userId}`, (newTags = []) => {
      dispatch({
        type: NEW_TAGS_FROM_SOCKET,
        payload: {
          newTags,
        },
      });
    });

    this.socket.on('dispatchReduxEvent', dispatch);

    this.socket.on('permissions/rename', ({
      oldPerm,
      newPerm,
    }) => Permissions.rename(oldPerm, newPerm));

    this.socket.on(`permissions/${this.position}`, ({
      action,
      permission,
      permissions,
      newPosition,
      userId,
    }) => {
      switch (action) {
        case 'add':
          Permissions.add(permission);
          if (userId !== Permissions.id) this.rerender();
          break;
        case 'delete':
          Permissions.delete(permission);
          if (userId !== Permissions.id) this.rerender();
          break;
        case 'reset':
          if (Permissions.reload(permissions)) {
            if (userId !== Permissions.id) this.rerender();
          }
          break;
        case 'reassign':
          if (Permissions.reload(permissions)) {
            this.rerender();
            // eslint-disable-next-line no-undef
            window.sessionStorage.setItem('position', newPosition);
          }
          break;
        default:
          console.log(`Invalid permssion action received: ${action}`);
      }
    });

    Object.keys(this.events).forEach((event) => this.socket.on(event, this.events[event]));
  }

  register(event, callback) {
    this.events[event] = callback;
  }

  dispatchShiftEvent(type, key = 'shifts') {
    return ({ [key]: data = [], users = [], fromUpdate }) => {
      const hasScheduleWrite = Permissions.has('SCHEDULE_WRITE');
      if (!hasScheduleWrite && !users.includes(this.userId)) return;
      if (hasScheduleWrite && fromUpdate) return;
      dispatch({
        type,
        payload: {
          [key]: data,
        },
      });
    };
  }
}

export default new SocketService();
