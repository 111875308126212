import React, { useCallback, useMemo } from 'react';
import {
  Col,
  Row,
  Select,
  Table,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import OnTraccrButton from '../../../../common/buttons/OnTraccrButton';
import OnTraccrNumberInput from '../../../../common/inputs/OnTraccrNumberInput';
import DisplayText from '../../../../common/text/DisplayText';
import BorderlessButton from '../../../../common/buttons/BorderlessButton';
import CostCodeTableSelector from './CostCodeTableSelector';

import useCheckTableMaxRows from '../../../../common/hooks/useCheckTableMaxRows';
import { generateId } from '../../../formHelpers';

import {
  currencyFormatter as formatter,
  currencyParser as parser,
} from '../../../../helpers/inputParsers';
import { getIdMap } from '../../../../helpers/helpers';

const getCostCodeColumns = ({
  t,
  requiredColumns,
  preventEdits,
  isDisplay,
  onValueUpdated,
  onProjectUpdated,
  onCostcodeUpdated,
  projectOptions,
}) => ({
  project: {
    title: <div className={requiredColumns && 'form-required-field'}>{t('Project')}</div>,
    dataIndex: 'project',
    width: 150,
    render: (project, record) => {
      if (isDisplay || preventEdits) return (project ?? '');

      return (
        <Select
          style={{ width: '100%' }}
          placeholder={`Select a ${t('Project')}`}
          showSearch
          allowClear
          optionFilterProp="label"
          options={projectOptions}
          mode="single"
          onChange={(newId) => onProjectUpdated(record.id, newId)}
          value={record?.projectId}
        />
      );
    },
  },
  costcode: {
    title: <div className={requiredColumns && 'form-required-field'}>Cost Code</div>,
    dataIndex: 'costcode',
    width: 150,
    render: (costcode, record) => {
      const {
        projectId,
        costcodeId,
        id,
        phaseCostcodeKey,
      } = record || {};
      if (isDisplay || preventEdits) {
        if (!costcodeId) return '';
        return costcode;
      }

      return (
        <CostCodeTableSelector
          projectId={projectId}
          onCostcodeUpdated={onCostcodeUpdated}
          value={phaseCostcodeKey}
          entryId={id}
        />
      );
    },
  },
  amount: {
    title: <div className={requiredColumns && 'form-required-field'}>Amount</div>,
    dataIndex: 'amount',
    width: 150,
    render: (amount, record) => {
      if (isDisplay || preventEdits) {
        if (!amount) return '$0';
        return `$${parseFloat(amount).toFixed(2)}`;
      }

      return (
        <OnTraccrNumberInput
          key={record.id}
          formatter={formatter}
          parser={parser}
          step={0.01}
          precision={2}
          onChange={(newAmount) => onValueUpdated(record.id, { amount: newAmount })}
          defaultValue={amount}
        />
      );
    },
  },
});

const getColumns = ({
  t,
  onDelete,
  onValueUpdated,
  onProjectUpdated,
  onCostcodeUpdated,
  columns,
  isDisplay,
  requiredColumns,
  preventEdits,
  projectOptions,
}) => {
  const cols = [];
  const colMap = getCostCodeColumns({
    t,
    isDisplay,
    onValueUpdated,
    onProjectUpdated,
    onCostcodeUpdated,
    requiredColumns,
    preventEdits,
    projectOptions,
  });

  columns.forEach((col) => {
    if (col.key in colMap) {
      cols.push(colMap[col.key]);
    } else if (col.isCalculation) {
      cols.push({
        title: <div>{col.name}</div>,
        dataIndex: col.name,
      });
    }
  });

  if (!isDisplay) {
    cols.push({
      title: '',
      dataIndex: '',
      width: 100,
      render: (_, record) => (
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ color: 'red' }} />}
          onClick={() => onDelete(record.id)}
        />
      ),
    });
  }
  return cols;
};

export default function CostCodeTablePreview({
  previewProps = {},
  responses = {},
  id,
  showCondensedView,
  isDisplay,
  responding,
  setResponses,
  setPreviewProps,
  columns = [],
  configProps = {},
}) {
  const { t } = useTranslation();

  const {
    requiredColumns,
    preventEdits,
  } = configProps ?? {};
  const values = previewProps.values || []; // For responses
  const {
    selected: previewSelected = [],
  } = previewProps;
  const {
    [id]: {
      values: responseSelected = [],
    } = {},
  } = responses;

  const selected = responding ? responseSelected : previewSelected;

  const projects = useSelector((state) => state.projects.projects);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const phasedCostcodes = useSelector((state) => state.costcodes.phases);

  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const costcodeMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const phaseMap = useMemo(() => getIdMap(phasedCostcodes), [phasedCostcodes]);

  const projectOptions = useMemo(() => (
    projects.filter((project) => project.active)
      .map((project) => ({ label: `${project.number} - ${project.name}`, value: project.id }))
  ), [projects]);

  const updateSelected = useCallback((newSelected) => {
    if (responding) {
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id]),
          values: newSelected,
          columns,
        },
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }
  }, [
    responding,
    responses,
    id,
    columns,
    previewProps,
    setResponses,
    setPreviewProps,
  ]);

  const onAddRowClicked = useCallback(() => {
    const newEntry = {
      id: generateId(),
    };

    const newSelected = selected.concat([newEntry]);
    updateSelected(newSelected);
  }, [selected, updateSelected]);

  const onDelete = useCallback((itemId) => {
    const newSelected = selected.filter((item) => item.id !== itemId);
    updateSelected(newSelected);
  }, [updateSelected, selected]);

  const onValueUpdated = useCallback((rowId, newData = {}) => {
    const newSelected = selected.map((row) => {
      if (row.id !== rowId) return row;
      return {
        ...row,
        ...newData,
      };
    });
    updateSelected(newSelected);
  }, [updateSelected, selected]);

  const onProjectUpdated = useCallback((entryId, newProjectId) => {
    const ourProject = projectMap?.[newProjectId] ?? {};
    onValueUpdated(entryId, {
      projectId: ourProject.id,
      project: ourProject?.name,
      // clear selected costcode
      phaseId: null,
      phaseName: null,
      costcodeId: null,
      costcode: '',
      phaseCostcodeKey: '',
    });
  }, [projectMap, onValueUpdated]);

  const onCostcodeUpdated = useCallback((entryId, selectedVal = '') => {
    if (typeof selectedVal !== 'string') return;
    const [phaseId, costcodeId] = selectedVal.split('.');
    const { name: phaseName = 'Unphased' } = phaseMap[phaseId] || {};
    const { name: costcodeName, code } = costcodeMap[costcodeId] || {};
    onValueUpdated(entryId, {
      phaseId,
      phaseName,
      costcodeId,
      costcode: `${phaseName || 'Unphased'} - ${code} ${costcodeName}`,
      phaseCostcodeKey: selectedVal,
    });
  }, [phaseMap, costcodeMap, onValueUpdated]);

  const tableColumns = useMemo(() => (
    getColumns({
      t,
      onDelete,
      onValueUpdated,
      onProjectUpdated,
      onCostcodeUpdated,
      columns,
      isDisplay,
      requiredColumns,
      preventEdits,
      projectOptions,
    })
  ), [
    onDelete,
    onValueUpdated,
    onProjectUpdated,
    onCostcodeUpdated,
    columns,
    isDisplay,
    requiredColumns,
    preventEdits,
    projectOptions,
  ]);

  const dataSource = useMemo(() => (
    isDisplay && !responding ? values : selected
  ), [isDisplay, responding, values, selected]);

  const {
    shouldAddButtonBeEnabled = true,
  } = useCheckTableMaxRows({
    configProps,
    currentRowsLength: dataSource.length,
  });

  return (
    <Row style={{ marginTop: showCondensedView ? 0 : 15 }}>
      {!isDisplay && (
        <Row align="middle" justify="space-between" style={{ width: '100%', marginBottom: 10 }}>
          <Col>
            <Row gutter={20}>
              <Col>
                <OnTraccrButton
                  title="Add Row"
                  icon={<PlusOutlined />}
                  onClick={onAddRowClicked}
                  disabled={!shouldAddButtonBeEnabled}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {!showCondensedView || dataSource?.length ? (
        <Table
          style={{ width: '100%', overflow: 'auto' }}
          columns={tableColumns}
          size="small"
          pagination={false}
          dataSource={dataSource}
        />
      ) : (
        <DisplayText title="No Costcodes Selected" style={{ marginBottom: 0 }} />
      )}
    </Row>
  );
}

/* eslint-disable react/forbid-prop-types */
CostCodeTablePreview.propTypes = {
  columns: PropTypes.array,
  previewProps: PropTypes.object,
  setPreviewProps: PropTypes.func.isRequired,
  isDisplay: PropTypes.bool,
  id: PropTypes.string.isRequired,
  setResponses: PropTypes.func.isRequired,
  responses: PropTypes.object,
  responding: PropTypes.bool,
  configProps: PropTypes.object,
  showCondensedView: PropTypes.bool,
};

CostCodeTablePreview.defaultProps = {
  columns: [],
  previewProps: {},
  responses: {},
  isDisplay: false,
  responding: false,
  configProps: {},
  showCondensedView: false,
};
