import { DateTime } from 'luxon';
import { TaskHelpers } from 'ontraccr-common';

import calculateOT from '../../helpers/overtime/calculateOT';
import { msToHours } from '../../helpers/time';
import { toTitleCase } from '../../helpers/helpers';

import {
  FIELDS_WITH_FILTERS,
  FIELDS_WITH_CONFIG,
} from '../../settings/Exports/exports.constants';

export default {};

export const getDataKey = (col = {}) => (
  FIELDS_WITH_FILTERS.has(col.field) || FIELDS_WITH_CONFIG.has(col.field)
    ? `${col.field}-${col.filterValue}`
    : col.field
);

export const fixedDecimalKeys = [
  'cost',
  'totalHours',
  'workHours',
  'breakHours',
  'regularHours',
  'otHours',
  'doubleOT',
  'saturdayTime',
  'saturdayOT',
  'saturdayDoubleOT',
  'sundayTime',
  'sundayOT',
  'sundayDoubleOT',
  'burdenCost',
  'calculatedWage',
  'user.wage',
  'costcodes.hourlywage',
  'class.hourlywage',
];

const mergeSetKeys = [
  'displayId',
  'projects.name',
  'projects.number',
  'projects.address',
  'projects.qboClass',
  'phases.name',
  'phases.description',
  'costcodes.name',
  'costcodes.description',
  'costcodes.code',
  'union.name',
  'local.name',
  'class.name',
  'timezone',
  'wageType',
];

const summarizedAverageKeys = [
  'calculatedWage',
  'user.wage',
  'costcodes.hourlywage',
  'class.hourlywage',
];

const tableNumberKeys = new Set([
  'quantity',
  'price',
  'total',
  'cost',
  'labourCost',
  'labourCostTotal',
  'labourAndMaterialTotal',
  'rate',
  'hours',
]);

const isFilterKey = (key) => {
  if (key?.startsWith('field')) return false;
  const [prefix] = key.split('-');
  return FIELDS_WITH_FILTERS.has(prefix);
};

const convertTableValue = (key, value) => {
  if (!tableNumberKeys.has(key)) return value;
  const parsed = parseFloat(value);
  return Number.isNaN(parsed) ? value : parsed;
};

const calculateOTPay = ({ row = {}, settings = {}, wage }) => {
  const fullRow = { ...row };

  const {
    saturdayPay = 1,
    sundayPay = 1,
    enableManualOT,
    manualOTModifier = 1.5,
  } = settings;
  const otModifier = enableManualOT ? manualOTModifier : 1.5;

  const regularHours = fullRow.regularHours ?? 0;
  const otHours = fullRow.otHours ?? 0;
  const doubleOT = fullRow.doubleOT ?? 0;
  const saturdayHours = fullRow.saturdayHours ?? 0;
  const saturdayOTHours = fullRow.saturdayOTHours ?? 0;
  const saturdayDoubleOTHours = fullRow.saturdayDoubleOTHours ?? 0;
  const sundayHours = fullRow.sundayHours ?? 0;
  const sundayOTHours = fullRow.sundayOTHours ?? 0;
  const sundayDoubleOTHours = fullRow.sundayDoubleOTHours ?? 0;

  const cost = wage ? (
    (regularHours * wage)
    + (otHours * wage * otModifier)
    + (doubleOT * wage * 2)
    + (
      saturdayHours
      + (saturdayOTHours * 1.5)
      + (saturdayDoubleOTHours * 2)
    ) * saturdayPay * wage
    + (
      sundayHours
      + (sundayOTHours * 1.5)
      + (sundayDoubleOTHours * 2)
    ) * sundayPay * wage
  ) : 0;

  return cost;
};

export const parseSummarizeRow = ({
  row = {},
  settings = {},
  wage,
}) => {
  const fullRow = { ...row };

  fullRow.cost = calculateOTPay({ row, settings, wage });

  fixedDecimalKeys.forEach((key) => {
    if (typeof fullRow[key] === 'number') fullRow[key] = fullRow[key].toFixed(2);
  });

  Object.keys(fullRow).forEach((key) => {
    /*
      costcodes.hours looks like costcodes.hours-${cost code}
    */
    if (isFilterKey(key)) {
      fullRow[key] = fullRow[key].toFixed(2);
    }
  });

  mergeSetKeys.forEach((key) => {
    if (fullRow[key]?.size > 0) {
      fullRow[key] = Array.from(fullRow[key]).join(', ');
    } else {
      fullRow[key] = '';
    }
  });
  return fullRow;
};

export const convertSummarizedResultsToRows = (summarizedResults = {}, settings = {}) => {
  const parsedSummarizedRows = [];

  Object.keys(summarizedResults).forEach((userId) => {
    const user = summarizedResults[userId];
    const { sortOrder = [] } = user;

    sortOrder?.forEach((summarizeKey) => {
      const summarizedRow = user[summarizeKey]?.reduce((acc, row) => {
        if (!Object.keys(acc).length) {
          // eslint-disable-next-line no-param-reassign
          acc = { ...row };
          acc.totalWeightedHours = row.type === 'break' ? 0 : calculateOTPay({ row, settings, wage: 1 });
          summarizedAverageKeys.forEach((key) => {
            acc[key] = row[key] * acc.totalWeightedHours;
          });
          return acc;
        }

        const {
          metadata: {
            projectName,
            projectNumber,
            projectAddress,
            projectQBOClass,
            phaseName,
            phaseDescription,
            costcodeName,
            costcodeDescription,
            costcodeCode,
            unionName,
            localName,
            className,
            timezone,
            displayId,
          } = {},
          totalHours,
          totalHoursWithBreak,
          regularHours,
          otHours,
          doubleOT,
          saturdayHours,
          saturdayOTHours,
          saturdayDoubleOTHours,
          sundayHours,
          sundayOTHours,
          sundayDoubleOTHours,
          note,
          endTime,
          startTime,
          hourBased,
          burdenCost,
          wageType,
        } = row ?? {};

        Object.keys(row).forEach((key) => {
          if (isFilterKey(key)) {
            acc[key] = (acc[key] ?? 0) + row[key];
          }
        });

        if (!hourBased) {
          // End Time is latest endTime of tasks for the day.
          if (!acc.startTime) acc.startTime = startTime;
          acc.endTime = endTime;
        }

        acc.totalHours += totalHoursWithBreak;
        if (timezone) acc.timezone.add(timezone);

        if (row.type === 'Break') {
          acc.breakHours += totalHoursWithBreak;
        } else {
          const weightedHours = calculateOTPay({ row, settings, wage: 1 });
          acc.workHours += totalHours;
          acc.regularHours += regularHours;
          acc.otHours += otHours;
          acc.doubleOT += doubleOT;
          acc.saturdayHours += saturdayHours;
          acc.saturdayOTHours += saturdayOTHours;
          acc.saturdayDoubleOTHours += saturdayDoubleOTHours;
          acc.sundayHours += sundayHours;
          acc.sundayOTHours += sundayOTHours;
          acc.sundayDoubleOTHours += sundayDoubleOTHours;
          acc.burdenCost += burdenCost;

          summarizedAverageKeys.forEach((key) => {
            acc[key] = (acc[key] ?? 0) + (row[key] * weightedHours);
          });

          acc.totalWeightedHours += weightedHours;
        }

        if (row.note) {
          if (acc.note) {
            acc.note += `\n ${note}`;
          } else {
            // handle null note;
            acc.note = note;
          }
        }

        if (displayId) acc.displayId = acc.displayId.add(displayId);
        if (projectName) acc['projects.name'].add(projectName);
        if (projectNumber) acc['projects.number'].add(projectNumber);
        if (projectAddress) acc['projects.address'].add(projectAddress);
        if (projectQBOClass) acc['projects.qboClass'].add(projectQBOClass);

        if (phaseName) acc['phases.name'].add(phaseName);
        if (phaseDescription) acc['phases.description'].add(phaseDescription);

        if (costcodeName) acc['costcodes.name'].add(costcodeName);
        if (costcodeDescription) acc['costcodes.description'].add(costcodeDescription);
        if (costcodeCode) acc['costcodes.code'].add(costcodeCode);

        if (unionName) acc['union.name'].add(unionName);
        if (localName) acc['local.name'].add(localName);
        if (className) acc['class.name'].add(className);

        if (wageType) wageType.forEach((type) => acc.wageType.add(type));

        return acc;
      }, {});

      if (summarizedRow.totalWeightedHours) {
        const { totalWeightedHours } = summarizedRow;
        summarizedAverageKeys.forEach((key) => {
          if (summarizedRow[key] !== undefined) {
            summarizedRow[key] /= totalWeightedHours;
          }
        });
      }

      parsedSummarizedRows.push(
        parseSummarizeRow({
          row: summarizedRow,
          settings,
          wage: summarizedRow?.calculatedWage,
        }),
      );
    });
  });

  return parsedSummarizedRows;
};

export const getTableFieldId = (columns) => (
  columns.reduce((acc, { field } = {}) => {
    if (!field) return acc;
    const fieldSplit = field.split('-');
    if (fieldSplit.length < 3) return acc;
    return fieldSplit.slice(0, 2).join('-');
  }, null)
);

export const computeTimeEntriesExportData = ({
  timeEntryUserMap = {},
  users = [],
  startDate = DateTime.local().minus({ days: 7 }),
  endDate = DateTime.local(),
  settings = {},
  projectIdMap = {},
  phaseIdMap = {},
  costcodeIdMap = {},
  unionIdMap = {},
  localIdMap = {},
  classIdMap = {},
  qboClassIdMap = {},
  taskCustomDataMap = {},
  userCustomDataMap = {},
  selectedExportId,
  approvedOnly,
  summarizeDays,
  summarizeProject,
  divisionId,
  divisionName,
  divisionCode = null,
  divisionUserSet = new Set(),
  tableFieldId,
  hideExported,
  payTypeMap = {},
}) => {
  const result = users.reduce((acc, user) => {
    const {
      wage,
      classId: defaultClassId,
      id: userId,
    } = user;
    const tasks = timeEntryUserMap[userId] ?? [];
    const filteredTasks = tasks.filter((task) => {
      if (!task.startTime || !task.endTime) return false;
      if (approvedOnly && task.state !== 'approved') return false;
      return task.endTime >= startDate.toMillis() && task.endTime <= endDate.toMillis();
    });
    if (filteredTasks.length === 0) return acc;
    const otTasks = calculateOT({
      tasks: filteredTasks,
      settings,
      range: [
        startDate.minus({ days: 15 }),
        endDate.plus({ days: 15 }),
      ],
    });

    const {
      [userId]: {
        data: userCustomData = {},
      } = {},
    } = userCustomDataMap;

    otTasks.forEach((task) => {
      const {
        startTime,
        endTime,
        regularTime,
        overtime,
        doubleOT,
        saturdayTime = 0,
        saturdayOT = 0,
        saturdayDoubleOT = 0,
        sundayTime = 0,
        sundayOT = 0,
        sundayDoubleOT = 0,
        projectId,
        phaseId,
        costcodeId,
        classId,
        timezone,
      } = task;
      const realClassId = classId ?? defaultClassId;
      const startDT = DateTime.fromMillis(startTime, { zone: timezone });
      const endDT = DateTime.fromMillis(endTime, { zone: timezone });

      const hrReg = msToHours(regularTime);
      const hourOT = msToHours(overtime);
      const hourDouble = msToHours(doubleOT);

      const satTime = msToHours(saturdayTime);
      const satOT = msToHours(saturdayOT);
      const satDouble = msToHours(saturdayDoubleOT);

      const sunTime = msToHours(sundayTime);
      const sunOT = msToHours(sundayOT);
      const sunDouble = msToHours(sundayDoubleOT);

      const {
        [projectId]: {
          name: projectName,
          number: projectNumber,
          divisionId: projectDivisionId,
          qboClassId: projectQBOClassId,
          estimatedBurdenRate: projectBurdenRate = 0,
          address: projectAddress,
        } = {},
      } = projectIdMap;

      const {
        [projectQBOClassId]: { Name: projectQBOClass } = {},
      } = qboClassIdMap;

      const {
        [phaseId]: {
          name: phaseName,
          description: phaseDescription,
        } = {},
      } = phaseIdMap;

      const {
        [costcodeId]: {
          name: costcodeName,
          description: costcodeDescription,
          code: costcodeCode,
          divisionId: costcodeDivisionId,
          category: costcodeCategory,
          hourlyWage: costcodeHourlyWage,
        } = {},
      } = costcodeIdMap;

      const costcodeHoursKey = `costcodes.hours-${costcodeCode}`;

      const {
        [realClassId]: {
          localId,
          name: className,
          wage: classHourlyWage,
        } = {},
      } = classIdMap;
      const {
        [localId]: {
          unionId,
          name: localName,
        } = {},
      } = localIdMap;
      const {
        [unionId]: { name: unionName } = {},
      } = unionIdMap;

      const {
        [task.id]: {
          data: taskCustomData = {},
          divisionId: customDataDivision,
          exportIds = [],
          batchNumber,
        } = {},
      } = taskCustomDataMap;

      const hasBeenExported = exportIds?.includes(selectedExportId);

      if (hideExported && hasBeenExported) {
        return;
      }

      const {
        [user.eclipseId]: payType,
      } = payTypeMap;

      const hasNoDiv = !customDataDivision && !projectDivisionId && !costcodeDivisionId;

      if (
        (customDataDivision && customDataDivision !== divisionId)
        || (projectDivisionId && projectDivisionId !== divisionId)
        || (costcodeDivisionId && costcodeDivisionId !== divisionId)
        || (hasNoDiv && !divisionUserSet.has(user.id))
      ) {
        return; // Eject early if we have data for a diff div.
      }
      const dayKey = endDT.toLocaleString(DateTime.DATE_SHORT);
      const fullRowKey = summarizeProject
        ? `${projectId ?? 'None'}-${dayKey}`
        : dayKey;

      const runtimes = TaskHelpers.getRuntimes(task, false);
      const totalHours = msToHours(runtimes.regularTime + runtimes.overtime + runtimes.doubleOT);
      const totalHoursWithBreak = msToHours(
        runtimes.regularTime + runtimes.overtime + runtimes.doubleOT + runtimes.breakTime,
      );
      const workHours = task.type === 'break' ? 0 : totalHours;
      let wageType = '';
      let calculatedWage = 0;

      if (classHourlyWage) {
        calculatedWage = classHourlyWage;
        wageType = 'Union';
      } else if (costcodeHourlyWage) {
        calculatedWage = costcodeHourlyWage;
        wageType = 'Cost Code';
      } else if (wage) {
        calculatedWage = wage;
        wageType = 'User';
      }

      const calculatedCost = calculatedWage ? (calculatedWage * workHours) : 0;

      const formattedTimezone = endDT.toFormat('ZZZZ');
      const displayId = task.id ? task.id.slice(0, 10) : '';
      const row = {
        ...task,
        displayId: new Set(displayId ? [displayId] : []),
        timezone: new Set([formattedTimezone]),
        'date-mm/dd/yyyy': endDT.toFormat('LL/dd/yyyy'),
        'date-mm/dd/yy': endDT.toFormat('LL/dd/yy'),
        'date-dd/mm/yyyy': endDT.toFormat('dd/LL/yyyy'),
        'date-dd/mm/yy': endDT.toFormat('dd/LL/yy'),
        'users.name': user.name,
        'users.employeeId': user.employeeId,
        'users.email': user.email,
        'users.wage': wage,
        cost: calculatedCost,
        calculatedWage,
        wageType: new Set(wageType ? [wageType] : []),
        'divisions.name': divisionName,
        'divisions.code': divisionCode,
        burdenCost: costcodeCategory === 'Labor' ? (calculatedCost * projectBurdenRate) : 0,
        totalHours,
        totalHoursWithBreak,
        workHours,
        breakHours: task.type === 'break' ? totalHoursWithBreak : 0,
        regularHours: task.type === 'break' ? 0 : hrReg,
        otHours: task.type === 'break' ? 0 : hourOT,
        doubleOT: task.type === 'break' ? 0 : hourDouble,
        saturdayHours: task.type === 'break' ? 0 : satTime,
        saturdayOTHours: task.type === 'break' ? 0 : satOT,
        saturdayDoubleOTHours: task.type === 'break' ? 0 : satDouble,
        sundayHours: task.type === 'break' ? 0 : sunTime,
        sundayOTHours: task.type === 'break' ? 0 : sunOT,
        sundayDoubleOTHours: task.type === 'break' ? 0 : sunDouble,
        type: task.type ? toTitleCase(task.type) : 'Work',
        state: task.state ? toTitleCase(task.state) : 'Not Submitted',
        'projects.name': new Set(projectName ? [projectName] : []),
        'projects.number': new Set(projectNumber ? [projectNumber] : []),
        'projects.address': new Set(projectAddress ? [projectAddress] : []),
        'projects.qboClass': new Set(projectQBOClass ? [projectQBOClass] : []),
        'phases.name': new Set(phaseName ? [phaseName] : []),
        'phases.description': new Set(phaseDescription ? [phaseDescription] : []),
        'costcodes.name': new Set(costcodeName ? [costcodeName] : []),
        'costcodes.description': new Set(costcodeDescription ? [costcodeDescription] : []),
        'costcodes.code': new Set(costcodeCode ? [costcodeCode] : []),
        'costcodes.hourlywage': costcodeHourlyWage ?? '',
        'union.name': new Set(unionName ? [unionName] : []),
        'local.name': new Set(localName ? [localName] : []),
        'class.name': new Set(className ? [className] : []),
        'class.hourlywage': classHourlyWage ?? '',
        metadata: {
          displayId,
          projectName,
          projectNumber,
          projectAddress,
          projectQBOClass,
          phaseName,
          phaseDescription,
          costcodeName,
          costcodeDescription,
          costcodeCode,
          unionName,
          localName,
          className,
          timezone: formattedTimezone,
        },
        payType,
        [costcodeHoursKey]: totalHours,
        exportIds,
        batchNumber,
      };

      if (!task.hourBased) {
        row.startTime = startDT.toLocaleString(DateTime.TIME_SIMPLE);
        row.endTime = endDT.toLocaleString(DateTime.TIME_SIMPLE);
      } else {
        // Override or else it's a timestamp
        row.startTime = '';
        row.endTime = '';
      }

      /**
       * When summarizeDays is false the accumulator is just a map of rows (key is day)
       * When summarizeDays is true the accumulator is a map of users which is a map of rows
       * (key is user id and then day)
       * This is because we have to group by user and then by day
       */
      if (!summarizeDays) {
        // No custom data for summarize rows
        const customData = { ...taskCustomData, ...userCustomData };
        Object.keys(customData).forEach((key) => {
          row[key] = customData[key];
        });

        fixedDecimalKeys.forEach((key) => {
          if (typeof row[key] === 'number') row[key] = row[key].toFixed(2);
        });

        const nonTableKeys = Object.keys(customData).filter((key) => key !== tableFieldId);

        nonTableKeys.forEach((key) => {
          row[key] = customData[key];
        });

        if (!tableFieldId || !customData[tableFieldId]) {
          acc[row.id] = parseSummarizeRow({ row, settings, wage: calculatedWage });
          return;
        }
        let {
          [tableFieldId]: tableValues = [],
        } = customData;

        if (tableValues && !Array.isArray(tableValues) && typeof tableValues === 'string') {
          /*
            Handle edge case where text field is converted to a table
            https://projectharbour.atlassian.net/browse/HARBOUR-3082
          */
          tableValues = [{ name: tableValues }];
        }
        if (!Array.isArray(tableValues) || tableValues.length === 0) {
          acc[row.id] = parseSummarizeRow({ row, settings, wage: calculatedWage });
          return;
        }

        tableValues.forEach((datum, tableIndex) => {
          const fullRow = { ...row };
          // Need to set a new id or else we end up with duplicate keys
          fullRow.id = `${fullRow.id}-${tableIndex}`;
          Object.keys(datum).forEach((columnKey) => {
            const dataKey = `${tableFieldId}-${columnKey}`;
            fullRow[dataKey] = convertTableValue(columnKey, datum[columnKey]);
          });
          acc[fullRow.id] = parseSummarizeRow({ row: fullRow, settings, wage: calculatedWage });
        });
        return;
      }

      if (!(userId in acc)) {
        acc[userId] = {
          sortOrder: [],
        };
      }

      if (!(fullRowKey in acc[userId])) {
        acc[userId][fullRowKey] = [];
        acc[userId].sortOrder.push(fullRowKey);
      }

      acc[userId][fullRowKey].push(row);
    });
    return acc;
  }, {});

  if (!summarizeDays) {
    return Object.values(result);
  }

  const res = convertSummarizedResultsToRows(result, settings);
  return res;
};

const getCSV = (arr, key) => (
  arr.map((item) => item?.[key]).filter((val) => !!val).join(', ')
);
export const computeFormExportData = ({
  customData = [],
  userIdMap = {},
  projectIdMap = {},
  qboClassIdMap = {},
  tableFieldId,
  hideExported,
  selectedExportId,
}) => (
  customData?.reduce?.((acc, row) => {
    const { projects = [], userId, exportIds } = row;
    if (hideExported && exportIds?.includes(selectedExportId)) return acc;
    const fullProjects = projects.map((projectId) => {
      const fullProj = projectIdMap[projectId];
      const {
        [fullProj?.qboClassId]: { Name: projectQBOClass } = {},
      } = qboClassIdMap;
      return {
        ...fullProj,
        qboClass: projectQBOClass,
      };
    })
      .filter((project) => !!project);

    const lastUpdated = DateTime.fromMillis(row.lastUpdated)
      .toLocaleString(DateTime.DATETIME_MED);
    const createdAt = DateTime.fromMillis(row.lastUpdated)
      .toLocaleString(DateTime.DATETIME_MED);

    const ourUser = userIdMap[userId];

    let {
      [tableFieldId]: tableValues = [],
    } = row;

    if (tableValues && !Array.isArray(tableValues) && typeof tableValues === 'string') {
      /*
        Handle edge case where text field is converted to a table
        https://projectharbour.atlassian.net/browse/HARBOUR-3082
      */
      tableValues = [{ name: tableValues }];
    }

    const baseRow = {
      ...row,
      lastUpdated,
      createdAt,
      'projects.name': getCSV(fullProjects, 'name'),
      'projects.number': getCSV(fullProjects, 'number'),
      'projects.qboClass': getCSV(fullProjects, 'qboClass'),
      'projects.address': getCSV(fullProjects, 'address'),
      'users.name': ourUser?.name,
      'users.employeeId': ourUser?.employeeId,
      'users.email': ourUser?.email,
    };

    if (!Array.isArray(tableValues) || tableValues.length === 0) {
      acc.push(baseRow);
      return acc;
    }

    tableValues.forEach((datum, tableIndex) => {
      const fullRow = { ...baseRow };
      // Need to set a new id or else we end up with duplicate keys
      fullRow.id = `${fullRow.id}-${tableIndex}`;
      Object.keys(datum).forEach((columnKey) => {
        const dataKey = `${tableFieldId}-${columnKey}`;
        fullRow[dataKey] = datum[columnKey];
      });
      acc.push(fullRow);
    });
    return acc;
  }, []) ?? []
);

export const updateFormNumbers = ({ data = [], formNumberMap = {} }) => {
  const newData = data.map(({ id, ...form }) => {
    const {
      [id]: number,
    } = formNumberMap;

    return {
      ...form,
      id,
      number,
    };
  });

  return newData;
};
