/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  TimePicker,
} from 'antd';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import OnTraccrTimePicker from '../../../../common/inputs/OnTraccrTimePicker';
import { TIME_TYPE_FLAG } from '../../../../constants/Flags';
import { luxonToMoment } from './TimeEntryTable.helpers';

const TIME_FORMAT = 'hh:mm a';
const HOURS_FORMAT = 'HH:mm';
function TypeOfTimePicker({
  type,
  setType,
  enableManualOT = false,
}) {
  const options = useMemo(() => {
    const parsedOptions = [
      {
        value: 'regular',
        label: 'Regular',
      },
    ];
    if (enableManualOT) {
      parsedOptions.push(...[{
        value: 'ot',
        label: 'OT',
      }, {
        value: 'doubleOT',
        label: 'Double OT',
      }]);
    }
    parsedOptions.push({
      value: 'break',
      label: 'Break',
    });
    return parsedOptions;
  });
  return (
    <Select
      value={type}
      options={options}
      onSelect={setType}
      style={{
        width: '7em',
      }}
    />
  );
}

TypeOfTimePicker.propTypes = {
  type: PropTypes.string,
  setType: PropTypes.func.isRequired,
  enableManualOT: PropTypes.bool,
};

TypeOfTimePicker.defaultProps = {
  type: 'regular',
  enableManualOT: false,
};

function TimeEntryTableHoursInput(props) {
  const {
    date,
    timezone,
    hourBased = false,
    value,
    onChange,
    onBlur,
  } = props;

  const { paidFlags = [] } = useSelector((state) => state.settings.company);
  const { settings = {} } = useSelector((state) => state.settings.company);

  const {
    enableManualOT = false,
  } = settings;

  const [type, setType] = useState('regular');

  const hasTimeTypeFlag = useMemo(() => paidFlags.includes(TIME_TYPE_FLAG), [paidFlags]);
  const safeDate = useMemo(() => (date.isValid ? date : DateTime.local()), [date]);

  const [startTimeKey, endTimeKey] = useMemo(() => {
    if (type === 'regular') {
      return [
        'startTime',
        'endTime',
      ];
    }

    const start = `${type}StartTime`;
    const end = `${type}EndTime`;

    return [
      start,
      end,
    ];
  }, [type]);

  const [luxonStart, luxonEnd] = useMemo(() => {
    const startMillis = value[startTimeKey];
    const endMillis = value[endTimeKey];

    const start = startMillis ? DateTime.fromMillis(startMillis, { zone: timezone }) : null;
    const end = startMillis ? DateTime.fromMillis(endMillis, { zone: timezone }) : null;

    const luxStart = start ? safeDate.set({
      hour: start.hour,
      minute: start.minute,
    }) : safeDate.set({
      hour: 0,
      minute: 0,
    });

    const luxEnd = end ? safeDate.set({
      hour: end.hour,
      minute: end.minute,
    }) : safeDate.set({
      hour: 0,
      minute: 0,
    });

    return [luxStart, luxEnd];
  }, [safeDate, startTimeKey, endTimeKey, value]);

  const onHourChange = (hourMillis) => {
    if (!hourMillis) {
      onChange({
        ...value,
        [startTimeKey]: null,
        [endTimeKey]: null,
      });
      return;
    }
    const hourDate = DateTime.fromMillis(hourMillis).setZone(timezone);
    onChange({
      ...value,
      [startTimeKey]: hourDate.set({ hour: 0, minute: 0 }).toMillis(),
      [endTimeKey]: hourMillis,
    });
  };

  const onRangeChange = (dates) => {
    if (!dates) {
      onChange({
        ...value,
        [startTimeKey]: null,
        [endTimeKey]: null,
      });
      return;
    }

    const [start, end] = dates;
    const startTime = DateTime.fromMillis(start.valueOf());
    const endTime = DateTime.fromMillis(end.valueOf());
    onChange({
      ...value,
      [startTimeKey]: safeDate
        .setZone(timezone)
        .set({ hour: startTime.hour, minute: startTime.minute }).toMillis(),
      [endTimeKey]: safeDate
        .setZone(timezone)
        .set({ hour: endTime.hour, minute: endTime.minute }).toMillis(),
    });
  };

  const InputComponent = hourBased ? (
    <OnTraccrTimePicker
      {...props}
      date={date}
      onBlur={!hasTimeTypeFlag ? onBlur : null}
      timezone={timezone}
      format={HOURS_FORMAT}
      value={value[endTimeKey]}
      onChange={onHourChange}
    />
  ) : (
    <TimePicker.RangePicker
      {...props}
      onBlur={!hasTimeTypeFlag ? onBlur : null}
      format={TIME_FORMAT}
      value={[luxonToMoment(luxonStart), luxonToMoment(luxonEnd)]}
      onChange={onRangeChange}
    />
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
      }}
    >
      {hasTimeTypeFlag && (
        <TypeOfTimePicker type={type} setType={setType} enableManualOT={enableManualOT} />
      )}
      {InputComponent}
    </div>
  );
}

TimeEntryTableHoursInput.propTypes = {
  date: PropTypes.instanceOf(DateTime).isRequired,
  timezone: PropTypes.string,
  hourBased: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  value: PropTypes.shape({
    startTime: PropTypes.number,
    endTime: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

TimeEntryTableHoursInput.defaultProps = {
  hourBased: false,
  timezone: DateTime.local().zoneName,
  style: {},
};

export default TimeEntryTableHoursInput;
