import React, {
  useMemo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table, Row, Col, Divider, message, Checkbox, Spin,
} from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import OnTraccrEmpty from '../../common/OnTraccrEmpty';
import HoverHelp from '../../common/HoverHelp';

import TimeCardDatePicker from '../../timecards/TimeCardDatePicker';

import ReportExcelExport from '../reportExcelExport';

import {
  CUSTOM_EXPORT_PROP_TYPE,
} from '../../settings/Exports/exports.constants';

import {
  computeTimeEntriesExportData,
  computeFormExportData,
  getTableFieldId,
  getDataKey,
  updateFormNumbers,
} from './exports.helpers';
import { PATH_MAP } from './exports.constants';

import { getIdMap } from '../../helpers/helpers';
import { request } from '../../helpers/requests';
import Debouncer from '../../helpers/Debouncer';

import { getCustomExports } from '../state/reports.actions';
import { getQuickbooksClasses } from '../../settings/state/settings.actions';

const exporter = new ReportExcelExport();
const debouncer = new Debouncer();

function CustomExportTable({
  selectedExport,
  scroll,
}) {
  const dispatch = useDispatch();
  const {
    approvedOnly = false,
    summarizeDays = false,
    summarizeProject = false,
    divisionId,
    type,
    id: exportId,
    generateFormNumber,
  } = selectedExport || {};
  const [startDate, endDate] = useSelector((state) => state.timecards.timeRange);
  const users = useSelector((state) => state.users.users);
  const projects = useSelector((state) => state.projects.projects);
  const phases = useSelector((state) => state.costcodes.phases);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);
  const qboClassMap = useSelector((state) => state.projects.quickbookClassMap);
  const {
    settings = {},
  } = useSelector((state) => state.settings.company || {});
  const {
    connectedToQuickbooks = false,
  } = useSelector((state) => state.settings.company);
  const {
    unions = [],
    locals = [],
    classes = [],
  } = useSelector((state) => state.unions);
  const divisions = useSelector((state) => state.settings.divisions);

  const {
    [divisionId]: {
      users: divUsers = new Set(),
      name: divisionName,
      code: divisionCode,
    } = {},
  } = divisions;

  const [rows, setRows] = useState([]);
  const [customData, setCustomData] = useState({});
  const [payTypeMap, setPayTypeMap] = useState({});
  const [hideExported, setHideExported] = useState(false);
  const [loading, setLoading] = useState(false);

  const userIdMap = useMemo(() => getIdMap(users), [users]);
  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const phaseIdMap = useMemo(() => getIdMap(phases), [phases]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const unionIdMap = useMemo(() => getIdMap(unions), [unions]);
  const localIdMap = useMemo(() => getIdMap(locals), [locals]);
  const classIdMap = useMemo(() => getIdMap(classes), [classes]);
  const qboClassIdMap = useMemo(() => getIdMap(qboClassMap[divisionId], 'Id'), [qboClassMap, divisionId]);

  const {
    columns = [],
    hasPayType: shouldGetPayType,
  } = useMemo(() => {
    if (!selectedExport) return [];
    const {
      columns: exportColumns = [],
    } = selectedExport;
    const parsedColumns = [];
    if (selectedExport?.addLineNumbers) {
      parsedColumns.push({
        title: '',
        render: (_1, _2, index) => index + 1,
        width: 50,
      });
    }
    parsedColumns.push({
      title: '',
      render: (_1, record) => {
        if (!record.exportIds?.includes(exportId)) return null;
        const suffix = record.batchNumber
          ? ` in batch ${record.batchNumber}`
          : '';
        const recordType = type === 'forms'
          ? 'form'
          : 'time card';
        return (
          <HoverHelp
            content={`This ${recordType} has already been exported${suffix}`}
            Icon={InfoCircleTwoTone}
            iconProps={{
              twoToneColor: '#fdb81b',
            }}
          />
        );
      },
      width: 50,
    });
    let hasPayType = false;
    exportColumns.forEach((col) => {
      parsedColumns.push({
        title: col.title,
        dataIndex: getDataKey(col),
      });
      if (col.field === 'payType') hasPayType = true;
    });
    return { columns: parsedColumns, hasPayType };
  }, [selectedExport]);

  const tableFieldId = useMemo(() => {
    if (!selectedExport) return null;
    const {
      columns: exportColumns = [],
    } = selectedExport;
    return getTableFieldId(exportColumns);
  }, [selectedExport]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const newData = await debouncer.debounce(() => {
        if (type === 'forms') {
          return computeFormExportData({
            userIdMap,
            projectIdMap,
            customData: customData?.tasks ?? {},
            selectedExportId: exportId,
            tableFieldId,
            hideExported,
            qboClassIdMap,
          });
        }
        return computeTimeEntriesExportData({
          timeEntryUserMap,
          users: users.filter((user) => divUsers.has(user.id)),
          startDate,
          endDate,
          settings,
          projectIdMap,
          phaseIdMap,
          costcodeIdMap,
          unionIdMap,
          localIdMap,
          classIdMap,
          qboClassIdMap,
          taskCustomDataMap: customData?.tasks ?? {},
          userCustomDataMap: customData?.users ?? {},
          selectedExportId: exportId,
          approvedOnly,
          summarizeDays,
          summarizeProject,
          divisionId,
          divisionName,
          divisionCode,
          divisionUserSet: divUsers,
          tableFieldId,
          hideExported,
          payTypeMap,
        });
      }, 500);
      setLoading(false);
      setRows(newData);
    };
    getData();
  }, [
    timeEntryUserMap,
    users,
    startDate,
    endDate,
    settings,
    userIdMap,
    projectIdMap,
    phaseIdMap,
    costcodeIdMap,
    unionIdMap,
    localIdMap,
    classIdMap,
    qboClassIdMap,
    customData,
    approvedOnly,
    divisionId,
    divUsers,
    summarizeDays,
    summarizeProject,
    tableFieldId,
    payTypeMap,
    type,
    hideExported,
    exportId,
  ]);

  const onExport = useCallback(async () => {
    if (!selectedExport) return;
    let batchNumber;
    if (selectedExport.generateBatchNumber) {
      const { data: newBatchNumber } = await request({
        call: axios.get(`/exports/${selectedExport.id}/batchNumber`),
        hideSuccessToast: true,
      });
      if (newBatchNumber) {
        batchNumber = newBatchNumber;
      } else {
        // Fail to return if we cant get batch number
        return;
      }
    }
    const didExport = exporter.createCustomExport(selectedExport.title, {
      addLineNumbers: selectedExport?.addLineNumbers,
      columns: selectedExport.columns,
      data: rows,
      batchNumber,
    });
    if (didExport) {
      const payload = {};
      if (batchNumber) payload.number = batchNumber;
      if (type === 'forms') {
        payload.generateFormNumber = !!generateFormNumber;
        payload.formIds = Array.from(rows.reduce((acc, datum) => {
          const { id: formId } = datum;
          /*
            If there is a table we append -{index} to give each row
            a unique key.
          */
          const [prefix] = formId?.split?.('-') ?? [];
          if (prefix) acc.add(prefix);
          return acc;
        }, new Set()));
      }
      if (type === 'timeEntries') {
        payload.taskIds = Array.from(rows.reduce((acc, datum) => {
          const { id: taskId } = datum;
          acc.add(taskId);
          return acc;
        }, new Set()));
      }
      if (Object.keys(payload).length === 0) return;
      const { data } = await request({
        call: axios.put(`/exports/${exportId}/after`, payload),
        hideSuccessToast: true,
      });
      if (type === 'forms') {
        const updatedRows = updateFormNumbers({ data: rows, formNumberMap: data });
        setRows(updatedRows);
      }
    }
  }, [selectedExport, rows, type]);

  const getCustomData = async ({
    path = '', params,
  }) => {
    try {
      const { data } = await axios.get(path, params);
      return data;
    } catch (err) {
      message.error('Failed to load custom data rows');
      Sentry.withScope(() => {
        Sentry.captureException(err);
      });
    }
    return {};
  };

  const parseCustomDataProps = () => {
    const {
      columns: exportColumns = [],
    } = selectedExport;
    const customFieldIds = new Set();
    const customTableCols = [];
    exportColumns
      .filter((col) => col.field.startsWith('field-'))
      .forEach((col) => {
        const parts = col.field.split('-');
        /*
          Table fields look like '{fieldId}-{id number}-{column name}
          Need to remove the last part.
        */
        const fieldId = parts.slice(0, 2).join('-');
        const columnKey = parts.length > 2 ? parts[2] : null;
        customFieldIds.add(fieldId);
        if (columnKey) customTableCols.push(columnKey);
      });

    return {
      fieldIds: Array.from(customFieldIds),
      exportColumns: customTableCols,
    };
  };

  useEffect(() => {
    const loadCustomTaskData = async ({ fieldIds = [], exportColumns = [] }) => {
      const path = PATH_MAP[type];
      if (!path) return {};
      const params = {
        params: {
          startTime: startDate.toMillis(),
          endTime: endDate.toMillis(),
          fieldIds,
          columns: exportColumns,
          formTemplateId: selectedExport?.formTemplateId,
          exportId,
        },
      };
      return getCustomData({ path, params });
    };
    const loadCustomUserData = async ({ fieldIds = [], exportColumns = [] }) => {
      const path = '/exports/users/custom';
      const params = {
        params: {
          fieldIds,
          columns: exportColumns,
        },
      };
      return getCustomData({
        path, params,
      });
    };
    const loadCustomData = async ({ fieldIds = [], exportColumns = [] }) => {
      setLoading(true);
      const proms = [];
      if (startDate && endDate) {
        proms.push(loadCustomTaskData({ fieldIds, exportColumns }));
      }
      if (type === 'timeEntries') {
        proms.push(loadCustomUserData({ fieldIds, exportColumns }));
      }
      const [newTaskData = {}, newUserData = {}] = await Promise.all(proms);
      setCustomData({
        users: newUserData,
        tasks: newTaskData,
      });
      setLoading(false);
    };
    if (selectedExport) {
      loadCustomData(parseCustomDataProps());
    } else {
      setCustomData({});
    }
  }, [selectedExport, startDate, endDate]);

  useEffect(() => {
    if (!connectedToQuickbooks) return;
    dispatch(getQuickbooksClasses());
  }, [connectedToQuickbooks]);

  useEffect(() => {
    dispatch(getCustomExports());
  }, []);

  useEffect(() => {
    const getPayTypes = async () => {
      try {
        const userIds = users.map((user) => user.id);
        const { data: payTypes } = await axios.post('/eclipse/pay_types', { userIds });
        setPayTypeMap(payTypes);
      } catch (err) {
        message.error('Failed to get Eclipse pay types');
        setPayTypeMap({});
      }
    };
    if (shouldGetPayType) {
      getPayTypes();
    } else {
      setPayTypeMap({});
    }
  }, [shouldGetPayType, users]);

  if (!selectedExport) return <OnTraccrEmpty />;
  return (
    <>
      <Row justify="space-between">
        <Col>
          <Row justify="start" gutter={16} align="middle">
            <TimeCardDatePicker />
          </Row>
        </Col>
        <Col>
          <Row justify="end" gutter={4} align="middle">
            <Col>
              <Checkbox
                checked={hideExported}
                onChange={(e) => setHideExported(e?.target?.checked)}
              >
                Hide previous exports?

              </Checkbox>
            </Col>
            {exportId !== 'preview' && (
            <Col>
              <OnTraccrButton
                title="Export"
                onClick={onExport}
                type="cancel"
              />
            </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Divider />
      {
        loading
          ? (
            <Row justify="center" align="middle" style={{ height: '100%', width: '100%' }}>
              <Col>
                <Spin />
              </Col>
            </Row>
          )
          : (
            <Table
              columns={columns}
              dataSource={rows}
              pagination={false}
              scroll={{
                x: 'fit-content',
                y: scroll,
              }}
              rowKey="id"
            />
          )
      }

    </>
  );
}

CustomExportTable.propTypes = {
  selectedExport: CUSTOM_EXPORT_PROP_TYPE,
  scroll: PropTypes.string,
};

CustomExportTable.defaultProps = {
  selectedExport: null,
  scroll: null,
};

export default CustomExportTable;
