import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Spin,
  Table,
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

// Import Components:
import FormResponder from '../FormResponder';
import FormsTable from '../FormsTable';
import FormSearchInput from '../FormSearchInput';

// Import Actions:
import {
  getAssignedForms,
} from '../state/forms.actions';

// Import Helpers:
import { getIdMap, includesTerm } from '../../helpers/helpers';
import { getAssignedFormColumns } from '../FormColumns';
import { getFilters, handleFormClick, parseFormDates } from '../formHelpers';
import { parseFormResponseToReadableFormat } from '../ResponderHelpers';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import useToggle from '../../common/hooks/useToggle';
import useFormSearch from '../../common/hooks/useFormSearch';

const parseForm = ({
  form = {},
  type,
  assignedDrafts = {},
  templates = {},
  projectIdMap = {},
  customerIdMap = {},
}) => {
  const {
    id: formId,
    templateId,
    customers = [],
    projects = [],
  } = form;
  const {
    [templateId]: {
      name: templateName,
    } = {},
  } = templates;

  const fullForm = {
    ...form,
    templateName,
    state: type,
  };

  let relevantProjects = projects;
  let relevantCustomers = customers;

  if (formId in assignedDrafts) {
    fullForm.lastUpdated = assignedDrafts[formId].lastUpdated;
    fullForm.state = 'Draft';
    relevantProjects = assignedDrafts[formId].projects;
    relevantCustomers = assignedDrafts[formId].customers;
  }

  const projectNames = [];
  relevantProjects.forEach((projectId) => {
    const {
      [projectId]: {
        name: projectName,
      } = {},
    } = projectIdMap;

    projectNames.push(projectName);
  });

  const customerNames = [];
  relevantCustomers.forEach((customerId) => {
    const {
      [customerId]: {
        name: customerName,
      } = {},
    } = customerIdMap;

    customerNames.push(customerName);
  });

  fullForm.project = projectNames.join(', ');
  fullForm.customer = customerNames.join(', ');
  const { lastUpdatedDate } = parseFormDates(fullForm);
  fullForm.lastUpdatedDate = lastUpdatedDate;
  return fullForm;
};

/** Assigned Forms */
export default function AssignedForms({
  history,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assignedForms = useSelector((state) => state.forms.assignedForms);
  const {
    manual: manualDrafts = {},
    assigned: assignedDrafts = {},
  } = useSelector((state) => state.forms.drafts);
  const customers = useSelector((state) => state.customers.customers);
  const projects = useSelector((state) => state.projects.projects);
  const templates = useSelector((state) => state.forms.templates);
  const { settings = {} } = useSelector((state) => state.settings.company);

  const [showDetail, setShowDetail] = useState(false);
  const [assignedTableData, setAssignedTableData] = useState([]);
  const [editTableData, setEditTableData] = useState([]);
  const [assignedForm, setAssignedForm] = useState({ });
  const [selectedIsEdit, setSelectedIsEdit] = useState(false);
  const [selectedIsResubmit, setSelectedIsResubmit] = useState(false);
  const [searchStr, setSearchStr] = useState();
  const [loading, setLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const { isToggled: isExpanded, toggle: toggleExpanded } = useToggle(true);

  const searchParams = useMemo(() => ({ searchTerm: searchStr }), [searchStr]);
  const {
    searchSet,
    searchLoading,
  } = useFormSearch(searchParams);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);

  const clearFormSelection = useCallback(() => setShowDetail(false), []);
  const onFormClick = useCallback(async (record) => {
    await handleFormClick({
      record,
      dispatch,
      loading,
      setLoading,
      setAssignedForm,
      setShowDetail,
      setSelectedIsEdit,
      setSelectedIsResubmit,
    });
  }, [loading]);

  useEffect(() => {
    dispatch(getAssignedForms());
  }, []);

  useEffect(() => {
    if (assignedForms.length) {
      setAssignedTableData(assignedForms.filter(({ editable }) => !editable));
      setEditTableData(assignedForms.filter(({ editable }) => !!editable));
    } else {
      setAssignedTableData([]);
      setEditTableData([]);
    }
  }, [assignedForms]);

  const onSearch = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchStr(value);
  }, []);

  // Prepare data:
  // -----------------------------------------------------
  const fullAssigned = useMemo(() => {
    const fullData = [];
    Object.values(manualDrafts).forEach((draft) => {
      const { templateId, customers: formCustomers = [], projects: formProjects = [] } = draft;
      const {
        [templateId]: template = {},
      } = templates;
      const customerNames = [];
      formCustomers.forEach((customerId) => {
        const {
          [customerId]: {
            name: customerName,
          } = {},
        } = customers;

        customerNames.push(customerName);
      });

      const projectNames = [];
      formProjects.forEach((projectId) => {
        const {
          [projectId]: {
            name: projectName,
          } = {},
        } = projectIdMap;
        projectNames.push(projectName);
      });

      const data = {
        ...template,
        ...draft,
        customer: customerNames.join(', '),
        project: projectNames.join(', '),
        templateName: template.name,
        state: 'Draft',
        isDraft: true,
      };

      const { lastUpdatedDate } = parseFormDates(data);
      data.lastUpdatedDate = lastUpdatedDate;
      fullData.push(data);
    });

    assignedTableData.forEach((form) => {
      fullData.push(parseForm({
        form,
        type: 'Assigned',
        assignedDrafts,
        templates,
        projectIdMap,
        customerIdMap: customers,
      }));
    });
    editTableData.forEach((form) => {
      fullData.push(parseForm({
        form,
        type: 'Editing',
        assignedDrafts,
        templates,
        projectIdMap,
        customerIdMap: customers,
      }));
    });

    return fullData
      .filter(({
        id,
        name,
        number,
        project,
        customer,
        state,
      }) => (
        (!searchStr || searchSet?.has(id))
        || (name && includesTerm(name, searchStr))
        || (number && includesTerm(number.toString(), searchStr))
        || (project && includesTerm(project, searchStr))
        || (customer && includesTerm(customer, searchStr))
        || (state && includesTerm(state, searchStr))))
      .sort((a, b) => b.lastUpdated - a.lastUpdated);
  }, [
    assignedTableData,
    editTableData,
    assignedDrafts,
    manualDrafts,
    templates,
    projectIdMap,
    customers,
    searchStr,
    searchSet,
  ]);

  useEffect(() => {
    if (fullAssigned.length && !Object.keys(templates).length) {
      setTableLoading(true);
    } else {
      setTableLoading(false);
    }
  }, [templates, fullAssigned]);

  // Prepare filters:
  // -----------------------------------------------------
  const assignedCustomerFilter = useMemo(() => getFilters({ data: fullAssigned, idMap: customers, key: 'customers' }), [customers, fullAssigned]);
  const assignedProjectFilter = useMemo(() => getFilters({ data: fullAssigned, idMap: projectIdMap, key: 'projects' }), [projectIdMap, fullAssigned]);
  const cardFilters = useMemo(() => {
    const nameSet = new Set();
    return fullAssigned.reduce((acc, form) => {
      if (form.card && !nameSet.has(form.card)) {
        nameSet.add(form.card);
        acc.push({ text: form.card, value: form.card });
      }
      return acc;
    }, []);
  }, [fullAssigned]);

  // Get columns:
  // -----------------------------------------------------
  const assignedColumns = useMemo(() => (
    getAssignedFormColumns({
      projectFilters: assignedProjectFilter,
      customerFilters: assignedCustomerFilter,
      cardFilters,
      t,
    })
  ), [assignedProjectFilter, assignedCustomerFilter, cardFilters]);

  const relevantTemplates = useMemo(() => {
    const relevantTemplateMap = {};
    const relevantTemplateIds = new Set();
    fullAssigned.forEach(({
      templateId,
    }) => {
      if (!relevantTemplateIds.has(templateId)) {
        relevantTemplateIds.add(templateId);

        const relevantTemplate = templates[templateId];
        if (!relevantTemplate) return;

        let relevantSections = [];
        try {
          const parsedTemplateData = JSON.parse(relevantTemplate?.jsonFormData);
          relevantSections = parsedTemplateData?.sections ?? [];
        } catch (err) {
          // do nothing
        }

        const fieldMap = relevantSections?.reduce((acc, { fields = [] }) => {
          fields.forEach((field) => {
            acc[field.id] = field;
          });
          return acc;
        }, {});

        relevantTemplateMap[templateId] = {
          ...relevantTemplate,
          sections: relevantSections,
          columns: relevantTemplate?.displayFields
            ?.sort((a, b) => a.orderIndex - b.orderIndex)
            ?.map(({
              fieldId,
            }) => ({
              title: fieldMap[fieldId]?.configProps?.title,
              dataIndex: fieldId,
              key: fieldId,
              render: (value) => {
                if (value) {
                  return value;
                }
                return '-';
              },
            })),
        };
      }
    });

    return relevantTemplateMap;
  }, [fullAssigned, templates]);

  const rowExpandable = useCallback((record) => {
    const relevantTemplate = relevantTemplates[record.templateId];
    return relevantTemplate?.displayFields?.length;
  }, [relevantTemplates]);

  const expandedRowRender = useCallback((record) => {
    const relevantTemplate = relevantTemplates[record.templateId];
    const formResponses = parseFormResponseToReadableFormat({
      templateSections: relevantTemplate?.sections ?? [],
      responseSections: record?.data?.sections ?? [],
      projectIdMap,
      settings,
    });

    return (
      <Table
        columns={relevantTemplate?.columns ?? []}
        dataSource={[formResponses]}
        pagination={false}
        size="small"
        bordered
      />
    );
  }, [relevantTemplates]);

  useEffect(() => {
    if (isExpanded) {
      setExpandedRowKeys(fullAssigned.map(({ id }) => id));
    } else {
      setExpandedRowKeys([]);
    }
  }, [fullAssigned, isExpanded]);

  const onExpand = useCallback((event, { id }) => {
    if (!event) {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== id));
      return;
    }

    setExpandedRowKeys([...expandedRowKeys, id]);
  }, [expandedRowKeys]);

  const expandable = useMemo(() => ({
    defaultExpandAllRows: true,
    rowExpandable,
    expandedRowRender,
    expandedRowKeys,
    onExpand,
  }), [fullAssigned, rowExpandable, expandedRowRender, expandedRowKeys, onExpand]);

  return (
    <div>
      <Row style={{ marginBottom: 14 }} align="middle" justify="space-between" gutter={20}>
        <Col>
          <FormSearchInput
            searchStr={searchStr}
            searchLoading={searchLoading}
            onSearch={onSearch}
          />
        </Col>
        <Col>
          <OnTraccrButton
            title={isExpanded ? 'Collapse All' : 'Expand All'}
            onClick={toggleExpanded}
            icon={isExpanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
            iconLeft
          />
        </Col>
      </Row>
      <div>
        <FormsTable
          data={fullAssigned}
          columns={assignedColumns}
          onClickRow={onFormClick}
          rowKey="id"
          expandable={expandable}
          loading={tableLoading}
        />
        <FormResponder
          visible={showDetail}
          onClose={clearFormSelection}
          history={history}
          redirectUrl="forms/assigned"
          assignedForm={assignedForm}
          isOnEditStep={selectedIsEdit}
          isResubmit={selectedIsResubmit}
        />
      </div>
      {loading && (
        <Row justify="center" align="middle" className="form-loading-container">
          <Spin size="large" />
        </Row>
      )}
    </div>
  );
}
