/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { Row, Col } from 'antd';
import { EditOutlined, FormOutlined } from '@ant-design/icons';
import { PropTypes } from 'prop-types';

import Badge from '../../common/badge';

import BoardCardUsers from '../BoardCardUsers';
import BoardField from '../BoardField';
import BoardCardTimeInStatusIcon from '../BoardCardTimeInStatusIcon';
import BoardCardTaskIcon from '../BoardCardTaskIcon';
import BoardCardNotesIcon from '../BoardCardNotesIcon';
import BoardCardShadowPopover from '../BoardCardShadowPopover';

import {
  getLastMove,
  getUnreadCommCount,
} from '../boards.helpers';

import { setBoardTargetCard } from '../state/boards.actions';

import { getTextColor } from '../../schedule/scheduleHelpers';
import { useInterval } from '../../helpers/helpers';
import BorderlessButton from '../../common/buttons/BorderlessButton';

const badgeStyle = {
  outline: '1px white solid',
  margin: 3,
  height: 14,
  borderRadius: 7,
  minWidth: 14,
  maxWidth: 14,
  lineHeight: '9px',
  fontSize: 10,
};

export default function BoardCard({
  id,
  title,
  number: cardNumber,
  orderIndex,
  statusId,
  noteCount,
  onClick,
  onEdit,
  onForm,
  isLocked,
  showCardNumber,
  color: backgroundColor,
  users: cardUsers = [],
  draggable = true,
  data = [],
  link = {},
  tasks = {},
  displayFields = new Set(),
  createdAt,
  lastMoveTimestamp = createdAt,
  userBoardPermissions = {},
  isShadow,
  boardId,
  projectIdMap,
  onBoardSelected,
}) {
  const dispatch = useDispatch();
  const ourRef = useRef();

  const tagsPerCard = useSelector((state) => state.boards.tagsPerCard);
  const unreadClientComms = useSelector((state) => state.customers.unreadClientComms);
  const sortType = useSelector((state) => state.boards.sortType.type);
  const { settings = {} } = useSelector((state) => state.settings.company);

  const {
    isFormsPermitted,
    canEdit,
  } = userBoardPermissions;

  const {
    cardId: targetCardId,
  } = useSelector((state) => state.boards.targetCard);

  const [lastMove, setLastMove] = useState(getLastMove(lastMoveTimestamp));
  const [highlight, setHighlight] = useState(false);

  const unreadCount = useMemo(() => (
    getUnreadCommCount({ link, unreadClientComms, projectIdMap })
  ), [link, unreadClientComms, projectIdMap]);

  const onCardClicked = useCallback(() => onClick(id), [onClick, id]);
  const onEditClicked = useCallback((e) => {
    e.stopPropagation();
    onEdit(id);
  }, [onEdit, id]);
  const onFormClicked = useCallback((e) => {
    e.stopPropagation();
    onForm(id);
  }, [onForm, id]);

  const textColor = backgroundColor ? getTextColor(backgroundColor) : undefined;

  const displayDataOrderMap = useMemo(() => {
    const orderMap = {};
    Array.from(displayFields).forEach((displayId, index) => {
      orderMap[displayId] = index;
    });
    return orderMap;
  }, [displayFields]);

  const displayData = useMemo(() => {
    const relevantDisplayFields = data.reduce((acc, section) => {
      const { fields: sectionFields = [] } = section;
      return acc.concat(
        sectionFields.filter((field) => displayFields.has(field.id || field.fieldId)),
      );
    }, []);

    const sortedDisplayFields = relevantDisplayFields.sort((a, b) => {
      const aId = a.id || a.fieldId;
      const bId = b.id || b.fieldId;
      return displayDataOrderMap[aId] - displayDataOrderMap[bId];
    });
    return sortedDisplayFields;
  }, [data, displayFields, displayDataOrderMap]);

  const isDragDisabled = useMemo(() => (
    !!isLocked || sortType !== 'default' || !canEdit || isShadow
  ), [isLocked, sortType, canEdit, isShadow]);

  const className = useMemo(() => `boards-card-${isDragDisabled ? 'locked' : 'draggable'}`, [isDragDisabled]);

  useInterval(() => {
    setLastMove(getLastMove(lastMoveTimestamp));
  }, [1000 * 60 * 60]); // Once an hour

  useEffect(() => {
    setLastMove(getLastMove(lastMoveTimestamp));
  }, [lastMoveTimestamp]);

  useEffect(() => {
    if (targetCardId === id && ourRef?.current) {
      ourRef.current.scrollIntoView();
      setHighlight(true);
      setTimeout(() => {
        dispatch(setBoardTargetCard({}));
        setHighlight(false);
      }, 1000 * 10);
    } else if (targetCardId && targetCardId !== id) {
      setHighlight(false);
    }
  }, [dispatch, targetCardId, id, ourRef]);

  const opacity = isShadow ? 0.5 : 1;
  const {
    [id]: myTagCount = 0,
  } = tagsPerCard;

  const fullCount = unreadCount + myTagCount;
  const HeaderRow = useCallback((props) => (
    <>
      <Row justify="space-between" {...props} ref={ourRef}>
        <Col span={18} style={highlight ? { fontWeight: 'bold' } : {}}>
          {showCardNumber ? <div>{`#${cardNumber}`}</div> : ''}
          <div style={{ alignItems: 'center', display: 'inline-flex' }}>
            <span style={{ fontSize: 18 }}>{title}</span>
            {fullCount > 0
              ? <Badge count={fullCount} style={badgeStyle} />
              : null}
          </div>
          <Row gutter={10}>
            {
              !!noteCount
              && (
                <Col style={{ height: 16, lineHeight: '16px' }}>
                  <BoardCardNotesIcon count={noteCount} />
                </Col>
              )
            }
            <Col>
              <BoardCardTimeInStatusIcon lastMove={lastMove} />
            </Col>
            <Col style={{ height: 16, lineHeight: '16px' }}>
              <BoardCardTaskIcon tasks={tasks} />
            </Col>
          </Row>
        </Col>
        <Col span={6} style={{ marginTop: 10, marginBottom: 10 }}>
          {canEdit && !isShadow && (
            <Row style={{ paddingBottom: 5 }}>
              <BorderlessButton
                iconNode={<EditOutlined style={{ color: textColor, fontSize: 18 }} />}
                className="boards-card-buttons"
                style={{ backgroundColor }}
                onClick={onEditClicked}
              />
            </Row>
          )}
          {isFormsPermitted && (
            <Row>
              <BorderlessButton
                iconNode={<FormOutlined style={{ color: textColor, fontSize: 18 }} />}
                className="boards-card-buttons"
                style={{ backgroundColor }}
                onClick={onFormClicked}
              />
            </Row>
          )}
        </Col>
      </Row>
      <BoardCardUsers
        users={cardUsers}
        justify="start"
        style={{ maxWidth: '100%' }}
        tooltipPlacement="top"
      />
    </>
  ), [
    highlight,
    title,
    lastMove,
    tasks,
    fullCount,
    badgeStyle,
    canEdit,
    textColor,
    isFormsPermitted,
    cardUsers,
    backgroundColor,
    ourRef,
    onEditClicked,
    onFormClicked,
    isShadow,
  ]);
  const body = (
    <div
      style={{ padding: 5 }}
      onClick={(e) => e.stopPropagation()}
      role="button"
      tabIndex={0}
    >
      {
        displayData.map((field, index) => (
          <div style={{ margin: '10px 0px', overflow: 'auto' }} key={field.id}>
            <BoardField
              field={field}
              index={index}
              settings={settings}
              projectIdMap={projectIdMap}
            />
          </div>
        ))
      }
    </div>
  );
  if (!draggable || isShadow) {
    const content = isShadow
      ? (
        <BoardCardShadowPopover
          boardId={boardId}
          cardId={id}
          statusId={statusId}
          onBoardSelected={onBoardSelected}
          canEdit={canEdit}
        >
          <HeaderRow />
          {body}
        </BoardCardShadowPopover>
      ) : (
        <>
          <HeaderRow />
          {body}
        </>
      );
    return (
      <div // eslint-disable-line jsx-a11y/no-static-element-interactions
        className={className}
        style={{ backgroundColor, color: textColor, opacity }}
        onClick={isShadow ? onCardClicked : null}
      >
        {content}
      </div>
    );
  }
  return (
    <Draggable draggableId={id} type="CARDS" index={orderIndex} isDragDisabled={isDragDisabled}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div
          className={className}
          {...draggableProps}
          ref={innerRef}
          onClick={onCardClicked}
          style={{
            ...draggableProps.style,
            backgroundColor,
            color: textColor,
            opacity,
          }}
          role="button"
          tabIndex={0}
        >
          <HeaderRow {...dragHandleProps} />
          {body}
        </div>
      )}
    </Draggable>
  );
}

/* eslint-disable react/forbid-prop-types */
BoardCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  number: PropTypes.number,
  showCardNumber: PropTypes.bool,
  orderIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onForm: PropTypes.func.isRequired,
  isLocked: PropTypes.number.isRequired,
  color: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string,
  })),
  draggable: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      fieldId: PropTypes.string,
    })),
  })),
  link: PropTypes.shape({
    id: PropTypes.string,
    unreadClientComms: PropTypes.number,
  }),
  tasks: PropTypes.shape({
    total: PropTypes.number,
    completed: PropTypes.number,
  }),
  displayFields: PropTypes.instanceOf(Set),
  createdAt: PropTypes.number.isRequired,
  lastMoveTimestamp: PropTypes.number.isRequired,
  userBoardPermissions: PropTypes.shape({
    canEdit: PropTypes.bool,
    isFormsPermitted: PropTypes.bool,
  }),
  noteCount: PropTypes.number,
  isShadow: PropTypes.bool.isRequired,
  boardId: PropTypes.string.isRequired,
  statusId: PropTypes.number.isRequired,
  projectIdMap: PropTypes.object,
  onBoardSelected: PropTypes.func.isRequired,
};

BoardCard.defaultProps = {
  number: null,
  users: [],
  color: null,
  draggable: true,
  data: [],
  link: {},
  tasks: {},
  displayFields: new Set(),
  userBoardPermissions: {},
  showCardNumber: false,
  noteCount: 0,
  projectIdMap: {},
};
